import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TherapyTemplateDto } from '../../entities/therapy';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import {
    TherapyTemplateExercise,
    TherapyTemplateExerciseDto,
    TherapyTemplateUpdateDto,
} from '../../entities/therapy/therapy-template-exercise';
import { TherapyGoal } from '../../entities/therapy-goal/therapy-goal';
import { ExerciseType } from '../../entities/exerciseSession';

@Injectable({
    providedIn: 'root',
})
export class TherapyTemplatesService {
    constructor(protected http: HttpClient) {}

    async getTherapyTemplates(args: {
        offset?: number;
        limit?: number;
        filter?: string;
        sortOrder?: SortOrder;
        sortBy?: SortBy;
        therapyGoal?: TherapyGoal;
        exerciseType?: ExerciseType;
        disabled?: boolean;
    }): Promise<PaginatedResponse<TherapyTemplate[]>> {
        const url = new URL(`${ApiService.url}therapyTemplates`);

        if (args?.offset) url.searchParams.set('offset', args.offset.toString());
        if (args?.limit) url.searchParams.set('limit', args.limit.toString());
        if (args?.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args?.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args?.filter) url.searchParams.set('filter', args.filter);
        if (args?.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args?.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (!!args?.disabled?.toString()) url.searchParams.set('disabled', args.disabled.toString());

        return this.http.get<PaginatedResponse<TherapyTemplate[]>>(url.toString(), ApiService.options).toPromise();
    }

    async postTherapyTemplate(newTherapyTemplate: TherapyTemplateDto): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates`;
        return this.http.post<TherapyTemplate>(url, newTherapyTemplate, ApiService.options).toPromise();
    }

    async getTherapyTemplateId(therapyTemplateId: number): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/${therapyTemplateId}`;
        return this.http.get<TherapyTemplate>(url, ApiService.options).toPromise();
    }

    async updateTherapyTemplate(
        therapyTemplateId: number,
        therapyTemplateDto: TherapyTemplateDto,
    ): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/${therapyTemplateId}`;
        return this.http.put<TherapyTemplate>(url, therapyTemplateDto, ApiService.options).toPromise();
    }

    async assignChildToParentTemplate(parentId: number, childId: number): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/assign/parent/${parentId}/child/${childId}`;
        return this.http.put<TherapyTemplate>(url, null, ApiService.options).toPromise();
    }

    async unassignChildFromParentTemplate(parentId: number, childId: number): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/unassign/parent/${parentId}/child/${childId}`;
        return this.http.put<TherapyTemplate>(url, null, ApiService.options).toPromise();
    }

    deleteTherapyTemplate(therapyTemplateId: number) {
        const url = `${ApiService.url}therapyTemplates/${therapyTemplateId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    deleteExerciseFromTherapyTemplate(therapyTemplateExerciseId: number): Promise<any> {
        const url = `${ApiService.url}therapyTemplates/-/therapyTemplateExercises/${therapyTemplateExerciseId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    putTherapyTemplateExercises(therapyTemplateExercises: TherapyTemplateUpdateDto[]) {
        const url = `${ApiService.url}therapyTemplates/-/therapyTemplateExercises`;
        return this.http.put<TherapyTemplateExercise[]>(url, therapyTemplateExercises, ApiService.options).toPromise();
    }

    postTherapyTemplateExercises(therapyTemplateExercise: TherapyTemplateExerciseDto) {
        const url = `${ApiService.url}therapyTemplates/-/therapyTemplateExercises`;
        return this.http.post<TherapyTemplateExercise>(url, therapyTemplateExercise, ApiService.options).toPromise();
    }
}
