<div
    *ngIf="showSplash"
    [ngStyle]="{ opacity: opacityChange, transition: splashTransition }"
    class="app-splash-screen">
    <div class="app-splash-content">
        <div class="loading-text">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
    </div>
</div>
