// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BuildType } from '../../../../libs/config/entities/build-type';
import { CurafidaEnvironmentConfig } from '../../../../libs/common/entities/curafida-environment-config.type';

export const environment: CurafidaEnvironmentConfig = {
    production: false,
    developmentStage: BuildType.dev,
    keycloak: {
        url: "https://dev.app.curafida.de/auth",
        realm: "curafida-dev",
        clientId: "curafida-frontend",
        kcAdapterOnMobile: "cordova"
    },
    theme: {
        logoUrl: "assets/extra/logo.png",
        faviconUrl: "assets/extra/favicon.png",
        colors: {
            primary: "#0096C8",
            primaryContrast: "#ffffff",
            secondary: "",
            secondaryContrast: "",
            tertiary: "",
            tertiaryContrast: ""
        }
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
