import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalConfig } from '../../entities/modal/modal-config';
import { MainModalComponent } from '../../components/main-modal/main-modal.component';
import { ModalMessage, ModalTyp } from '../../entities/modal/modal-typ';
import { ButtonConfig } from '../../entities/modal/modal-button';

@Injectable({
    providedIn: 'root',
})
export class ModalAlertService {
    constructor(private modalController: ModalController) {}

    async showModal(modalConfig: ModalConfig) {
        const modal = await this.modalController.create({
            component: MainModalComponent,
            backdropDismiss: false,
            cssClass: modalConfig.modalTyp,
            componentProps: {
                modalConfig,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data;
    }

    async showActionModal(title: string, description: string, actionButton: string) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = title;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = description;
        modalConfig.buttonLeft = new ButtonConfig();
        modalConfig.buttonLeft.buttonText = 'CANCEL';
        modalConfig.buttonLeft.buttonColor = 'primary';
        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = actionButton;
        modalConfig.buttonRight.buttonColor = 'danger';
        const modal = await this.modalController.create({
            component: MainModalComponent,
            backdropDismiss: false,
            cssClass: modalConfig.modalTyp,
            componentProps: {
                modalConfig,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data;
    }

    getModalConfig(modalMsg: ModalMessage): ModalConfig {
        const modalObj = new ModalConfig();
        switch (modalMsg) {
            case ModalMessage.ENABLE_BLUETOOTH_SETTINGS:
                modalObj.modalTyp = ModalTyp.INFORMATION;
                modalObj.title = `Bluetooth erforderlich`;
                modalObj.description =
                    'Bitte aktivieren Sie Bluetooth in den Einstellungen Ihres Gerätes, um alle Funktionen der App zu nutzen.';
                modalObj.buttonLeft = new ButtonConfig();
                modalObj.buttonLeft.buttonText = 'Abbrechen';
                modalObj.buttonLeft.buttonColor = 'danger';
                modalObj.buttonRight = new ButtonConfig();
                modalObj.buttonRight.buttonText = 'Einstellungen öffnen';
                modalObj.buttonRight.buttonColor = 'primary';
                return modalObj;
            case ModalMessage.ENABLE_LOCATION_SETTINGS:
                modalObj.modalTyp = ModalTyp.INFORMATION;
                modalObj.title = `Standort erforderlich`;
                modalObj.description =
                    'Bitte aktivieren Sie Standort in den Einstellungen Ihres Gerätes, um alle Funktionen der App zu nutzen.';
                modalObj.buttonLeft = new ButtonConfig();
                modalObj.buttonLeft.buttonText = 'Abbrechen';
                modalObj.buttonLeft.buttonColor = 'danger';
                modalObj.buttonRight = new ButtonConfig();
                modalObj.buttonRight.buttonText = 'Einstellungen öffnen';
                modalObj.buttonRight.buttonColor = 'primary';
                return modalObj;
            case ModalMessage.SEND_MEASUREMENT:
                return null;
            case ModalMessage.IS_NOT_CONNECTED:
                modalObj.modalTyp = ModalTyp.INFORMATION_REDUCED;
                modalObj.title = `Verbindung erforderlich`;
                modalObj.description = 'Bitte verbinden Sie zuerst das Gerät. ';
                modalObj.buttonRight = new ButtonConfig();
                modalObj.buttonRight.buttonText = 'Ok';
                modalObj.buttonRight.buttonColor = 'primary';
                return modalObj;
            case ModalMessage.DISCONNECT_DEVICE:
                modalObj.modalTyp = ModalTyp.INFORMATION_REDUCED;
                modalObj.title = `Gerät ausschalten`;
                modalObj.description = 'Bitte schalten Sie nach der Messung das Gerät wieder aus.';
                modalObj.buttonRight = new ButtonConfig();
                modalObj.buttonRight.buttonText = 'Ok';
                modalObj.buttonRight.buttonColor = 'primary';
                return modalObj;
            case ModalMessage.DEVICE_INFO:
                modalObj.modalTyp = ModalTyp.INFORMATION;
                modalObj.title = `Gerät Name`;
                modalObj.description = null; // Das Gerät EKG: 2345677845  wurde erfolgreich eingerichtet.
                modalObj.buttonLeft = new ButtonConfig();
                modalObj.buttonLeft.buttonText = 'Gerät entfernen';
                modalObj.buttonLeft.buttonColor = 'danger';
                modalObj.buttonRight = new ButtonConfig();
                modalObj.buttonRight.buttonText = 'Test starten';
                modalObj.buttonRight.buttonColor = 'primary';
                return modalObj;
        }
        return null;
    }
}
