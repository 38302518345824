import { Pipe, PipeTransform } from '@angular/core';
import { PageConfig } from '../../config/entities';
import { FeaturesConfiguration, CurafidaFeature } from '../../config/entities/base-page-config';
import { UserRoles } from '../../auth/entities/user';

@Pipe({
    name: 'MenuItemsFilter',
})
export class MenuItemsFilterPipe implements PipeTransform {
    transform(menuItems: PageConfig[], userRoles: UserRoles[] | string[], globalConfiguration: FeaturesConfiguration) {
        if (!userRoles) {
            return menuItems;
        }

        if (!menuItems) {
            return menuItems;
        }
        if (userRoles.length === 0) {
            return menuItems.filter((menuItem) => {
                const hasSubPage = menuItem.childPageConfigs?.length > 0;
                if (hasSubPage) {
                    return menuItem.role.length === 0;
                } else {
                    if (
                        globalConfiguration[menuItem?.module]?.enabled ||
                        globalConfiguration[menuItem?.module]?.enabled === undefined ||
                        menuItem.module === CurafidaFeature.common
                    ) {
                        return menuItem.role.length === 0;
                    }
                }
            });
        }

        return menuItems.filter((menuItem) => {
            const hasSubPage = menuItem.childPageConfigs?.length > 0;
            if (hasSubPage) {
                return menuItem.role.find((role) => userRoles.includes(role as UserRoles));
            } else {
                if (globalConfiguration[menuItem?.module]?.enabled || menuItem.module === CurafidaFeature.common) {
                    return menuItem.role.find((role) => userRoles.includes(role as UserRoles));
                } else {
                    return false;
                }
            }
        });
    }
}
