export class Point2D {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

export class EcgRingBuffer {
    private readonly size: number;
    private writeIndex = 0;
    private readonly points: Point2D[];
    private readIndex = 0;
    private nPoints = [];
    private readonly xScaleFactor: number;
    private minY = undefined;
    private readonly ecgBaselineReference: number; // The canvas origin (zero) is used as reference for the magnitude (value),
    // that means the max magnitude for this parameter (which means the minimum in the PlottingWindowView) could be equal to canvasHeight
    // ecgBaselineReference will be used for the calibration. The idea of the calibration is to level/move the average curve to the ecgBaselineReference.
    private readonly yScaleFactor: number;
    private readonly canvasHeight: number;
    private yCalibration = 0;

    constructor(size: number, xScaleFactor: number, canvasHeight: number, yScaleFactor: number) {
        this.ecgBaselineReference = canvasHeight * (2 / 3);
        this.canvasHeight = canvasHeight;
        this.yScaleFactor = yScaleFactor;
        this.size = size;
        this.xScaleFactor = xScaleFactor;
        this.points = new Array<Point2D>(size);
        for (let i = 0; i < size; i++) {
            this.points[i] = { x: i * xScaleFactor, y: null };
        }
    }

    enqueue(voltage: number): void {
        const equivVoltage = voltage * this.yScaleFactor;
        const y = this.transformToPlotScale(equivVoltage);
        if (this.nPoints.length % this.size === 0 && this.nPoints.length !== 0) {
            this.calibratePlottingView();
        }
        this.points[this.writeIndex].y = y;
        const point = this.points[this.writeIndex];
        this.nPoints.push(new Point2D(point.x, point.y));
        this.writeIndex = (this.writeIndex + 1) % this.size;
        this.points[this.writeIndex].y = null;
    }

    private transformToPlotScale(voltage: number) {
        if (this.nPoints.length % this.size === 0 && this.nPoints.length !== 0) {
            // debugger;
        }
        this.setMinY(voltage);
        return this.ecgBaselineReference + this.yCalibration - (voltage - this.minY);
    }

    getCurrent(): Point2D {
        return this.points[this.readIndex];
    }

    getNext(): Point2D {
        this.readIndex = (this.readIndex + 1) % this.size;
        return this.points[this.readIndex];
    }

    hasNext(): boolean {
        const nextIndex = (this.readIndex + 1) % this.size;
        return this.points[nextIndex].y !== null;
    }

    getAll(limit: number) {
        const startIndex = Math.max(0, this.nPoints.length - limit);
        const points = this.nPoints.slice(startIndex);
        if (this.nPoints.length > this.size) {
            this.nPoints = points;
        }
        for (let i = 0; i < points.length; i++) {
            points[i].x = i * this.xScaleFactor;
        }
        return points;
    }

    /**
     * the number of values (points) is an important value to calculate data's average. Default number 250 points
     */
    getAverage(numberOfPoints = 250) {
        let sum = 0;
        if (this.nPoints.length < numberOfPoints) {
            return null;
        }
        for (let i = 0; i < this.nPoints.length; i++) {
            sum = sum + this.nPoints[i]?.y;
        }
        const average = sum / this.nPoints.length;
        if (!average) {
            throw Error('Average of ecg-ring-buffer value invalid');
        }
        return average;
    }

    private setMinY(voltage: number) {
        if (this.minY === undefined) {
            this.minY = voltage;
            return;
        }
        if (voltage < this.minY || voltage > this.minY + this.ecgBaselineReference) {
            this.minY = voltage;
        }
    }

    /**
     * Calibration means that a constant value will be added to move the curve vertically
     */
    private calibratePlottingView() {
        const avg = this.getAverage();
        const tempCalibration = this.ecgBaselineReference - avg;
        if (!avg || !this.minY) {
            this.yCalibration = 0;
            return;
        }
        const deltaBaseline = this.canvasHeight - this.ecgBaselineReference;
        if (tempCalibration > deltaBaseline) {
            this.yCalibration = deltaBaseline;
            return;
        }
        this.yCalibration = this.ecgBaselineReference - avg;
    }
}
