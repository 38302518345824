import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StyleService } from '../../services/style/style.service';
import { CurafidaSegmentItem } from '../../entities/curafida-segment.item';
import { CurafidaPopoverTabComponent } from '../curafida-popover-tab/curafida-popover-tab.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IconColour, IconSizeTyp } from '../../entities/icon_type';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-segment',
    templateUrl: './curafida-segment.component.html',
    styleUrls: ['./curafida-segment.component.scss'],
})
export class CurafidaSegmentComponent<T> implements OnInit, ControlValueAccessor {
    @Input()
    segmentValue: T;
    mainList: CurafidaSegmentItem<T>[];
    dropDownList: CurafidaSegmentItem<T>[];
    @Output()
    sendSegmentType: EventEmitter<T> = new EventEmitter<T>();
    segmentType: T;
    platformSubscription: Subscription;
    isMobile = false;
    @Input()
    maxMainListItem = 7;
    @Input()
    maxMainListItemMobile = 3;
    tabWidth = '0%';
    @Input()
    mode: 'md' | 'ios' = 'ios';
    completionPercent: string;

    constructor(
        public platform: Platform,
        private popoverController: PopoverController,
        private styleService: StyleService,
    ) {
        this.platformSubscription = this.platform.resize.subscribe(() => {
            this.initGUIConfig();
        });
    }

    private _segmentList: CurafidaSegmentItem<T>[];
    IconColour = IconColour;
    IconSizeTyp = IconSizeTyp;

    get segmentList(): CurafidaSegmentItem<T>[] {
        return this._segmentList;
    }

    @Input() set segmentList(value: CurafidaSegmentItem<T>[]) {
        this._segmentList = value;
        this.initGUIConfig();
    }

    initGUIConfig(): void {
        this.isMobile = this.styleService.isMobile();
        /* if (this.isMobile) {
            this.mainList = this._segmentList.slice(0, this.maxMainListItemMobile);
            this.tabWidth = this.mainList?.length <= this.maxMainListItemMobile ? '90%' : '100%';
            this.dropDownList = this._segmentList.slice(this.maxMainListItemMobile, this._segmentList?.length);
        } else {*/
        this.mainList = this._segmentList.slice(0, this.maxMainListItem);
        this.dropDownList = this._segmentList.slice(this.maxMainListItem, this._segmentList?.length);
        this.tabWidth = `${(this.mainList?.length * (95 / this.maxMainListItem)).toString()}%`;
        // }
    }

    segmentChanged(event: CustomEvent): void {
        this.segmentType = event.detail.value;
        this.sendSegmentType.emit(event.detail.value);
        event.stopPropagation();
    }

    ngOnInit(): void {
        this._segmentList = this._segmentList.filter((item) => item.isElementShow);
        this.mainList = this._segmentList.slice(0, this.maxMainListItem);
        this.dropDownList = this._segmentList.slice(this.maxMainListItem, this._segmentList.length);
        if (this.segmentValue) {
            this.segmentType = this.segmentValue;
        } else {
            this.segmentType = this.mainList[0].value;
            this.sendSegmentType.emit(this._segmentList[0].value as T);
        }
        if (this.isMobile) {
            if (this._segmentList.length > 2) {
                this.completionPercent = `${this.segmentList.length * 33}%`;
            } else {
                this.completionPercent = `${this.segmentList.length * 50}%`;
            }
        } else {
            this.completionPercent = `${this.segmentList.length * 174}px`;
        }
        this.initGUIConfig();
    }

    isSelectedSegmentInDropdownListIncluding(): CurafidaSegmentItem<T> {
        return this.dropDownList.find((i) => i.value === this.segmentValue);
    }

    async presentPopover(ev: MouseEvent): Promise<void> {
        const popover = await this.popoverController.create({
            component: CurafidaPopoverTabComponent,
            translucent: true,
            event: ev,
            componentProps: {
                tabItems: this.dropDownList,
                selectedSegment: this.segmentValue,
            },
        });
        await popover.present();

        const { data } = await popover.onDidDismiss();
        if (data) {
            this.segmentType = data;
            this.sendSegmentType.emit(data);
        }
    }

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {}
}
