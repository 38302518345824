import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserRoles } from '../../../../auth/entities/user';
import { FormBuilder, Validators } from '@angular/forms';
import { ExerciseSortOrder, Therapy, TherapyExerciseOrder } from '../../../entities/therapy';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TherapiesService } from '../../../services/therapies';
import { UsersService } from '../../../../user/services/user';
import { TherapyTemplate } from '../../../entities/therapy/therapy-template';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableItem,
} from '../../../../table/entities/table';
import { ModalController } from '@ionic/angular';
import { UsersTherapiesService } from '../../../services/users-therapies';
import { UsersExerciseGoalsService } from '../../../services/user-exercise-goal';
import {
    ExerciseGoalDtoWrapper,
    IntensityUnit,
    PhysicalExerciseGoalDto,
} from '../../../entities/exerciseGoal/exerciseGoalDto';
import { ExerciseListModalComponent } from '../../../components/modal/exercise-list-modal/exercise-list-modal.component';
import { ExerciseSessionOfUser, ExerciseType, FrontendExerciseSessionOfUser } from '../../../entities/exerciseSession';
import { ModalAlertService } from '../../../../common/services/modal';
import { Exercise } from '../../../entities/exercise';
import { PaginatedResponse, SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { ExercisesService } from '../../../services/exercises';
import { UsersTherapySessionsService } from '../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { FrontendTherapySession, TherapySessionState } from '../../../entities/therapy-session/therapy-session';
import { ToolbarService } from '../../../../common/services/toolbar/toolbar.service';
import { ConfigService } from '../../../../config/services';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { ExerciseTrainingListChartModalComponent } from '../../../components/training/exercise-training-list-chart-modal/exercise-training-list-chart-modal.component';
import { RangeSelectAdapterComponent } from '../../../components/table-adapter/range-selector-item-adapter.component';
import { CommentFeedbackAdapterComponent } from '../../../components/table-adapter/comment-feedback-adapter.component';
import { BorgPainFeedbackAdapterComponent } from '../../../components/table-adapter/borg-pain-feedback-adapter.component';
import { FeedbackAdapterComponent } from '../../../components/table-adapter/feedback-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { ActionButton, ActionItemType, ActionMenuItem } from '../../../../table/entities/action-menu.item';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { TrainingFormComponent } from '../../../components/training/training-form/training-form.component';
import { format, parseISO } from 'date-fns';
import { ICardActionButton } from '../../../../common/entities/card-action-button.interface';
import { CurafidaAuthService } from '../../../../auth/services';

@Component({
    selector: 'lib-training-patient-detail',
    templateUrl: './training-patient-detail.page.html',
    styleUrls: ['./training-patient-detail.page.scss'],
})
export class TrainingPatientDetailPage implements OnInit {
    @ViewChild('trainingFormComponent') trainingFormComponent: TrainingFormComponent;
    title = 'Trainingskonfiguration';
    user: User;

    training: TherapyExerciseGoal;
    exerciseGoals: PhysicalExerciseGoalDto[];
    therapists: User[] = [];
    requiredValidators: Validators = Validators.compose([Validators.minLength(1), Validators.required]);

    subtitle: string;
    isEditEnabled = false;
    isNew = false;
    exerciseSessionsOfUserTable: TableConfig<FrontendExerciseSessionOfUser[]> = new TableConfig<
        FrontendExerciseSessionOfUser[]
    >();
    exerciseDefinitionOfUserTable: TableConfig<ExerciseDefinition[]> = new TableConfig<ExerciseDefinition[]>();
    previousUserTherapySessionsTable: TableConfig<FrontendTherapySession[]> = new TableConfig<
        FrontendTherapySession[]
    >();
    previousUserTherapySessions: PaginatedResponse<FrontendTherapySession[]>;
    currentRunningTherapySessions: PaginatedResponse<FrontendTherapySession[]>;
    userRoleOfBetreuer = this.configService.config.userRoleOfBetreuer
        ? this.configService.config.userRoleOfBetreuer
        : UserRoles.CAREGIVER;
    isEditContentEnabled = false;
    loggedInRoles: string[] | UserRoles[];
    UserRoles = UserRoles;
    actionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;
    private trainingTemplate: TherapyTemplate;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UsersService,
        private formBuilder: FormBuilder,
        private usersExerciseGoalsService: UsersExerciseGoalsService,
        private toastService: ToastService,
        private therapiesService: TherapiesService,
        private usersTherapiesService: UsersTherapiesService,
        private modalController: ModalController,
        private modalAlertService: ModalAlertService,
        private exercisesService: ExercisesService,
        private usersTherapySessionService: UsersTherapySessionsService,
        private configService: ConfigService,
        private toolbarService: ToolbarService,
        private loggingService: LoggingService,
        private authService: CurafidaAuthService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.loggedInRoles = this.authService.getSession().user.roles;
    }

    async fetchTherapy() {
        if (this.route.snapshot.paramMap.get('trainingId') === 'new') {
            this.training = new TherapyExerciseGoal();
            this.isNew = true;
            this.isEditEnabled = true;
            if (this.trainingFormComponent) {
                this.trainingFormComponent.training = this.training;
                this.trainingFormComponent.isEditEnabled = true;
                this.trainingFormComponent.isNew = true;
                await this.trainingFormComponent.initForm();
            }
        } else {
            try {
                this.training = (await this.usersTherapiesService.getUserTherapy(
                    this.user.username,
                    Number(this.route.snapshot.paramMap.get('trainingId')),
                    true,
                    false,
                )) as TherapyExerciseGoal;
                this.trainingTemplate = this.training.therapyTemplate;
                await this.setExerciseGoalTable();
                await this.saveExerciseGoals(true);
                this.previousUserTherapySessions = (await this.usersTherapySessionService.getUserTherapySessions(
                    this.user.username,
                    this.training.id,
                    0,
                    10,
                    SortOrder.DESC,
                    TherapySessionState.COMPLETED,
                )) as PaginatedResponse<FrontendTherapySession[]>;
                this.currentRunningTherapySessions = (await this.usersTherapySessionService.getUserTherapySessions(
                    this.user.username,
                    this.training.id,
                    0,
                    1,
                    SortOrder.ASC,
                    TherapySessionState.RUNNING,
                )) as PaginatedResponse<FrontendTherapySession[]>;
                if (this.previousUserTherapySessions?.items[0]) {
                    this.exerciseSessionsOfUserTable.list.items = this.previousUserTherapySessions.items[0]
                        .exerciseSessionsOfUser as FrontendExerciseSessionOfUser[];
                    this.exerciseSessionsOfUserTable.list.items.sort((a, b) => {
                        return FrontendExerciseSessionOfUser.compareFeedbackExerciseSessions(a, b);
                    });
                    for (const frontendExerciseSessionOfUser of this.exerciseSessionsOfUserTable.list.items) {
                        frontendExerciseSessionOfUser.exerciseName =
                            frontendExerciseSessionOfUser.exerciseSession.title;
                        frontendExerciseSessionOfUser.comment = frontendExerciseSessionOfUser.feedback?.feedbackComment;
                        frontendExerciseSessionOfUser.exerciseSessionUpdateTime =
                            frontendExerciseSessionOfUser.exerciseSession.updated_at;
                    }
                    if (this.previousUserTherapySessions.items.length > 0) {
                        this.exerciseSessionsOfUserTable.list.total =
                            this.previousUserTherapySessions.items[0].exerciseSessionsOfUser?.length;
                        this.exerciseSessionsOfUserTable.list.limit =
                            this.previousUserTherapySessions.items[0].exerciseSessionsOfUser?.length;
                    }
                    this.previousUserTherapySessionsTable.list = this.previousUserTherapySessions;
                }
            } catch (e) {
                this.log.error(e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
        await this.initUserTherapySessionTable();
        await this.initPreviousUserTherapySessionsTable();

        if (this.trainingFormComponent) {
            this.trainingFormComponent.training = this.training;
            await this.trainingFormComponent.initForm();
        }
    }

    toggleTable() {
        if (this.isEditContentEnabled) {
            this.exerciseDefinitionOfUserTable.itemSettings[0].type = ItemType.NUMBERING;
        } else {
            this.exerciseDefinitionOfUserTable.itemSettings[0].type = ItemType.REORDER;
        }
        this.isEditContentEnabled = !this.isEditContentEnabled;
        const exerciseGoalTableColumn = this.exerciseDefinitionOfUserTable.itemSettings.find(
            (ti) => ti.prop === 'exerciseGoal',
        );
        exerciseGoalTableColumn.isOnlyToShow = !this.isEditContentEnabled;
        this.exerciseDefinitionOfUserTable.isReorderDisable = !this.exerciseDefinitionOfUserTable.isReorderDisable;
    }

    async setExerciseGoalTable() {
        const completedTherapySessions = await this.usersTherapySessionService.getUserTherapySessions(
            this.user.username,
            this.training.id,
            0,
            1,
            SortOrder.DESC,
            TherapySessionState.COMPLETED,
        );
        const userExerciseGoals = (await this.usersExerciseGoalsService.getUserExerciseGoals(
            this.user.username,
            true,
        )) as PhysicalExerciseGoalDto[];
        this.training.exerciseDefinition = this.training.exercises as ExerciseDefinition[];
        if (userExerciseGoals) {
            this.exerciseGoals = [];
            for (const exercise of this.training.exerciseDefinition) {
                exercise.actions = [];
                if (this.loggedInRoles.includes(UserRoles.MANAGE_THERAPY)) {
                    exercise.actions.push(new ActionButton(ActionItemType.BUTTON, 'Entfernen', ActionType.DELETE));
                }
                exercise.actions.push(new ActionButton(ActionItemType.BUTTON, 'Info', ActionType.OPEN_NEW_PAGE));
                const goal = userExerciseGoals.find(
                    (exerciseGoal) =>
                        exerciseGoal.exerciseId === exercise.id && exerciseGoal.therapyId === this.training.id,
                );
                if (goal) {
                    goal.exercise.therapyExercise_id = exercise.therapyExercise_id;
                    exercise.exerciseGoal = goal;
                } else {
                    const exerciseFromServer = await this.exercisesService.getExerciseById(exercise.id);
                    exercise.exerciseGoal = exerciseFromServer.exerciseGoals[0] as PhysicalExerciseGoalDto;
                    await this.usersExerciseGoalsService.createUserExerciseGoal(
                        this.user.username,
                        new ExerciseGoalDtoWrapper(
                            PhysicalExerciseGoalDto.returnPhysicalExerciseGoalDtoToSend(exercise.exerciseGoal),
                        ),
                    );
                }
                let exerciseSession: ExerciseSessionOfUser;
                if (completedTherapySessions.total !== 0) {
                    exerciseSession = completedTherapySessions.items[0].exerciseSessionsOfUser.find(
                        (exerciseSessionOfUser) => exerciseSessionOfUser.exerciseSession.exercise.id === exercise.id,
                    );
                    if (!exerciseSession) {
                        exerciseSession = new ExerciseSessionOfUser();
                    }
                } else {
                    exerciseSession = new ExerciseSessionOfUser();
                }
                exercise.lastExerciseSessionOfUser = exerciseSession;
                exercise.lastExerciseGoal = exerciseSession.exerciseGoal;
                exercise.updated_at = exercise?.lastExerciseSessionOfUser?.exerciseSession?.updated_at;
            }
        }
        this.exerciseDefinitionOfUserTable = new TableConfig<ExerciseDefinition[]>();
        this.exerciseDefinitionOfUserTable.isReorderDisable = true;
        this.exerciseDefinitionOfUserTable.list.items = this.training.exerciseDefinition;
        this.exerciseDefinitionOfUserTable.list.total = this.training.exerciseDefinition?.length;
        this.exerciseDefinitionOfUserTable.list.count = this.training.exerciseDefinition?.length;
        this.exerciseDefinitionOfUserTable.list.limit = this.training.exerciseDefinition?.length;
        this.exerciseDefinitionOfUserTable.list.offset = 0;
        this.exerciseDefinitionOfUserTable.itemSettings = [
            {
                id: '',
                prop: '',
                header: '',
                type: ItemType.NUMBERING,
                width: '6%',
                sortOrderWeb: 0,
            },
            {
                id: '',
                prop: 'title',
                header: 'Übung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '14%',
                sortOrderWeb: 0,
            },
            {
                id: '',
                prop: 'updated_at',
                header: 'Datum',

                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                type: ItemType.ADAPTER,
                width: '12%',
                sortOrderWeb: 1,
            },
            {
                id: 'exerciseGoal',
                prop: 'exerciseGoal',
                header: 'SOLL Werte',

                type: ItemType.ADAPTER,
                adapter: RangeSelectAdapterComponent,
                width: '42%',
                sortOrderWeb: 2,
                label: 'true',
                isOnlyToShow: true,
            },
            {
                id: 'exerciseGoal',
                prop: 'lastExerciseGoal',
                header: 'Letztes Training',

                type: ItemType.ADAPTER,
                adapter: RangeSelectAdapterComponent,
                width: '18%',
                sortOrderWeb: 3,
                label: 'true',
                isOnlyToShow: true,
                isLabelDisabled: true,
            },
            {
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                actionType: ActionType.POPOVER,
                fill: 'clear',
                icon: 'ellipsis-vertical',
                width: '8%',
                sortOrderWeb: 4,
            },
        ];

        const exerciseGoalTableColumn = this.exerciseDefinitionOfUserTable.itemSettings.find(
            (ti) => ti.prop === 'exerciseGoal',
        );
        exerciseGoalTableColumn.isOnlyToShow = !this.isEditContentEnabled;
    }

    setTitleAndSubtitle({
        birthDate,
        firstName,
        lastName,
    }: {
        firstName: string;
        lastName: string;
        birthDate: string;
    }) {
        this.title = 'Trainingsverwaltung';
        this.subtitle = `${lastName}, ${firstName}`;
        if (birthDate) {
            this.subtitle = `${this.subtitle}, *${format(parseISO(birthDate), 'dd.MM.yyyy')}`;
        }
        if (this.user.pvsId) {
            this.subtitle = `${this.user.pvsId} - ${this.subtitle}`;
        }
    }

    ionViewDidEnter() {
        this.toolbarService.setTitle(this.title);
    }

    async ngOnInit() {
        if (this.loggedInRoles.includes(UserRoles.MANAGE_THERAPY)) {
            this.actionButtons.push({
                icon: 'add',
                id: 'add-exercise',
                isDisabled: false,
                isHidden: false,
                isIconButton: false,
                title: 'TRAINING.EXERCISE.ADD_ITEM',
            });
        }
        this.user = await this.userService.getUser(this.route.snapshot.paramMap.get('username'));
        this.setTitleAndSubtitle({
            firstName: this.user.firstname,
            lastName: this.user.lastname,
            birthDate: this.user.birthdate,
        });
        await this.fetchTherapy();
    }

    async setNewUserExerciseGoal(exerciseList: Exercise[]) {
        const userExerciseGoals = (await this.usersExerciseGoalsService.getUserExerciseGoals(
            this.user.username,
            true,
        )) as PhysicalExerciseGoalDto[];
        for (let exercise of exerciseList) {
            if (!this.training.exercises.find((item) => item.id === exercise.id)) {
                try {
                    const exerciseResponse = await this.therapiesService.postExerciseOfTherapy(
                        this.training.id,
                        exercise.id,
                    );
                    exercise = await this.exercisesService.getExerciseById(exerciseResponse.exercise_id);
                    exercise.therapyExercise_id = exerciseResponse.id;
                    let exerciseGoal = userExerciseGoals.filter(
                        (e) => e.exerciseId === exerciseResponse.exercise_id,
                    )[0];
                    if (!exerciseGoal) {
                        exerciseGoal = exercise.exerciseGoals.filter((eg) => eg.active)[0] as PhysicalExerciseGoalDto;
                    }
                    exerciseGoal.exerciseId = exerciseResponse.exercise_id;
                    exerciseGoal.therapyId = this.training.id;
                    exercise.exerciseGoals.push(
                        await this.usersExerciseGoalsService.createUserExerciseGoal(
                            this.user.username,
                            new ExerciseGoalDtoWrapper(
                                PhysicalExerciseGoalDto.returnPhysicalExerciseGoalDtoToSend(
                                    exerciseGoal,
                                ) as PhysicalExerciseGoalDto,
                            ),
                        ),
                    );
                    this.training.exercises.push(exercise);
                } catch (e) {
                    this.log.error(e);
                    await this.toastService.showToast(e, IonicColor.danger);
                }
            }
        }
    }

    async addNewExercise() {
        let exercises: Exercise[];
        if (this.exerciseGoals) exercises = this.exerciseGoals.map((exerciseGoal) => exerciseGoal.exercise);
        const tableItems: TableItem[] = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '10%',
                sortOrderWeb: 0,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'Übung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortBy: SortBy.TITLE,
                sortOrderWeb: 1,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '60%',
                sortBy: SortBy.DESCRIPTION,
                sortOrderWeb: 2,
            },
        ];
        const modal = await this.modalController.create({
            component: ExerciseListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: 'Übungen wählen',
                searchString: 'Übungen suchen',
                selectedExercises: exercises,
                exerciseType: ExerciseType.TRAINING,
                isMultipleChoice: true,
                showOldSelectedExercise: false,
                tableItems,
                anyItem: 'ANY_EXERCISE_TRAINING',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            await this.setNewUserExerciseGoal(data);
            await this.setExerciseGoalTable();
            await this.saveExerciseGoals(true);
            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
        }
    }

    async removeExercise(exerciseDefinition: ExerciseDefinition) {
        const action = await this.modalAlertService.showActionModal(
            `${exerciseDefinition.title} entfernen`,
            `Die Übung „${exerciseDefinition.title}" wird aus dem Trainingsplan entfernt. Wenn Sie die Übung entfernen möchten, klicken Sie auf "Entfernen".`,
            'Entfernen',
        );
        if (action && action.action === 'right') {
            try {
                await this.therapiesService.deleteExerciseOfTherapy(exerciseDefinition.therapyExercise_id);
                this.training = (await this.therapiesService.getTherapyId(
                    Number(this.route.snapshot.paramMap.get('trainingId')),
                )) as TherapyExerciseGoal;
                if (this.isEditContentEnabled) {
                    await this.toggleTable();
                }
                await this.setExerciseGoalTable();
                await this.toastService.showToast('Die Übung wurde entfernt', IonicColor.success);
            } catch (e) {
                this.log.error(e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    async cancelExerciseGoalsEdit() {
        this.toggleTable();
        await this.setExerciseGoalTable();
    }

    async saveExerciseGoals(init = false) {
        try {
            let i = 0;
            const exerciseSortOrder = new ExerciseSortOrder();
            exerciseSortOrder.orders = [];
            for (const exerciseDefinition of this.exerciseDefinitionOfUserTable.list.items) {
                exerciseDefinition.exerciseGoal.exerciseId = exerciseDefinition.id;
                exerciseDefinition.exerciseGoal.active = true;
                exerciseDefinition.exerciseGoal.therapyId = this.training.id;
                if (exerciseDefinition.exerciseGoal.intensityUnit === IntensityUnit.NONE) {
                    exerciseDefinition.exerciseGoal.intensityUnit = null;
                }
                exerciseDefinition.exerciseGoal = (await this.usersExerciseGoalsService.createUserExerciseGoal(
                    this.user.username,
                    new ExerciseGoalDtoWrapper(
                        PhysicalExerciseGoalDto.returnPhysicalExerciseGoalDtoToSend(exerciseDefinition.exerciseGoal),
                    ),
                )) as PhysicalExerciseGoalDto;
                exerciseSortOrder.orders.push(new TherapyExerciseOrder(exerciseDefinition.therapyExercise_id, i));
                i++;
            }

            if (!init) {
                await this.therapiesService.putTherapiesTherapyExercisesOrder(exerciseSortOrder, this.training.id);
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            }
        } catch (e) {
            console.error('error: ', e);
            await this.toastService.showToast(
                'Beim Ändern der Reihenfolge ist ein Fehler aufgetreten',
                IonicColor.danger,
            );
        } finally {
            if (!init) this.toggleTable();
        }
    }

    async setActionOnItem(actionEmitter: ActionEmitter<ExerciseDefinition>) {
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.removeExercise(actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.OPEN_NEW_PAGE) {
            const modal = await this.modalController.create({
                component: ExerciseTrainingListChartModalComponent,
                backdropDismiss: false,
                cssClass: 'full-width-modal-css',
                componentProps: {
                    exerciseId: actionEmitter.item.id,
                    therapyId: this.training.id,
                    patient: this.user,
                },
            });
            await modal.present();
        }
    }

    private async initUserTherapySessionTable() {
        this.exerciseSessionsOfUserTable.itemSettings = [
            {
                id: 'exerciseName',
                prop: 'exerciseName',
                header: 'Übung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                sortBy: SortBy.TITLE,
                sortOrderWeb: 0,
            },
            {
                id: 'exerciseSessionUpdateTime',
                prop: 'exerciseSessionUpdateTime',
                header: 'Datum',

                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                type: ItemType.ADAPTER,
                width: '11%',
                sortOrderWeb: 0,
            },
            {
                id: 'exerciseGoal',
                prop: 'exerciseGoal',
                header: 'SOLL Werte',
                type: ItemType.ADAPTER,
                adapter: RangeSelectAdapterComponent,
                width: '24%',
                sortOrderWeb: 0,
                label: 'true',
                isOnlyToShow: true,
            },
            {
                id: '',
                prop: '',
                header: 'IST Werte',
                type: ItemType.ADAPTER,
                adapter: FeedbackAdapterComponent,
                width: '17%',
                sortOrderWeb: 0,
                label: 'true',
            },
            {
                id: '',
                prop: 'feedback',
                header: 'Feedback',
                type: ItemType.ADAPTER,
                adapter: BorgPainFeedbackAdapterComponent,
                width: '12%',
                sortOrderWeb: 0,
            },
            {
                id: '',
                prop: 'comment',
                header: 'Kommentar',
                type: ItemType.ADAPTER,
                adapter: CommentFeedbackAdapterComponent,
                width: '15%',
                sortOrderWeb: 0,
            },
        ];
    }

    private async initPreviousUserTherapySessionsTable() {
        this.previousUserTherapySessionsTable.itemSettings = [
            {
                id: 'created_at',
                prop: 'created_at',
                header: 'Datum',

                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                type: ItemType.ADAPTER,
                width: '11%',
                sortOrderWeb: 0,
            },
            {
                id: '',
                prop: 'state',
                header: 'Feedback',
                type: ItemType.ADAPTER,
                adapter: BorgPainFeedbackAdapterComponent,
                width: '12%',
                sortOrderWeb: 0,
            },
            /* {
                 id: '',
                 prop: 'feedback',
                 header: 'Feedback',
                 type: ItemType.ADAPTER,
                 adapter: BorgPainFeedbackAdapterComponent,
                 width: '12%',
                 sortOrderWeb: 0
             },
             {
                 id: '',
                 prop: 'comment',
                 header: 'Kommentar',
                 type: ItemType.ADAPTER,
                 adapter: CommentFeedbackAdapterComponent,
                 width: '15%',
                 sortOrderWeb: 0
             },*/
        ];
    }
}

export enum RangeTyp {
    DURATION,
    SERIES,
    INTENSITY,
    PULSE,
    PULSE_VARIANCE,
}

export class TherapyExerciseGoal extends Therapy {
    exerciseDefinition: ExerciseDefinition[];
}

export class ExerciseDefinition extends Exercise {
    lastExerciseSessionOfUser: ExerciseSessionOfUser;
    lastExerciseGoal: PhysicalExerciseGoalDto;
    exerciseGoal: PhysicalExerciseGoalDto;
    updated_at: string;
    actions: ActionMenuItem[];
}
