<ion-footer
    style="position: absolute; bottom: 0; max-height: 25%; overflow-y: scroll"
    *ngIf="isLoggingViewEnabled">
    <ion-list
        class="full-height ion-padding-horizontal"
        style="
            padding: 0;
            margin: 0;
            font-size: smaller;
            font-family: monospace;
            background-color: rgba(0, 0, 0, 0.97);
        ">
        <div *ngFor="let log of logsObjects">
            <span style="color: #646464">{{ log.timestamp.toLocaleTimeString() }} </span>
            <span style="color: rgb(97, 169, 197)">[{{ log.loggerName }}]</span>

            <span
                *ngIf="log.logLevel === 'error'"
                style="color: #c93939">
                {{ log.logLevel | uppercase }}:
            </span>
            <span
                *ngIf="log.logLevel === 'warn'"
                style="color: #c9bb39">
                {{ log.logLevel | uppercase }}:
            </span>
            <span
                *ngIf="log.logLevel === 'info'"
                style="color: #d3d3d3">
                {{ log.logLevel | uppercase }}:
            </span>
            <span
                *ngIf="log.logLevel === 'debug'"
                style="color: #929292">
                {{ log.logLevel | uppercase }}:
            </span>
            <span
                *ngIf="log.logLevel === 'trace'"
                style="color: #8e3cb4">
                {{ log.logLevel | uppercase }}:
            </span>

            <pre style="color: #eeeeee; display: inline">{{ log.message.trim() }}</pre>
        </div>
    </ion-list>
</ion-footer>
