export enum ExaminationStates {
    NEW = 'NEW',
    ASSIGNED = 'ASSIGNED',
}

export enum ComponentStates {
    slider = 'SLIDER',
    keyboard = 'KEYBOARD',
}

export enum MeasurementUIAction {
    save = 'SAVE',
    cancel = 'CANCEL',
}

export enum SendUIStateMeasurement { // TODO: To evaluate to be set as boolean
    sent = 'sent',
    notSent = 'Erneut versenden',
}

export enum CosinussBluetoothStates {
    finished = 'finished',
    connected = 'connected',
    disconnected = 'disconnected',
    transferring = 'transferring',
}

export enum BluetoothStates {
    finished = 'finished',
    connected = 'connected',
    disconnected = 'disconnected',
    transferring = 'transferring',
}

export enum TwinTracRecordingResponse {
    STARTED = 'STARTED',
    STOPPED = 'STOPPED',
    NONE = 'NONE',
}

export enum TwinTracFileNameData {
    PATIENT_DAT = 'PATIENT.DAT',
    CONFIG_DAT = 'CONFIG.DAT',
    RECORD_DAT = 'RECORD.DAT',
    FWIMAGE_DAT = 'FWIMAGE.DAT',
}
export enum MediaContentModelAction {
    save = 'SAVE',
    delete = 'DELETE',
    retake = 'RETAKE',
}

export enum EcgRecordingStates {
    connected = 'CONNECTED', // The connection was established and data is being received
    disconnected = 'DISCONNECTED',
    recording = 'RECORDING', // Here the fileBuffer is initialize, and the data is collected into the fileBuffer to be later stored.
    recorded = 'RECORDED',
    not_found = 'NOT_FOUND',
    test_mode = 'TEST_MODE',
}

export enum StethoscopeRecordingStates {
    zoneSelected = 'ZONE_SELECTED',
    zoneNoSelected = 'ZONE_NO_SELECTED',
    connected = 'CONNECTED',
    disconnected = 'DISCONNECTED',
    tutorial = 'TUTORIAL',
    recording = 'RECORDING',
    timer_stopped = 'TIMER_STOPPED',
    recorded = 'RECORDED',
    not_found = 'NOT_FOUND',
    test_mode = 'TEST_MODE',
}

export enum AuscultationLocation {
    HEART = 'HEART',
    FRONT_LUNG = 'FRONT_LUNG',
    BACK_LUNG = 'BACK_LUNG',
    TRACHEA = 'TRACHEA',
    NONE = 'NONE',
}

export enum TutorialStepStethoscope {
    TUTORIAL_STETHOSCOPE_1 = 'TUTORIAL_STETHOSCOPE_1',
    TUTORIAL_STETHOSCOPE_2 = 'TUTORIAL_STETHOSCOPE_2',
    TUTORIAL_STETHOSCOPE_3 = 'TUTORIAL_STETHOSCOPE_3',
    TUTORIAL_STETHOSCOPE_4 = 'TUTORIAL_STETHOSCOPE_4',
}

export enum BodyPartStatesUI {
    NO_PART_SELECTED = 'NO_PART_SELECTED',
    PART_SELECTED = 'PART_SELECTED',
}

export enum DevicesConnectionStates {
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
}

export enum CommentMeasurements {
    noMeasurable = 'Nicht messbar',
    noMeasured = 'Nicht gemessen',
    noComments = '',
}

export enum TypeOfPage {
    measurement = 'MEASUREMENT',
    examination = 'EXAMINATION',
    standard = 'STANDARD',
}

export class VitalParameterColors {
    highRed: number;
    highOrange: number;
    highYellow: number;
    lowYellow: number;
    lowOrange: number;
    lowRed: number;
}

export const valuesOfVitalsParameter = {
    systolic: {
        highRed: 196, // low limit value of the very high area
        highOrange: 166, // low limit value of the high area
        highYellow: 146, // low limit value of the slightly high area
        lowYellow: 99, // high limit value of the slightly low area
        lowOrange: 85, // high limit value of the low area
        lowRed: 74, // high limit value of the very low area
    },
    diastolic: {
        highRed: 121,
        highOrange: 109,
        highYellow: 101,
        lowYellow: 59,
        lowOrange: 49,
        lowRed: 39,
    },
    bloodSugar: {
        highRed: 251,
        highOrange: 181,
        highYellow: 151,
        lowYellow: 80,
        lowOrange: 50,
        lowRed: 30,
    },
    spo2: {
        highRed: 101,
        highOrange: 101,
        highYellow: 101,
        lowYellow: 95,
        lowOrange: 90,
        lowRed: 85,
    },
    temperature: {
        highRed: 40,
        highOrange: null,
        highYellow: 38,
        lowOrange: null,
        lowYellow: 35.9,
        lowRed: 33.9,
    },
    spirometer: {
        highRed: 8,
        highOrange: null,
        highYellow: null,
        lowOrange: null,
        lowYellow: null,
        lowRed: 2,
        max: 10,
        min: 0,
        step: 0.01,
    },
};
