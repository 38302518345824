<div class="flex-container-row">
    <lib-range-selector
        (emitValue)="setActionMainValue($event)"
        [rangeValue]="mainValue"></lib-range-selector>
    <p
        style="min-width: 28px; text-align: center"
        *ngIf="secondaryValue">
        {{ operator }}
    </p>
    <lib-range-selector
        *ngIf="secondaryValue"
        (emitValue)="setActionSecondaryValue($event)"
        [rangeValue]="secondaryValue"></lib-range-selector>
</div>
