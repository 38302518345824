import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NgControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { endOfDay, isAfter, isBefore, roundToNearestMinutes, startOfDay } from 'date-fns';
import { ECalendarValue, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CalendarMode } from 'ng2-date-picker/lib/common/types/calendar-mode';
import { InputCustomEvent } from '@ionic/core';

const DEF_CONF: IDatePickerDirectiveConfig = {
    firstDayOfWeek: 'mo',
    disableKeypress: false,
    closeOnSelect: false,
    closeOnSelectDelay: 100,
    onOpenDelay: 100,
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 5,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ':',
    hideInputContainer: false,
    returnedValueType: ECalendarValue.String,
    unSelectOnClick: false,
    numOfMonthRows: 4,
};

class DayjsFormatValidator {
    constructor() {
        dayjs.locale('de');
        dayjs.extend(customParseFormat);
    }

    /* Validate the format of the date.
     * When clicking the popover date picker this format is already applied to the value.
     * This validator ensures the same format when typing the date in the text input field.
     * validDate, validate, get it? :D
     */
    static validDateFormat(controlName: string, format: string): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const control = group.get(controlName);
            if (!control || !format) {
                throw new Error(
                    `${this.constructor.name}: FormControl with name ${controlName} not found or format not specified`,
                );
            }
            /* If the form control holds no value or an empty value, it should still be valid.
             * If the form control is required, that should be achieved with a different validator.
             */
            if (!control.value) {
                return null;
            }
            const error = dayjs(control.value, format, true).isValid() ? null : { invalidDateFormat: true };
            control.setErrors(error);
            return error;
        };
    }

    /* Validate the range of the date against a max and min value.
     * The popover date picker already ensures this by not allowing to click on dates outside the valid range.
     * This validator applies the same limitation to typing the date in the text input field.
     * validDate, validate, get it? :D
     */
    static validDateRange(controlName: string, format: string, max?: string, min?: string): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const control = group.get(controlName);
            if (!control) {
                throw new Error(`${this.constructor.name}: FormControl with name ${controlName} not found`);
            }
            /* If the form control holds no value or an empty value, it should still be valid.
             * If the form control is required, that should be achieved with a different validator.
             */
            if (!control.value) {
                return null;
            }
            /* If the input text is not a valid date, return null.
             * Checking date formatting should be achieved with a different validator.
             */
            if (!dayjs(control.value, format, true).isValid()) {
                return null;
            }
            /* If there is no range then it is valid, return null */
            if (!min && !max) {
                return null;
            }
            const errors = { invalidMaxDate: false, invalidMinDate: false };
            if (max && isAfter(dayjs(control.value, format).toDate(), new Date(max))) {
                errors.invalidMaxDate = true;
            }
            if (min && isBefore(dayjs(control.value, format).toDate(), new Date(min))) {
                errors.invalidMinDate = true;
            }
            /* Remove falsy properties from the errors object */
            Object.keys(errors).forEach((key) => !errors[key] && delete errors[key]);
            /* If the errors object does not contain keys "{}" set result to null */
            const result = Object.keys(errors).length < 1 ? null : errors;
            control.setErrors(result);
            return result;
        };
    }
}

@Component({
    selector: 'curafida-date-input',
    templateUrl: './curafida-date-input.component.html',
    styleUrls: ['./curafida-date-input.component.scss'],
})
export class CurafidaDateInputComponent extends CurafidaInputComponent implements OnInit {
    @Input()
    dateFormat = 'DD.MM.YYYY';
    @Input()
    datePickerMode: CalendarMode = 'day';
    @Input()
    disabledPastDays = false;
    @Input()
    disabledFutureDays = false;
    @Input()
    borderColorPrimary = false;
    @Input()
    isEditEnabled = true;
    @Output()
    inputBlur: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input()
    minuteStep?: number = 5;
    @Input()
    minDate = undefined;
    @Input()
    maxDate = undefined;
    @Input()
    roundedMinutes? = true;
    config: IDatePickerDirectiveConfig;
    displayDate: string;
    datePickerFormGroup: FormGroup;
    private min: string;
    private max: string;
    private readonly log: Logger;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        private loggingService: LoggingService,
    ) {
        super(ngControl);
        this.log = this.loggingService.getLogger(this.constructor.name);
        dayjs.locale('de');
        dayjs.extend(customParseFormat);
    }

    async ngOnInit() {
        await super.ngOnInit();
        /* Error messages are set in CurafidaInputComponent based on the formControlName of the parent component.
         * The date picker has its custom validators, so add the possible error messages here
         */
        this.formErrors.push(...this.errorMessages.filter((i) => i.formType === 'picker'));
        this.initDatePickerConfig();
        /* Artificial type safety ot ensure the received input is read as a string even if null or undefined. */
        const dateStringInput = this.formGroup.controls[this.formControlName].value ?? '';
        const initialDate = this.roundMinutesIfConfigured(dateStringInput);
        /* The displayDate is only relevant when opening the date picker popover for the first time.
         * If the initial input is not empty open it on the input date, otherwise on today/now.
         */
        this.displayDate = initialDate ? initialDate : this.roundMinutesIfConfigured(new Date().toISOString());
        this.datePickerFormGroup = new FormGroup(
            {
                picker: new FormControl<string>({
                    value: this.formatDateString(initialDate),
                    disabled: false,
                }),
            },
            [
                DayjsFormatValidator.validDateFormat('picker', this.dateFormat),
                DayjsFormatValidator.validDateRange('picker', this.dateFormat, this.max, this.min),
            ],
        );
        if (this.isRequired) {
            this.datePickerFormGroup.controls['picker'].addValidators(Validators.required);
        }
        /* Propagate the status of the date picker form control to the original parent component form control.
         * This is relevant for validation purposes as only the date picker control implements the DayjsFormatValidator.
         */
        this.datePickerFormGroup.controls['picker'].statusChanges.subscribe((status) => {
            switch (status) {
                case 'VALID':
                    this.formGroup.controls[this.formControlName].setErrors(null);
                    break;
                case 'INVALID':
                    const errors = this.datePickerFormGroup.controls['picker'].errors;
                    this.formGroup.controls[this.formControlName].setErrors(errors);
                    break;
                default:
                    break;
            }
        });
    }

    /* Formats and sets the value on the form control of the parent component.
     * This event gets triggered by two different actions:
     * 1. When a date/time is selected in the date picker popover with a click.
     * 2. When a **VALID** date/time is typed into the input field.
     * Invalid inputs will not trigger this event.
     */
    setValueOnParentComponent(event: InputCustomEvent) {
        const value = event.detail.value;
        const isoStringValue = value ? dayjs(value, this.dateFormat).toISOString() : '';
        this.formGroup.controls[this.formControlName].patchValue(isoStringValue);
        this.formGroup.controls[this.formControlName].markAsDirty();
        this.inputBlur.emit(true);
    }

    private initDatePickerConfig() {
        if (this.disabledFutureDays) {
            this.max = endOfDay(new Date()).toISOString();
        }
        if (this.disabledPastDays) {
            this.min = startOfDay(new Date()).toISOString();
        }
        if (this.maxDate) {
            this.max = new Date(this.maxDate).toISOString();
        }
        if (this.minDate) {
            this.min = new Date(this.minDate).toISOString();
        }
        this.config = {
            ...DEF_CONF,
            minutesInterval: this.minuteStep,
            format: this.dateFormat,
            max: this.max ? dayjs(this.max) : null,
            min: this.min ? dayjs(this.min) : null,
        };
    }

    private formatDateString(date: string): string {
        if (!date) {
            return '';
        }
        return dayjs(date).format(this.dateFormat);
    }

    private roundMinutesIfConfigured(input: string): string {
        if (!input) {
            return '';
        }
        if (!this.roundedMinutes) {
            return input;
        }
        /* Because date-fns does not work with strings, the string needs to be used to create a Date object.
         * Since we mostly work with ISO strings in Curafida, it needs to be converted again after the operation.
         */
        return roundToNearestMinutes(new Date(input), {
            nearestTo: this.minuteStep,
            roundingMethod: 'ceil',
        }).toISOString();
    }
}
