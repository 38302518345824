export enum SortBy {
    TITLE = 'TITLE',
    DESCRIPTION = 'DESCRIPTION',
    DURATION = 'DURATION',
    USERNAME = 'USERNAME',
    FIRSTNAME = 'FIRSTNAME',
    LASTNAME = 'LASTNAME',
    EMAIL = 'EMAIL',
    BIRTHDATE = 'BIRTHDATE',
    EXERCISE_SESSION_TYPE = 'EXERCISE_SESSION_TYPE',
    NEEDS_SCHEDULE = 'NEEDS_SCHEDULE',
    AUTO_ACTIVATION = 'AUTO_ACTIVATION',
    REPETITIONS = 'REPETITIONS',
    CATEGORY_ID = 'CATEGORY_ID',
    MIN_GROUP_SIZE = 'MIN_GROUP_SIZE',
    MAX_GROUP_SIZE = 'MAX_GROUP_SIZE',
    START_TIME = 'START_TIME',
    DELAYED_TIME = 'DELAYED_TIME',
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT',
    MIMETYPE = 'MIMETYPE',
    RESPONSIBLE = 'RESPONSIBLE',
    TIMESTAMP = 'TIMESTAMP',
    CREATOR = 'CREATOR',
    TEXT = 'TEXT',
    END_TIME = 'END_TIME',
    EXERCISE_TYPE = 'EXERCISE_TYPE',
    EXERCISE_SESSION_STATE = 'EXERCISE_SESSION_STATE',
    esa_startTime = 'esa.startTime',
    UUID = 'UUID',
    PATH = 'PATH',
    SHORT_NAME = 'SHORT_NAME',
    CATEGORY = 'CATEGORY',
    NAME = 'NAME',
    STATE = 'STATE',
    PATIENT_LASTNAME = 'PATIENT_LASTNAME',
    REQUESTER_NAME = 'REQUESTER_NAME',
    CONSULTANT_NAME = 'CONSULTANT_NAME',
    LATEST_STATE_CHANGE = 'LATEST_STATE_CHANGE',
    PROVIDER_NAME = 'PROVIDER_NAME',
    CLIENT_NAME = 'CLIENT_NAME',
    ORDER_STATE = 'ORDER_STATE',
    ORDER_UPDATED_AT = 'ORDER_UPDATED_AT',
    ORDER_CREATED_AT = 'ORDER_CREATED_AT',
    LAST_ACTIVITY_DATE = 'LAST_ACTIVITY_DATE',
    EDITOR_FULL_NAME = 'EDITOR_FULL_NAME',
    USER_FULL_NAME = 'USER_FULL_NAME',
}
