import { User } from './user';
import { Type } from 'class-transformer';
import { SelectInputItem } from '../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';
import { AbstractControl } from '@angular/forms';

export class UserCustomProperty {
    uuid: string;
    username: string;
    user: User;
    schemaId: string;
    schema: UserCustomPropertySchema;
    value?: string;
}

export class UserCustomPropertyDto {
    schemaId: string;
    value?: string;
    uuid?: string;

    constructor(schemaId: string, value: string, uuid?: string) {
        this.schemaId = schemaId;
        this.value = value;
        if (uuid) this.uuid = uuid;
    }

    /**
     * Return true if this property was never set or changed.
     */
    isPristine(): boolean {
        return (this.value === '' || !this.value) && !this.uuid;
    }
}

enum DisplayOptionTypes {
    STRING = 'STRING',
    SELECT = 'SELECT',
    DATE = 'DATE',
}

class DisplayCondition {
    otherKey?: string;
    hasValue?: string;
}

export abstract class DisplayOptions {
    displayType: DisplayOptionTypes;
    defaultValue?: string;
    displayCondition?: DisplayCondition;
}

export class StringDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.STRING;
}

export class SelectDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.SELECT;
    answerOptions: string[];
}

class DateDisplayOptions extends DisplayOptions {
    displayType: DisplayOptionTypes = DisplayOptionTypes.DATE;
    presentation: 'date' | 'date-time' = 'date';
}

export class DisplayOptionsSchema {
    version = 1;
    @Type(() => DisplayOptions, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'displayType',
            subTypes: [
                { value: StringDisplayOptions, name: StringDisplayOptions.name },
                { value: SelectDisplayOptions, name: SelectDisplayOptions.name },
                { value: DateDisplayOptions, name: DateDisplayOptions.name },
            ],
        },
    })
    options: DisplayOptions | StringDisplayOptions | SelectDisplayOptions;
}

export class UserCustomPropertySchema {
    uuid: string;
    groupUuid: string;
    propertyGroupUuid?: string;

    @Type(() => UserCustomPropertySchema)
    propertyGroup?: UserCustomPropertySchema;

    @Type(() => UserCustomPropertySchema)
    propertySchemas?: UserCustomPropertySchema[];
    name: string;
    displayName?: string;
    sortOrder = 0;
    required = false;
    userRole?: string = 'PATIENT';

    userReadable?: boolean;
    userWritable?: boolean;

    @Type(() => DisplayOptionsSchema)
    displayOptions?: DisplayOptionsSchema;

    getFormControlName(): string {
        return this.name + '_' + this.uuid;
    }

    getAnswerOptionsAsItemList(): SelectInputItem[] {
        if (this.displayOptions?.options?.displayType === DisplayOptionTypes.SELECT) {
            const options = this.displayOptions?.options as SelectDisplayOptions;
            return options?.answerOptions?.map((v) => new SelectInputItem(v, v)) || [];
        }
        return [];
    }

    isVisible(abstractControls?: { [p: string]: AbstractControl }): boolean {
        const condition = this.displayOptions?.options?.displayCondition;
        if (condition) {
            if (abstractControls && abstractControls[condition.otherKey]) {
                return abstractControls[condition.otherKey].value === condition.hasValue;
            } else {
                console.error(`other values key not found: `, condition.otherKey);
            }
        }
        return true;
    }
}
