import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { ErrorMessage } from '../../entities/error-const';

@Component({
    selector: 'error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements ControlValueAccessor {
    @Input()
    errorMessageInput: ErrorMessage[];
    @Input()
    formGroup: FormGroup;
    @Input()
    formControlName: string;

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    registerOnChange(fn: any): void {}

    registerOnTouched(fn: any): void {}

    writeValue(obj: any): void {}
}
