import { Injectable } from '@angular/core';
import { LoggingService } from '../../logging/logging.service';
import { AccountType, Genders, User } from '../entities/user';
import jwt_decode from 'jwt-decode';
import { CurafidaSession } from '../entities/user/curafida-session';
import { SessionFactoryInterface } from '../../kc-auth/services';
import { TokenSet } from '../../kc-auth/entities/token-set';

@Injectable()
export class CurafidaSessionFactory implements SessionFactoryInterface<CurafidaSession> {
    constructor(private readonly loggingService: LoggingService) {}

    /**
     * Create a Session instance from a keycloak TokenSet.
     * @param tokenSet the keycloak TokenSet.
     */
    public create(tokenSet: TokenSet): CurafidaSession {
        const session = new CurafidaSession();
        session.user = this.getUserFromAccessToken(tokenSet.access_token);
        if (!session.user) {
            throw new Error('[processTokenSet] User Object could not be constructed from TokenSet -> Logout');
        }
        session.globalLogLevel = (jwt_decode(tokenSet.access_token) as any)?.debugging?.globalLogLevel;
        if (session.globalLogLevel || session.user.debuggingEnabled !== undefined) {
            this.loggingService.setGlobalLogLevel(session.globalLogLevel, session.user.debuggingEnabled);
        }
        session.tokenSet = tokenSet;
        session.tenantId = this.getTenantIdFromAccessToken(tokenSet.access_token);
        if (!session.tenantId) {
            throw new Error('[processTokenSet] There is no TenantId in TokenSet -> Logout');
        }
        return session;
    }

    /**
     * Constructs a user object from a given access token.
     * @param accessToken a raw (string encoded) access token obtained by keycloak
     */
    private getUserFromAccessToken(accessToken: string): User {
        const user = new User();
        try {
            const decoded: any = jwt_decode(accessToken);
            user.kcId = decoded.sub;
            user.accountType = AccountType.LOGIN_USER;
            user.username = decoded.preferred_username;
            user.firstname = decoded.given_name;
            user.lastname = decoded.family_name;
            user.email = decoded.email;
            user.emailVerified = decoded.email_verified;
            user.disabled = false;
            user.birthdate = decoded.birthdate;
            user.gender = Object.values(Genders).find((el) => el === decoded.gender);
            user.roles = decoded.realm_access.roles;
            user.debuggingEnabled = decoded?.debugging?.logViewerEnabled;
            user.groups = decoded.groups;
        } catch (error) {
            throw new Error('Constructing user object from access token payload failed!');
        }
        return user;
    }

    /**
     * Get the root tenant group path from access token groups claim.
     *
     * This method only gets the tenant root group path.
     * Even if the user is not a member of this group, and e.g. only member of a subgroup.
     */
    private getTenantIdFromAccessToken(accessToken: string): string {
        const decoded: any = jwt_decode(accessToken);
        if (!decoded || !decoded.groups) {
            throw new Error(`'groups' claim is not present in access token`);
        }
        const tenantgroups = decoded.groups
            .map((group: string) => {
                return group.split('/').slice(0, 3).join('/');
            })
            .filter((group: string) => {
                const splitted = group.split('/');
                // A valid root tenant group is splitted like: ["", "tenants", "beta.app.consi.curafida.de"]
                return splitted && splitted.length === 3 && splitted[0] === '' && splitted[1] === 'tenants';
            })
            .filter((group: string, index: number, self) => {
                // Filter duplicates
                return self.indexOf(group) === index;
            });
        if (tenantgroups.length > 1) {
            throw new Error(
                `Access token contains membership in multiple tenants. This is an invalid user group configuration.`,
            );
        }
        if (tenantgroups.length < 1) {
            throw new Error(
                `Access token contains no membership in a tenant group or subgroup. This is an invalid user group configuration.`,
            );
        }
        return tenantgroups[0];
    }
}
