import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ExerciseToolSetting } from '../../therapy/entities/exerciseSession';
import { CurafidaAuthService } from '../../auth/services';
import { map } from 'rxjs/operators';

declare let JitsiMeetExternalAPI: any;

export interface JitsiUser {
    name: string;
    email: string;
}

export interface Controls {
    isAudioMuted: boolean;
    isVideoMuted: boolean;
}

export interface JitsiModel {
    setting: ExerciseToolSetting;
    user: JitsiUser;
    api: any;
    apiOptions: any;
    controls: Controls;
    lastNavigationUrl: string;
}

const initialState: JitsiModel = {
    setting: undefined,
    user: undefined,
    api: undefined,
    apiOptions: undefined,
    controls: {
        isAudioMuted: true,
        isVideoMuted: true,
    },
    lastNavigationUrl: undefined,
};

@Injectable({
    providedIn: 'root',
})
export class JitsiService {
    readonly model$ = new BehaviorSubject<JitsiModel>({ ...initialState });
    readonly setting$ = this.model$.pipe(map((it) => it.setting));
    readonly user$ = this.model$.pipe(map((it) => it.user));
    readonly api$ = this.model$.pipe(map((it) => it.api));
    readonly apiOptions$ = this.model$.pipe(map((it) => it.apiOptions));
    readonly controls$ = this.model$.pipe(map((it) => it.controls));

    private iframe: HTMLElement;

    constructor(readonly route: Router, readonly authService: CurafidaAuthService) {}

    get current(): JitsiModel {
        return this.model$.value;
    }

    rememberUrl(url: string): void {
        this.model$.value.lastNavigationUrl = url;
    }

    startJitsiConference(): void {
        this.resetIframe();
        const user = {
            name: `${this.authService.getSession().user.firstname} ${this.authService.getSession().user.lastname}`,
            email: this.authService.getSession().user.email,
        };
        const options = {
            roomName: this.current.setting.config.conferenceName,

            configOverwrite: {
                prejoinPageEnabled: false,
                subject: this.current.setting.config.subject,
            },
            interfaceConfigOverwrite: {
                startAudioMuted: false,
                startVideoMuted: false,
            },
            parentNode: document.querySelector('#jitsi-iframe'),
            userInfo: {
                displayName: user.name,
                email: user.email,
            },
        };
        this.model$.next({
            ...this.current,
            user: user,
            api: new JitsiMeetExternalAPI(this.current.setting.config.domain, options),
            apiOptions: options,
        });
    }

    leaveMeeting(): void {
        console.log('Leaving Jitsi Meeting...');
        this.current.api?.dispose();
        this.model$.next({ ...initialState });
    }

    private resetIframe(): void {
        if (!this.iframe) {
            this.iframe = document.getElementById('jitsi-iframe');
        }
        this.iframe.innerHTML = '';
    }
}
