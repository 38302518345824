<div
    class="flex-container-column border-tutorial"
    id="border-tutorial">
    <strong
        *ngIf="slideshow?.list[currentIndex].title.length > 0"
        class="full-width text-align-center ion-margin-top"
        >{{ slideshow.list[currentIndex].title }}</strong
    >
    <div
        class="flex-container-row align-items-center full-width justify-content-center"
        *ngIf="hasCounter">
        <ion-icon
            color="danger"
            class="medium-icon blink"
            name="radio-button-on-sharp"></ion-icon>
        <strong>{{ counter }}s / {{ maxCounter }}s</strong>
    </div>
    <swiper
        #swiper
        (realIndexChange)="slideChanged()"
        [config]="config"
        style="margin-top: 1%; margin-bottom: 1%; background: #ffffff; max-width: 100%">
        <ng-template
            swiperSlide
            *ngFor="let slide of slideshow?.list">
            <img
                *ngIf="slide.picture"
                alt="Anleitung"
                src="{{ slide.picture.toString() }}"
                style="max-height: 59vh" />
            <div
                *ngIf="slide.component === ComponentType.COSINUSS"
                style="width: 90%">
                <p class="title-top-rectangle-measurement">Temperatur</p>
                <lib-rectangle-value-display
                    [value]="temperature"
                    [units]="['°C']"
                    [id]="'Temperature'"
                    [comment]="temperatureComment"
                    [rangeColor]="false"
                    [integer]="false">
                </lib-rectangle-value-display>
                <p class="title-top-rectangle-measurement">Sauerstoffsättigung</p>
                <lib-rectangle-value-display
                    [value]="spo2"
                    [units]="['%']"
                    [id]="'Sauerstoffsättigung'"
                    [comment]="spo2Comment"
                    [rangeColor]="false"
                    [integer]="false">
                </lib-rectangle-value-display>
                <p class="title-top-rectangle-measurement">Puls</p>
                <lib-rectangle-value-display
                    [value]="heartRate"
                    [units]="['bpm']"
                    [id]="'Puls'"
                    [comment]="pulseComment"
                    [rangeColor]="false"
                    [integer]="false">
                </lib-rectangle-value-display>
            </div>
            <div
                *ngIf="slide.component === ComponentType.TWINTRAC"
                style="width: 90%">
                <lib-ecg-3-channels-chart
                    [moveWindowPlottingFlag]="false"
                    [channel$]="channels$"
                    [numberChannels]="3"></lib-ecg-3-channels-chart>
            </div>
        </ng-template>
    </swiper>
    <p
        *ngIf="slideshow?.list[currentIndex].description.length > 0"
        class="ion-margin-horizontal ion-margin-top"
        style="white-space: pre-line">
        {{ slideshow.list[currentIndex].description }}
    </p>
</div>
