import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { CurafidaAuthService } from '../../../auth/services';
import { FilterSetting, FilterSettingItem, UserPreferences } from '../../entities/user-preferences/user-preferences';

@Injectable({
    providedIn: 'root',
})
export class UsersPreferencesService {
    constructor(protected http: HttpClient, private authService: CurafidaAuthService) {}

    private _userPreferences: UserPreferences;

    get userPreferences(): UserPreferences {
        return this._userPreferences;
    }

    set userPreferences(value: UserPreferences) {
        this._userPreferences = value;
    }

    async init(username: string): Promise<void> {
        const url = new URL(`${ApiService.url}users/${username}/preferences`);
        this.userPreferences = await this.http.get<UserPreferences>(url.toString(), ApiService.options).toPromise();
    }

    async update(username: string, userPreferences: UserPreferences): Promise<void> {
        const url = new URL(`${ApiService.url}users/${username}/preferences`);
        this.userPreferences = await this.http
            .put<UserPreferences>(url.toString(), userPreferences, ApiService.options)
            .toPromise();
    }

    async updateFilterSettingItem(filterSettingItem: FilterSettingItem) {
        const filterSettingItemIndex = this.userPreferences.filterSettings?.filterSettingItem?.findIndex(
            (i) => i.name === filterSettingItem.name.toString(),
        );
        if (!this.userPreferences.filterSettings) {
            this.userPreferences.filterSettings = new FilterSetting();
            this.userPreferences.filterSettings.filterSettingItem = [];
        }
        if (filterSettingItemIndex !== -1) {
            this.userPreferences.filterSettings.filterSettingItem[filterSettingItemIndex] = filterSettingItem;
        } else {
            this.userPreferences.filterSettings.filterSettingItem.push(filterSettingItem);
        }

        await this.update(this.authService.getSession().user.username, this.userPreferences);
    }
}
