import { TableItem } from './item-table';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { ActionType } from './action-type';

export class MobileConfiguration {
    hasButton? = false;
    buttonIconName?: string;
    buttonIconColor? = 'primary';
    hasIcon? = false;
    templateId?: number;
    isDisabled? = false;
    buttonActionType?: ActionType = ActionType.OPEN_NEW_PAGE;
    hasImage? = false;
    hasCheckbox? = false;
    forceMobile? = false;
    hasPointer? = false;
    hasNotesTitle? = false;
    titleSeparator? = ',';
}

export class TableConfig<T> {
    itemSettings: TableItem[];
    isOpenDetailEnable = false; // show hand on hover and enable open detail action
    isReorderDisable = true; // disable reoder function
    hideHeader = false;
    list: PaginatedResponse<T> = new PaginatedResponse<T>();
    /**
     @Deprecated()
     */
    hasMobileConfig = false;
    /**
     @Deprecated()
     */
    mobileConfig?: MobileConfiguration = new MobileConfiguration();

    createList(items: T[]): PaginatedResponse<T[]> {
        const paginatedList = new PaginatedResponse<T[]>();
        paginatedList.items = items;
        paginatedList.offset = 0;
        paginatedList.limit = items.length;
        paginatedList.count = items.length;
        paginatedList.total = items.length;
        return paginatedList;
    }
}

export class TableUpdateValue {
    offset: number;
    limit?: number;

    constructor(offset: number, limit?: number) {
        this.offset = offset;
        this.limit = limit;
    }
}
