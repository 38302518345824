<ion-header *ngIf="therapyTemplateListConfig.list?.items">
    <ion-toolbar
        color="primary"
        style="padding-left: 1em">
        <div slot="start">{{ title | translate }}</div>
        <ion-button
            slot="end"
            (click)="dismissModal()"
            fill="outline">
            <ion-icon
                name="close"
                style="color: var(--ion-color-primary-contrast)"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="flex-container-row justify-content-flex-end align-items-flex-end full-width">
        <curafida-search-and-button
            class="full-width ion-padding-end ion-padding-start"
            [(ngModel)]="searchTerm"
            [hideButton]="true"
            (getItemList)="initTherapyTemplateList({ offset: 0 }, searchTerm)">
        </curafida-search-and-button>
    </div>
    <div *ngIf="therapyTemplateListConfig.list?.items && therapyTemplateListConfig.list.count > 0">
        <div class="full-width ion-padding no-vertical-padding">
            <curafida-table
                [listTableConfig]="therapyTemplateListConfig"
                (openDetail)="onActionOnItem($event)"
                (updateTable)="initTherapyTemplateList($event, searchTerm)"
                (sort)="sortTherapyTemplateList($event)">
            </curafida-table>
        </div>
    </div>
    <div
        class="flex-container-row ion-padding justify-content-flex-start full-width ion-margin-top"
        *ngIf="therapyTemplateListConfig.list.items && therapyTemplateListConfig.list.count === 0">
        {{ anyItem | translate }}
    </div>
</ion-content>
<ion-footer class="no-border ion-margin-top ion-margin-bottom">
    <div
        class="flex-container-row full-width justify-content-space-between ion-padding-end ion-padding-start"
        *ngIf="therapyTemplateListConfig.list?.items">
        <ion-button
            (click)="dismissModal()"
            class="btn-font-medium"
            color="danger"
            >{{ 'CANCEL' | translate }}</ion-button
        >
        <ion-button
            (click)="returnSelectedTherapyTemplate()"
            [disabled]="addToSelectedTherapyTemplates.length <= 0"
            class="btn-font-medium"
            >{{ 'SELECT_ITEM_CONFIRM' | translate }}
        </ion-button>
    </div>
</ion-footer>
