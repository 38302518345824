<ion-header>
    <ion-toolbar
        color="primary"
        style="padding-left: 1em">
        <div slot="start">{{ title }}</div>
        <ion-button
            slot="end"
            (click)="dismissModal()"
            fill="outline">
            <ion-icon
                name="close"
                style="color: var(--ion-color-primary-contrast)"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="flex-container-row justify-content-flex-end align-items-flex-end full-width">
        <curafida-search-and-button
            class="full-width ion-padding-start ion-padding-end"
            [(ngModel)]="searchTerm"
            [hideButton]="true"
            [hideFilter]="!hasFilter"
            [filterLength]="filterTags?.length"
            (openFilterModal)="setFilter()"
            (getItemList)="initExerciseList({ offset: 0 }, searchTerm)">
        </curafida-search-and-button>
    </div>
    <div class="full-width">
        <div class="full-width ion-padding no-vertical-padding">
            <curafida-table
                *ngIf="exerciseListConfig?.list?.total > 0"
                [isLoadingSuccess]="isLoadingSuccess"
                class="full-width"
                [listTableConfig]="exerciseListConfig"
                (openDetail)="onActionOnItem($event)"
                (updateTable)="initExerciseList($event, searchTerm)">
            </curafida-table>
            <curafida-loading
                *ngIf="!isLoadingSuccess"
                [showSpinner]="isLoading"
                [showActionButtonOnTimeout]="true"
                (onTimeout)="isLoading = false; isLoadingSuccess = false"
                (actionButtonClicked)="initExerciseList({ offset: 0, limit: 0 }, searchTerm)">
            </curafida-loading>
            <div
                class="flex-container-row justify-content-flex-start full-width ion-margin-top"
                *ngIf="isLoadingSuccess && exerciseListConfig?.list?.total === 0">
                {{ anyItem | translate }}
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="no-border">
    <div
        class="flex-container-row full-width justify-content-space-between ion-padding"
        *ngIf="isLoadingSuccess">
        <ion-button
            (click)="dismissModal()"
            class="btn-font-medium"
            color="danger"
            >{{ 'CANCEL' | translate }}</ion-button
        >
        <ion-button
            *ngIf="exerciseListConfig?.list?.total > 0"
            (click)="returnSelectedExercise()"
            [disabled]="addToSelectedExercises.length <= 0"
            class="btn-font-medium"
            >{{ 'SELECT_ITEM_CONFIRM' | translate }}
        </ion-button>
    </div>
</ion-footer>
