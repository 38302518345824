<ion-header>
    <ion-toolbar
        color="primary"
        style="padding-left: 1em"
        class="no-vertical-padding">
        <div
            slot="start"
            class="flex-container-row ion-padding-top ion-padding-bottom">
            <ion-icon
                *ngIf="modalConfig.titleIcon"
                style="font-size: 20px"
                name="{{ modalConfig.titleIcon }}"></ion-icon>
            <p class="no-padding ion-no-margin">&nbsp;{{ modalConfig.title | translate }}</p>
        </div>
        <ion-button
            slot="end"
            (click)="dismissModal()"
            fill="outline">
            <ion-icon
                name="close"
                style="color: #ffffff"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div
        class="flex-container-row ion-margin"
        style="white-space: pre-line">
        <div
            [hidden]="!modalConfig.descriptionIsHTML"
            id="modalDescription"></div>
        <div [hidden]="modalConfig.descriptionIsHTML">{{ modalConfig.description | translate }}</div>
    </div>
    <ion-select
        [hidden]="!modalConfig.hasSelectList"
        cancelText="Abbrechen"
        interface="popover"
        placeholder="Bitte auswählen"
        [(ngModel)]="itemSelected"
        (ionChange)="setButtonState()">
        <ion-select-option
            *ngFor="let item of modalConfig.selectItem"
            [value]="item.value"
            >{{ item.label }}</ion-select-option
        >
    </ion-select>
</ion-content>

<ion-footer>
    <div class="flex-container-row justify-content-space-between ion-padding-start ion-padding-end ion-padding-bottom">
        <ion-button
            *ngIf="modalConfig.buttonLeft"
            (click)="emitLeftButton()"
            class="btn-modal"
            [disabled]="isButtonDisabled"
            [color]="modalConfig.buttonLeft.buttonColor">
            {{ modalConfig.buttonLeft.buttonText | translate }}
        </ion-button>
        <ion-button
            *ngIf="modalConfig.buttonRight"
            (click)="emitRightButton()"
            class="btn-modal"
            [disabled]="isButtonDisabled"
            [color]="modalConfig.buttonRight.buttonColor">
            {{ modalConfig.buttonRight.buttonText | translate }}
        </ion-button>
    </div>
</ion-footer>
