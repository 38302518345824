import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Logger, LoggingService, LogLevel } from '../logging/logging.service';
import { SplashScreenStateService } from '../common/services/splash-screen-state.service';
import { PageConfig } from '../config/entities';
import { ConfigService } from '../config/services';
import { ToastrService } from 'ngx-toastr';
import { CurafidaFeature } from '../config/entities/base-page-config';
import { CurafidaAuthService } from '../auth/services';

@Injectable({
    providedIn: 'root',
})
export class RoleGuardService {
    pageConfig: PageConfig;
    private readonly log: Logger;

    constructor(
        public authService: CurafidaAuthService,
        public router: Router,
        public configService: ConfigService,
        public loggingService: LoggingService,
        public splashScreenStateService: SplashScreenStateService,
        public toastService: ToastrService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.log.setLevel(LogLevel.INFO);
    }

    static trimUrl(url: string): string {
        if (!url || url.length <= 1) {
            return '';
        }
        if (url.charAt(0) === '/') {
            url = url.substr(1);
        }
        if (url.charAt(url.length - 1) === '/') {
            url = url.substr(0, url.length - 1);
        }
        return url;
    }

    /**
     * At the moment pageConfigs that include a URL with path variables as an infix cannot be matched:
     * Example: /member/patient/{someID}/details
     * This example will only match /member/patient just like /member/patient/{someID} and /member/patient will.
     * @param route: ActivatedRouteSnapshot
     */
    async canActivate(route: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
        const userRoles = await this.authService.getRoles();
        // @ts-ignore
        const url = route._routerState.url.split('?')[0];
        let pageRoles: string[] = this.findPageRoles(url);
        if (pageRoles.length === 0) {
            for (const pageConfig of this.configService.config.pageConfigs) {
                if (pageConfig.childPageConfigs) {
                    for (const subPageConfig of pageConfig.childPageConfigs) {
                        if (subPageConfig.url && url.includes(subPageConfig.url)) {
                            this.pageConfig = subPageConfig;
                            pageRoles = subPageConfig.role;
                        }
                    }
                }
            }
        }
        if (
            this.pageConfig?.module &&
            !this.configService.config.features[this.pageConfig.module]?.enabled &&
            this.pageConfig.module !== CurafidaFeature.common
        ) {
            this.log.warn(
                `[canActivate] Module not activate (${this.pageConfig.module}) for the page ${url}. Navigating to root /`,
            );
            this.toastService.show('AUTHORIZATION.NO_SITE_ENTITLEMENT', '', null, 'error');
            this.splashScreenStateService.stop();
            return this.router.parseUrl('/');
        }
        if (pageRoles.length === 0) {
            this.log.debug(`[canActivate] No specific role required. Stay on ${url} and return true.`);
            this.splashScreenStateService.stop();
            return true;
        }
        for (const userRole of userRoles) {
            if (pageRoles.includes(userRole)) {
                this.log.debug(`[canActivate] User has required roles. Stay on ${url} and return true.`);
                this.splashScreenStateService.stop();
                return true;
            }
        }
        this.log.warn(`[canActivate] Insufficient Roles (${pageRoles}) for the page ${url}. Navigating to root /`);
        this.toastService.show('AUTHORIZATION.NO_SITE_ENTITLEMENT', '', null, 'error');
        this.splashScreenStateService.stop();
        return this.router.parseUrl('/');
    }

    private findPageRoles(url: string, pageRoles: string[] = []): string[] {
        if ((pageRoles && pageRoles.length > 0) || url.length <= 1) {
            return pageRoles;
        }
        url = RoleGuardService.trimUrl(url);
        const matchingPageConfig = this.configService.config.pageConfigs.find(
            (pageConfig) => RoleGuardService.trimUrl(pageConfig.url) === url,
        );
        this.pageConfig = matchingPageConfig;
        pageRoles = matchingPageConfig ? matchingPageConfig.role : [];
        return this.findPageRoles(url.substr(0, url.lastIndexOf('/')), pageRoles);
    }
}
