import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-language-selection',
    templateUrl: './language-selection.component.html',
    styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {
    langSubscription: Subscription;
    currentLang: string;

    langList: string[] = ['de', 'en'];

    constructor(private translate: TranslateService, private popoverCtrl: PopoverController) {}

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.langSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLang = this.translate.currentLang;
            this.popoverCtrl.dismiss();
        });
    }

    selectLanguage(lang: string) {
        this.translate.use(lang);
    }
}
