<ion-header>
    <curafida-toolbar-modal [title]="title"></curafida-toolbar-modal>
</ion-header>

<ion-content class="ion-padding">
    <curafida-table
        *ngIf="exerciseSessionsOfUserTable && exerciseSessionsOfUserTable.list.items.length > 0"
        [listTableConfig]="exerciseSessionsOfUserTable"></curafida-table>
    <div
        class="flex-container-row justify-content-flex-start full-width ion-margin-top"
        *ngIf="exerciseSessionsOfUserTable && exerciseSessionsOfUserTable.list.items.length <= 0">
        Keine Übungsstatistiken vorhanden
    </div>
</ion-content>
