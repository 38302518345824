import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TutorialDefinition } from '../../../measurement/entities/tutorial-slide';
import { UserRoles } from '../../../auth/entities/user';
import { ToolbarType } from '../../entities/view/toolbar-type';
import { CurafidaAuthService } from '../../../auth/services';

@Injectable({
    providedIn: 'root',
})
export class ToolbarService implements OnDestroy {
    title = new BehaviorSubject('');
    backButtonStringName = new BehaviorSubject('');
    backButtonAndBurgerMenuIconState = new BehaviorSubject(true);
    helpModalComponent = new BehaviorSubject(null);
    roles = new BehaviorSubject([]);
    toolbarType = new BehaviorSubject(ToolbarType.NORMAL);
    feedbackMainCategories: BehaviorSubject<string> = new BehaviorSubject('');
    feedbackSubCategories: BehaviorSubject<string> = new BehaviorSubject('');
    previousStep = new BehaviorSubject(false);
    hideToolbar = new BehaviorSubject(false);

    constructor(private readonly authService: CurafidaAuthService) {
        authService.session$.subscribe((session) => {
            this.roles.next(session?.user?.roles || []);
        });
    }

    subscribeHideToolbar(onNext): Subscription {
        return this.hideToolbar.subscribe(onNext);
    }

    setHideToolbar(disable) {
        this.hideToolbar.next(disable);
    }

    getHideToolbar() {
        return this.hideToolbar.getValue();
    }

    subscribePreviousStep(onNext): Subscription {
        return this.previousStep.subscribe(onNext);
    }

    setPreviousStep(enable) {
        this.previousStep.next(enable);
    }

    getPreviousStep() {
        return this.previousStep.getValue();
    }

    subscribeMainFeedbackCategory(onNext): Subscription {
        return this.feedbackMainCategories.subscribe(onNext);
    }

    setfeedbackMainCategory(category: string) {
        this.feedbackMainCategories.next(category);
    }

    getfeedbackMainCategory() {
        return this.feedbackMainCategories.getValue();
    }

    subscribeSubFeedbackCategory(onNext): Subscription {
        return this.feedbackSubCategories.subscribe(onNext);
    }

    setfeedbackSubCategory(category: string) {
        this.feedbackSubCategories.next(category);
    }

    getfeedbackSubCategory() {
        return this.feedbackSubCategories.getValue();
    }

    subscribeTitle(onNext): Subscription {
        return this.title.subscribe(onNext);
    }

    setTitle(title: string) {
        this.title.next(title);
    }

    getTitle() {
        return this.title.getValue();
    }

    subscribeBackButtonStringName(onNext): Subscription {
        return this.backButtonStringName.subscribe(onNext);
    }

    setBackButtonStringName(value: string) {
        this.backButtonStringName.next(value);
    }

    getBackButtonStringName() {
        return this.backButtonStringName.getValue();
    }

    subscribeBackButtonAndBurgerMenuIconState(onNext): Subscription {
        return this.backButtonAndBurgerMenuIconState.subscribe(onNext);
    }

    setBackButtonAndBurgerMenuIconState(value: boolean) {
        this.backButtonAndBurgerMenuIconState.next(value);
    }

    getBackButtonAndBurgerMenuIconState() {
        return this.backButtonAndBurgerMenuIconState.getValue();
    }

    subscribeHelpModalComponent(onNext): Subscription {
        return this.helpModalComponent.subscribe(onNext);
    }

    setHelpModalComponent(value: TutorialDefinition) {
        this.helpModalComponent.next(value);
    }

    getHelpModalComponent() {
        return this.helpModalComponent.getValue();
    }

    subscribeUserRole(onNext): Subscription {
        return this.roles.subscribe(onNext);
    }

    private setUserRole(value: UserRoles[] | string[]) {
        this.roles.next(value);
    }

    getHelpUserRole() {
        return this.roles.getValue();
    }

    subscribeToolbarType(onNext): Subscription {
        return this.toolbarType.subscribe(onNext);
    }

    getToolbarType(): ToolbarType {
        return this.toolbarType.getValue();
    }

    setToolbarType(value: ToolbarType) {
        this.toolbarType.next(value);
    }

    ngOnDestroy(): void {}
}
