import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonComponentsModule } from '../../common/components/common-components.module';
import { CurafidaCommonModule } from '../../common/curafida-common.module';
import { RouterModule } from '@angular/router';
import { UserComponentsModule } from '../../user/components/user-components.module';
import { PipeTableLib } from '../../table/pipe/pipes-table-lib.module';
import { KioskTaskModalComponent } from './kiosk-task-modal/kiosk-task-modal.component';
import { KioskNotificationsModalComponent } from './kiosk-notifications-modal/kiosk-notifications-modal.component';
import { KioskTrainingModalComponent } from './kiosk-training-modal/kiosk-training-modal.component';
import { KioskTrainingVideoModalComponent } from './kiosk-training-video-modal/kiosk-training-video-modal.component';
import { KioskActionElementModalComponent } from './kiosk-action-element-modal/kiosk-action-element-modal.component';
import { KioskAppointmentVideoModalComponent } from './kiosk-appointment-video-modal/kiosk-appointment-video-modal.component';
import { JitsiModule } from '../../video-conference/jitsi/jitsi.module';
import { KioskWeekResumeModalComponent } from './kiosk-week-resume-modal/kiosk-week-resume-modal.component';
import { KioskTaskPreviewVideoModalComponent } from './kiosk-task-preview-video-modal/kiosk-task-preview-video-modal.component';
import { KioskTaskVideoModalComponent } from './kiosk-task-video-modal/kiosk-task-video-modal.component';
import { VideoConferenceModule } from '../../video-conference/video-conference.module';

@NgModule({
    declarations: [
        KioskTaskModalComponent,
        KioskNotificationsModalComponent,
        KioskTrainingModalComponent,
        KioskTrainingVideoModalComponent,
        KioskActionElementModalComponent,
        KioskAppointmentVideoModalComponent,
        KioskWeekResumeModalComponent,
        KioskTaskPreviewVideoModalComponent,
        KioskTaskVideoModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        UserComponentsModule,
        CurafidaCommonModule,
        RouterModule,
        PipeTableLib,
        JitsiModule,
        VideoConferenceModule,
    ],
    exports: [
        KioskTaskModalComponent,
        KioskNotificationsModalComponent,
        KioskTrainingModalComponent,
        KioskTrainingVideoModalComponent,
        KioskActionElementModalComponent,
        KioskAppointmentVideoModalComponent,
        KioskWeekResumeModalComponent,
        KioskTaskPreviewVideoModalComponent,
        KioskTaskVideoModalComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KioskComponentsModule {}
