<ion-list>
    <div *ngFor="let item of actionItems; let i = index">
        <ion-buttons class="full-width">
            <ion-button
                (click)="sendAction(item.action)"
                *ngIf="isType(item, ActionMenuItemType.BUTTON)"
                [color]="item.color"
                [disabled]="item.disabled"
                class="full-width">
                <span class="full-width flex-container-row text-align-start"
                    ><ion-icon
                        *ngIf="item.icon"
                        name="{{ item.icon }}"></ion-icon
                    >{{ item.label | translate }}</span
                >
            </ion-button>
        </ion-buttons>
        <span
            *ngIf="isType(item, ActionMenuItemType.INFO_TEXT)"
            class="selection-footer"
            >{{ item.label }}</span
        >
        <ion-item-divider
            *ngIf="isType(item, ActionMenuItemType.DIVIDER)"
            class="line-separator"></ion-item-divider>
    </div>
</ion-list>
