import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VitalParameterColors } from '../../entities/examination-states';

@Component({
    selector: 'lib-rectangle-value-display',
    templateUrl: './rectangle-value-display.component.html',
    styleUrls: ['./rectangle-value-display.component.scss'],
})
export class RectangleValueDisplayComponent implements OnInit, OnChanges {
    @Input()
    value = '';

    @Input()
    color: string;

    private _units: string[] = [];

    @Input()
    set units(unitsInput: string[]) {
        if (unitsInput && unitsInput.length >= 1 && !!this.selectedUnit) {
            this.selectedUnit = unitsInput[0];
        }
        this._units = unitsInput;
    }

    get units(): string[] {
        return this._units;
    }

    @Input()
    selectedUnit?: string;

    @Output() unitChangeEvent = new EventEmitter<string>();

    @Input()
    id = 'rectangle';

    @Input()
    comment = '';

    @Input()
    rangeColor = false;

    @Input()
    integer = false;

    @Input()
    vitalParametarColor: VitalParameterColors;

    @Input()
    selected = true;

    constructor() {}

    ngOnInit() {}

    publishUnitChange() {
        this.unitChangeEvent.emit(this.selectedUnit);
    }

    ngOnChanges() {
        // If value does not fulfill the next conditions then a "-" will be shown. Value can not be undefined nor empty
        if (this.value === undefined || this.value === '') {
            this.value = '-';
        }
        if (document.getElementById(this.id)) {
            if (!this.selected) {
                document.getElementById(this.id).style.border = '4px solid #86888f';
            } else {
                document.getElementById(this.id).style.border = '4px solid var(--ion-color-primary)';
            }
        }
    }
}
