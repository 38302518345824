import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IonicStorageModule} from '@ionic/storage-angular';
import {HttpClientModule} from '@angular/common/http';
import * as commonModuleDe from '../../../../libs/common/i18n/de.json';
import * as commonModuleEn from '../../../../libs/common/i18n/en.json';
import * as genericDe from '../assets/i18n/de.json';
import * as genericEn from '../assets/i18n/en.json';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {ConfigService} from '../../../../libs/config/services';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonComponentsModule} from '../../../../libs/common/components/common-components.module';
import {MeasurementModule} from '../../../../libs/measurement/measurement.module';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {HTTP} from '@ionic-native/http/ngx';
import {BluetoothLE} from '@ionic-native/bluetooth-le/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {SplashScreenStateService} from '../../../../libs/common/services/splash-screen-state.service';
import {LoggingModule} from '../../../../libs/logging/logging.module';
import {ToastrModule} from 'ngx-toastr';
import {GlobalErrorHandler} from '../../../../libs/config/services/global-error-handler';
import {HttpCancelService} from '../../../../libs/common/services/http-cancel.service';
import {KioskModule} from '../../../../libs/kiosk/kiosk.module';
import {ApiService} from '../../../../libs/api';
import {AuthModule} from '../../../../libs/auth/auth-module';
import {environment} from "../environments/environment";

export class IonicGestureConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        const mc = new (window as any).Hammer(element);
        if (window) {
            for (const eventName in this.overrides) {
                if (eventName) {
                    mc.get(eventName).set(this.overrides[eventName]);
                }
            }
        }
        return mc;
    }
}

export class CurafidaTranslateLoader extends TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        if (lang === 'en') {
            return of({...(commonModuleEn as any).default, ...(genericEn as any).default});
        }
        return of({...(commonModuleDe as any).default, ...(genericDe as any).default});
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonComponentsModule,
        IonicModule.forRoot({}),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useClass: CurafidaTranslateLoader},
        }),
        BrowserAnimationsModule,
        MeasurementModule,
        KioskModule,
        HammerModule,
        LoggingModule,
        ToastrModule.forRoot({
            maxOpened: 4,
            resetTimeoutOnDuplicate: true,
            includeTitleDuplicates: true,
            preventDuplicates: true,
            closeButton: true,
        }),
        AuthModule,
    ],

    providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        ConfigService,
        StatusBar,
        InAppBrowser,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: 'environmentConfig', useFactory: () => environment},
        {provide: 'keycloakConfig', useFactory: () => environment.keycloak},
        {provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig},
        BluetoothSerial,
        HTTP,
        BluetoothLE,
        SplashScreenStateService,
        ScreenOrientation,
        HttpCancelService,
        ApiService,
        //  { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}
