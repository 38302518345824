import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RangeDefinition } from '../range-selector/range-selector.component';

@Component({
    selector: 'lib-range-selector-group',
    templateUrl: './range-selector-group.component.html',
    styleUrls: ['./range-selector-group.component.scss'],
})
export class RangeSelectorGroupComponent {
    @Input()
    mainValue: RangeDefinition;
    @Input()
    secondaryValue: RangeDefinition;
    @Input()
    operator: string;

    @Output()
    setMainValue: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    setSecondaryValue: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}

    setActionMainValue(value: number) {
        this.setMainValue.emit(Number(value));
    }

    setActionSecondaryValue(value: number) {
        this.setSecondaryValue.emit(Number(value));
    }
}
