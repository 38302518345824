<div
    id="{{ id }}"
    [ngClass]="{ 'output-measurement': selected, 'output-measurement-disabled': !selected }"
    style="position: relative">
    <div
        class="flex-container-row justify-content-center full-width configuration-z-index"
        style="align-items: center; justify-content: center">
        <span
            *ngIf="!comment"
            class="output-letters"
            >{{ value | measurementNumber : integer }}</span
        >
        <span
            *ngIf="comment"
            class="output-comment"
            >{{ comment }}</span
        >
    </div>
    <div
        *ngIf="!comment"
        class="flex-container-row justify-content-end full-width ion-padding configuration-z-index"
        style="align-items: center; justify-content: end">
        <span
            style="font-size: 25px"
            *ngIf="units && units.length <= 1"
            >{{ units[0] }}</span
        >
        <span
            style="font-size: 25px"
            *ngIf="units && units.length > 1">
            <ion-select
                [(ngModel)]="selectedUnit"
                interface="popover"
                (ionChange)="publishUnitChange()">
                <ion-select-option
                    *ngFor="let unit of units"
                    [value]="unit"
                    >{{ unit }}</ion-select-option
                >
            </ion-select>
        </span>
    </div>
</div>
