import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';

export enum InputMode {
    DECIMAL = 'decimal',
    NUMERIC = 'numeric',
    EMAIL = 'email',
    NONE = 'none',
    TEXT = 'text',
    URL = 'url',
}

@Component({
    selector: 'curafida-text-input',
    templateUrl: './curafida-text-input.component.html',
    styleUrls: ['./curafida-text-input.component.scss'],
})
export class CurafidaTextInputComponent extends CurafidaInputComponent {
    @Input()
    unit: string;

    @Input()
    explanation: string;

    @Input()
    inputType = 'text';

    // A hint to the app for which keyboard to display
    @Input()
    inputMode?: InputMode;

    @Input()
    labelIcon = '';

    @Input()
    labelIconColor = '';

    @Input()
    borderColorPrimary = false;

    @Input()
    isMobile: boolean;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
    ) {
        super(ngControl);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    changeValue(value: any) {
        if (this.formGroup && this.formControlName) {
            this.formGroup?.controls[this.formControlName].setValue(value?.detail?.value);
            if (value?.detail?.value) this.formGroup.controls[this.formControlName].markAsDirty();
        }
        value.stopPropagation();
    }
}
