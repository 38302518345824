export enum UserRoles {
    PATIENT = 'PATIENT',
    /** @deprecated */
    THERAPIST = 'THERAPIST',
    /** @deprecated */
    RECEPTIONIST = 'RECEPTIONIST',
    CAREGIVER = 'CAREGIVER',
    PHYSICIAN = 'PHYSICIAN',
    ADMIN = 'ADMIN',
    SUPERVISOR = 'SUPERVISOR',
    USER_MANAGER = 'USER_MANAGER',
    CATALOG_MANAGER = 'CATALOG_MANAGER',
    ANALYST = 'ANALYST',
    MFA = 'MFA',

    /** @deprecated */
    OFFLINE_USER = 'OFFLINE_USER',

    MANAGE_THERAPY_TEMPLATE = `manage_therapytemplate`,
    MANAGE_THERAPY = 'manage_therapy',
    MANAGE_EXERCISE = 'manage_exercise',
    MANAGE_DEVICE = 'manage_device',
    MANAGE_MEDICATION = 'manage_medication',
    READ_MEDICATION = 'read_medication',

    MANAGE_NOTE = 'manage_note',
    write_note = 'write_note',
    read_note = 'read_note',
    MANAGE_NOTE_CHARACTERISTICS = 'manage_note_characteristics',

    MANAGE_SUPERVISOR = 'manage_supervisor',
    MANAGE_CAREGIVER = 'manage_caregiver',
    READ_THERAPY = 'read_therapy',

    MANAGE_MEDICAL_HISTORY = 'manage_medical_history',
    READ_MEDICAL_HISTORY = 'read_medical_history',

    MANAGE_MEASUREMENT = 'manage_measurement',
    CREATE_MEASUREMENT = 'create_measurement',
    READ_MEASUREMENT = 'read_measurement',
    UPDATE_MEASUREMENT = 'update_measurement',
    DELETE_MEASUREMENT = 'delete_measurement',

    MANAGE_USER = 'manage_user',
    CREATE_USER = 'create_user',
    READ_USER = 'read_user',
    UPDATE_USER = 'update_user',
    DELETE_USER = 'delete_user',

    CREATE_EXAMINATION = 'create_examination',

    MANAGE_PATIENT = 'manage_patient',
    READ_PATIENT = 'read_patient',
    DELETE_PATIENT = 'delete_patient',
    CREATE_PATIENT = 'create_patient',
    CREATE_SUPERVISED_PATIENT = 'create_supervised_patient',

    MANAGE_GROUP = 'manage_group',
    READ_GROUP = 'read_group',

    MANAGE_CONSULTATION = 'manage_consultation',
    MANAGE_CONSULTATION_SERVICES = 'manage_consultation_services',
    MANAGE_CONSULTATION_SETTINGS = 'manage_consultation_settings',
    READ_CONSULTATION = 'read_consultation',

    MANAGE_ORDER = 'manage_order',
    MANAGE_ORDER_SERVICES = 'manage_order_services',
    MANAGE_ORDER_SETTINGS = 'manage_order_settings',
    READ_ORDER = 'read_order',

    MANAGE_CHAT = 'manage_chat',
    write_chat = 'write_chat',
    read_chat = 'read_chat',

    MANAGE_DICO_FEEDBACK = 'manage_dico_feedback',

    WRITE_QUESTIONNAIRE = 'write_questionnaire',
    QUERY_MEDITYME = 'query-medityme',
    DICO_FEEDBACKMANAGER = 'DICO_FEEDBACKMANAGER',
    DICO_USER = 'DICO_USER',
    DICO_STAFF_REPRESENTATIVE = 'DICO_STAFF_REPRESENTATIVE',
    DICO_ORGANIZATION_MANAGEMENT = 'DICO_ORGANIZATION_MANAGEMENT',
    DICO_CARE_SERVICE_STAFF = 'DICO_CARE_SERVICE_STAFF',
    DICO_CARE_SERVICE_MANAGEMENT = 'DICO_CARE_SERVICE_MANAGEMENT',
    DICO_ORGANIZATION_DEVELOPMENT = 'DICO_ORGANIZATION_DEVELOPMENT',
    DICO_IT = 'DICO_IT',
    DICO_RESIDINETIAL_MANAGEMENT = 'DICO_RESIDINETIAL_MANAGEMENT',

    read_medical_patientdata = 'read_medical_patientdata',
    write_medical_patientdata = 'write_medical_patientdata',
    patient_coordination = 'patient_coordination',
    patient_recruitment = 'patient_recruitment',
}

/**
 * A list of roles and rights which are assignable to a user in curafida.
 * This does not include fine granular rights, which are only implicitly
 * assigned through other roles/rights.
 *
 * Use this enum preferably instead of the UserRoles enum.
 *
 * https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/wikis/Rollen-und-Rechte
 */
export enum AssignableUserRole {
    /**
     * Person in Behandlung.
     */
    PATIENT = 'PATIENT',

    /**
     * Verantwortlich für die Behandlung von ihm zugewiesenen Patienten,
     * kann aber auch eine Person außerhalb der Einrichtung sein.
     */
    SUPERVISOR = 'SUPERVISOR',

    /**
     * Verantwortlich für die Behandlung von allen Patienten
     * in der eigenen Gruppe (inklusive Untergruppen).
     */
    CAREGIVER = 'CAREGIVER',

    /**
     * Zuständig für die Benutzer- und Gruppenverwaltung
     */
    USER_MANAGER = 'USER_MANAGER',

    /**
     * Zuständig für die Verwaltung von Katalogen (Vorlagen)
     */
    CATALOG_MANAGER = 'CATALOG_MANAGER',

    /**
     * Verantwortlich für Auswertung der Daten für Studienmanagement
     * und Evaluation für alle Patienten in der eigenen Gruppe.
     */
    ANALYST = 'ANALYST',

    /**
     * Medizinische Daten lesen (Erweiterung)
     */
    read_medical_patientdata = 'read_medical_patientdata',

    /**
     * Medizinische Daten bearbeiten (Erweiterung)
     */
    write_medical_patientdata = 'write_medical_patientdata',

    /**
     * Patientenkoordination (Erweiterung)
     */
    patient_coordination = 'patient_coordination',

    /**
     * Patientenrekrutierung (Erweiterung)
     */
    patient_recruitment = 'patient_recruitment',

    /**
     * Erlaubt das Erstellen eines Patientenkontos, welches vom aktuellen Benutzer betreut wird. (Erweiterung)
     */
    create_supervised_patient = 'create_supervised_patient',

    /**
     * Notizen lesen (Erweiterung)
     */
    read_note = 'read_note',

    /**
     * Notizen bearbeiten (Erweiterung)
     */
    write_note = 'write_note',

    /**
     * Chats lesen (Erweiterung)
     */
    write_chat = 'write_chat',

    /**
     * Chats bearbeiten (Erweiterung)
     */
    read_chat = 'read_chat',
}

export class CompositeRole {
    constructor(public roleName: AssignableUserRole, public compositeRoleNames: AssignableUserRole[]) {}

    public flatten(): AssignableUserRole[] {
        return [this.roleName, ...this.compositeRoleNames];
    }
}

export const assignableCompositeRoles = [
    new CompositeRole(AssignableUserRole.write_note, [AssignableUserRole.read_note]),
    new CompositeRole(AssignableUserRole.write_chat, [AssignableUserRole.read_chat]),
    new CompositeRole(AssignableUserRole.write_medical_patientdata, [AssignableUserRole.read_medical_patientdata]),
    new CompositeRole(AssignableUserRole.patient_recruitment, [AssignableUserRole.create_supervised_patient]),
];

/**
 * Validates a set of roles:
 *  1. List items must be unique
 *  2. Includes only assignable roles
 *  3. Includes no conflicting roles
 *  4. Includes at least one role
 */
export function isRoleAssignmentValid(currentRoles: AssignableUserRole[]): boolean {
    if (!currentRoles || currentRoles.length < 1) {
        // List must include at least one role
        return false;
    }
    if (
        currentRoles.filter((x) =>
            [
                AssignableUserRole.PATIENT,
                AssignableUserRole.CAREGIVER,
                AssignableUserRole.USER_MANAGER,
                AssignableUserRole.CATALOG_MANAGER,
                AssignableUserRole.ANALYST,
                AssignableUserRole.SUPERVISOR,
            ].includes(x),
        ).length < 1
    ) {
        // List must include at least one base role
        return false;
    }
    if (currentRoles.length !== [...new Set(currentRoles)].length) {
        // List items are not unique
        return false;
    }
    const nonAssignableRoles = currentRoles.filter((x) => !Object.values(AssignableUserRole).includes(x));
    if (nonAssignableRoles.length !== 0) {
        // currentRoles contains non assignable roles
        return false;
    }
    if (currentRoles.includes(AssignableUserRole.PATIENT)) {
        // Users with the role PATIENT are not allowed to have any other role
        return currentRoles.length === 1;
    }
    if (currentRoles.includes(AssignableUserRole.SUPERVISOR)) {
        // Users with the role SUPERVISOR are only allowed to have the following
        // additional roles
        const allowedSupervisorRoles = [AssignableUserRole.SUPERVISOR, AssignableUserRole.create_supervised_patient];
        if (currentRoles.filter((x) => !allowedSupervisorRoles.includes(x)).length > 0) {
            return false;
        }
    }
    return true;
}

/**
 * Based on a current set of assigned roles other roles
 * can be assigned.
 *
 * E.g. If a user has role PATIENT, then he is not allowed to have other roles.
 *
 */
export function getAssignableRoles(currentRoles: AssignableUserRole[]): AssignableUserRole[] {
    let baseSelection: AssignableUserRole[] = [
        // Base Composite Roles
        AssignableUserRole.ANALYST,
        AssignableUserRole.CAREGIVER,
        AssignableUserRole.SUPERVISOR,
        AssignableUserRole.USER_MANAGER,
        AssignableUserRole.CATALOG_MANAGER,
        AssignableUserRole.PATIENT,
    ];
    if (currentRoles.length === 0) {
        return baseSelection;
    }
    if (currentRoles.includes(AssignableUserRole.PATIENT)) {
        return [AssignableUserRole.PATIENT];
    }
    if (currentRoles.includes(AssignableUserRole.SUPERVISOR)) {
        return [AssignableUserRole.SUPERVISOR, AssignableUserRole.create_supervised_patient];
    }
    baseSelection = baseSelection.filter(
        (x) => ![AssignableUserRole.PATIENT, AssignableUserRole.SUPERVISOR].includes(x),
    );
    baseSelection.push(
        ...[
            // Base Extension Rights
            AssignableUserRole.read_medical_patientdata,
            AssignableUserRole.write_medical_patientdata,
            AssignableUserRole.patient_coordination,
            AssignableUserRole.patient_recruitment,
            AssignableUserRole.read_note,
            AssignableUserRole.write_note,
            AssignableUserRole.read_chat,
            AssignableUserRole.write_chat,
        ],
    );
    if (currentRoles.includes(AssignableUserRole.write_medical_patientdata)) {
        baseSelection = baseSelection.filter((x) => x !== AssignableUserRole.read_medical_patientdata);
    }
    if (currentRoles.includes(AssignableUserRole.write_note)) {
        baseSelection = baseSelection.filter((x) => x !== AssignableUserRole.read_note);
    }
    if (currentRoles.includes(AssignableUserRole.write_chat)) {
        baseSelection = baseSelection.filter((x) => x !== AssignableUserRole.read_chat);
    }
    return baseSelection;
}
