import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormat } from '../entities/table';

@Pipe({
    name: 'DateTransform',
})
export class DateTransformPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: any, format: DateFormat): any {
        if (format && value !== '-') {
            if (format === DateFormat.DATE) return this.datePipe.transform(value, 'dd.MM.yyyy', 'de', 'de');
            if (format === DateFormat.DATE_AND_HOUR) {
                return `${this.datePipe.transform(value, 'dd.MM.yyyy', 'de', 'de')}, ${this.datePipe.transform(
                    value,
                    'HH:mm',
                    'de',
                    'de',
                )} Uhr`;
            }
            if (format === DateFormat.DATE_AND_HOUR_2_LINES) {
                return `${this.datePipe.transform(value, 'dd.MM.yyyy', 'de', 'de')}
${this.datePipe.transform(value, 'HH:mm', 'de', 'de')} Uhr`;
            }
        } else {
            return value;
        }
    }
}
