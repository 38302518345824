import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
    transform(value: { firstname: string; lastname: string }, isLastnameFirst = false): string {
        if (!value.firstname && !value.lastname) {
            return '-';
        }
        if (!value.firstname) {
            return value.lastname;
        }
        if (!value.lastname) {
            return value.firstname;
        }
        if (isLastnameFirst) {
            return `${value.lastname}, ${value.firstname}`;
        }
        return `${value.firstname} ${value.lastname}`;
    }
}
