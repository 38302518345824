import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MyMedaxQuestionnaireSubmissionComponent } from './components/my-medax-questionnaire-submission/my-medax-questionnaire-submission.component';
import { CurafidaCommonModule } from '../common/curafida-common.module';
import { UserComponentsModule } from '../user/components/user-components.module';
import { MyMedaxPatientFormModalComponent } from './components/my-medax-patient-form-modal/my-medax-patient-form-modal.component';
import { TableModule } from '../table/table.module';
import { QuestionnaireListModalComponent } from './components/questionnaire-list-modal/questionnaire-list-modal.component';

@NgModule({
    declarations: [
        MyMedaxQuestionnaireSubmissionComponent,
        MyMedaxPatientFormModalComponent,
        QuestionnaireListModalComponent,
    ],
    imports: [IonicModule, FormsModule, CommonModule, CurafidaCommonModule, UserComponentsModule, TableModule],
    exports: [
        MyMedaxQuestionnaireSubmissionComponent,
        MyMedaxPatientFormModalComponent,
        QuestionnaireListModalComponent,
    ],
})
export class MyMedaxModule {}
