import { enableProdMode } from '@angular/core';
import { CurafidaEnvironmentConfig } from '../common/entities/curafida-environment-config.type';
import { mergeDeep } from '../kc-auth/entities/curafida-keycloak-config.type';

export async function initEnvironment(environment: CurafidaEnvironmentConfig): Promise<void> {
    environment = await readRuntimeEnvironmentConfig(environment);
    if (environment?.production) {
        enableProdMode();
    }
    initIndexHmtlFile(environment);
}
export async function initEnv(environment: CurafidaEnvironmentConfig): Promise<void> {
    await readRuntimeConfig(environment);
    if (environment.production) {
        enableProdMode();
    }
    initIndexHmtlFile(environment);
}

async function readRuntimeEnvironmentConfig(
    environment: CurafidaEnvironmentConfig,
): Promise<CurafidaEnvironmentConfig> {
    try {
        // 'assets/extra/environment.json' is the main file for runtime configuration
        const response = await fetch('assets/extra/environment.json');
        const runtimeEnvJson: CurafidaEnvironmentConfig = await response?.json();
        if (runtimeEnvJson) {
            Object.assign(environment, mergeDeep(environment, runtimeEnvJson));
        }
    } catch (err) {
        console.warn('Fetching assets/extra/environment.json failed');
    }
    return environment;
}

async function readRuntimeConfig(environment: CurafidaEnvironmentConfig): Promise<CurafidaEnvironmentConfig> {
    // TODO: fetch in parallel for better performance
    try {
        // 'assets/environment.json' is generated from docker container env vars
        // This way is deprecated and should not be used anymore.
        const response = await fetch('assets/environment.json');
        const legacyEnvJson: CurafidaEnvironmentConfig = await response?.json();
        if (legacyEnvJson) {
            Object.assign(environment, mergeDeep(environment, legacyEnvJson));
        }
    } catch (err) {
        // INFO: This catch block is intentionally empty.
    }
    try {
        // 'assets/extra/environment.json' is the main file for runtime configuration
        const response = await fetch('assets/extra/environment.json');
        const runtimeEnvJson: CurafidaEnvironmentConfig = await response?.json();
        if (runtimeEnvJson) {
            Object.assign(environment, mergeDeep(environment, runtimeEnvJson));
        }
    } catch (err) {
        console.warn('Fetching assets/extra/environment.json failed');
    }
    return environment;
}

function initIndexHmtlFile(environment: CurafidaEnvironmentConfig) {
    // Set HTML title
    if (environment?.appDisplayName) {
        document.title = environment.appDisplayName;
    }
    // Set Browser Favicon
    if (environment?.theme?.faviconUrl) {
        const imgElem = document.createElement('link');
        imgElem.rel = 'icon';
        imgElem.type = 'image/png';
        imgElem.href = environment.theme.faviconUrl;
        document.head.appendChild(imgElem);
    }
}
