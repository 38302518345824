import { ExerciseSessionType } from './exercise-session-type';
import { ExerciseSessionState } from './exercise-session-state';
import { UserRoles } from '../../../auth/entities/user';
import { Exercise } from '../exercise';
import { ExerciseType } from './exercise-type';
import { ExerciseToolSettings } from './exercise-tool.dto';

export class ExerciseSessionDto {
    exercise_id?: number;
    therapy_id?: number;
    therapyExercise_id?: number;
    therapyExercise?: any;
    therapySessionId?: string;
    title: string;
    description: string;
    duration?: string;
    exerciseSessionType: ExerciseSessionType;
    exerciseSessionState: ExerciseSessionState;
    needsSchedule = false;
    autoActivation?: boolean;
    autoNoShow = false;
    minGroupSize?: number;
    maxGroupSize?: number;
    responsibleUserRole?: UserRoles;
    responsible?: string;
    exerciseType?: ExerciseType;
    parentId?: number;
    childOrder?: number;
    isGroup: boolean;
    location?: LocationInfo;
    presenceType?: PresenceType;
    exerciseToolSettings?: ExerciseToolSettings;
    exerciseSubType?: ExerciseSubType;
    metaInfo?: any;
    notifyOnStartTime?: boolean;
    notifyOnEndTime?: boolean;
    notifyOnDelayedTime?: boolean;
    concernedUsername?: string;

    static setExerciseSessionType(exerciseSessionType: ExerciseSessionType, maxGroupSize?: number) {
        if (exerciseSessionType === ExerciseSessionType.HOMEWORK) {
            return ExerciseSessionType.HOMEWORK;
        } else if (exerciseSessionType === ExerciseSessionType.GROUP && maxGroupSize === 1) {
            return 'SINGLE';
        } else if (exerciseSessionType === ExerciseSessionType.GROUP && maxGroupSize > 1) {
            return ExerciseSessionType.GROUP;
        }
    }
}

export class ExerciseSessionDtoBuilder {
    private exerciseSessionDto = new ExerciseSessionDto();

    setTherapyId(therapyId: number): ExerciseSessionDtoBuilder {
        this.exerciseSessionDto.therapy_id = therapyId;
        return this;
    }

    setNeedsSchedule(needsSchedule: boolean): ExerciseSessionDtoBuilder {
        this.exerciseSessionDto.needsSchedule = needsSchedule;
        return this;
    }

    setTherapySessionId(therapySessionId: string): ExerciseSessionDtoBuilder {
        this.exerciseSessionDto.therapySessionId = therapySessionId;
        return this;
    }

    setExerciseSessionState(exerciseSessionState: ExerciseSessionState): ExerciseSessionDtoBuilder {
        this.exerciseSessionDto.exerciseSessionState = exerciseSessionState;
        return this;
    }

    copyFromExercise(exercise: Exercise): ExerciseSessionDtoBuilder {
        this.exerciseSessionDto.exercise_id = exercise.id;
        this.exerciseSessionDto.therapyExercise_id = exercise.therapyExercise_id;
        this.exerciseSessionDto.title = exercise.title;
        this.exerciseSessionDto.description = exercise.description;
        this.exerciseSessionDto.duration = exercise.duration;
        this.exerciseSessionDto.exerciseSessionType = exercise.exerciseSessionType;
        this.exerciseSessionDto.needsSchedule = exercise.needsSchedule;
        this.exerciseSessionDto.autoActivation = true;
        this.exerciseSessionDto.minGroupSize = exercise.minGroupSize;
        this.exerciseSessionDto.maxGroupSize = exercise.maxGroupSize;
        this.exerciseSessionDto.responsibleUserRole = exercise.responsibleUserRole;
        this.exerciseSessionDto.exerciseType = exercise.exerciseType;
        this.exerciseSessionDto.isGroup = exercise.isGroup;
        this.exerciseSessionDto.location = exercise.location;
        this.exerciseSessionDto.exerciseToolSettings = exercise.exerciseToolSettings;
        this.exerciseSessionDto.presenceType = exercise.presenceType;
        this.exerciseSessionDto.exerciseSubType = exercise.exerciseSubType;
        this.exerciseSessionDto.notifyOnStartTime = exercise.notifyOnStartTime;
        this.exerciseSessionDto.notifyOnDelayedTime = exercise.notifyOnDelayedTime;
        this.exerciseSessionDto.notifyOnEndTime = exercise.notifyOnEndTime;
        return this;
    }

    /**
     * Copy and override all properties from the ExerciseSessionDto.
     *  exerciseSessionDto
     */
    copyFromExerciseSessionDto(exerciseSessionDto: ExerciseSessionDto): ExerciseSessionDtoBuilder {
        this.exerciseSessionDto.exercise_id = exerciseSessionDto.exercise_id;
        this.exerciseSessionDto.therapy_id = exerciseSessionDto.therapy_id;
        this.exerciseSessionDto.therapyExercise_id = exerciseSessionDto.therapyExercise_id;
        this.exerciseSessionDto.title = exerciseSessionDto.title;
        this.exerciseSessionDto.description = exerciseSessionDto.description;
        this.exerciseSessionDto.duration = exerciseSessionDto.duration;
        this.exerciseSessionDto.exerciseSessionType = exerciseSessionDto.exerciseSessionType;
        this.exerciseSessionDto.exerciseSessionState = exerciseSessionDto.exerciseSessionState;
        this.exerciseSessionDto.needsSchedule = exerciseSessionDto.needsSchedule;
        this.exerciseSessionDto.autoActivation = true;
        this.exerciseSessionDto.autoNoShow = exerciseSessionDto.autoNoShow;
        this.exerciseSessionDto.minGroupSize = exerciseSessionDto.minGroupSize;
        this.exerciseSessionDto.maxGroupSize = exerciseSessionDto.maxGroupSize;
        this.exerciseSessionDto.responsibleUserRole = exerciseSessionDto.responsibleUserRole;
        this.exerciseSessionDto.responsible = exerciseSessionDto.responsible;
        this.exerciseSessionDto.exerciseType = exerciseSessionDto.exerciseType;
        this.exerciseSessionDto.isGroup = exerciseSessionDto.isGroup;
        this.exerciseSessionDto.location = exerciseSessionDto.location;
        this.exerciseSessionDto.exerciseToolSettings = exerciseSessionDto.exerciseToolSettings;
        this.exerciseSessionDto.presenceType = exerciseSessionDto.presenceType;
        this.exerciseSessionDto.notifyOnStartTime = exerciseSessionDto.notifyOnStartTime;
        this.exerciseSessionDto.notifyOnDelayedTime = exerciseSessionDto.notifyOnDelayedTime;
        this.exerciseSessionDto.notifyOnEndTime = exerciseSessionDto.notifyOnEndTime;
        return this;
    }

    build(): ExerciseSessionDto {
        return this.exerciseSessionDto;
    }
}

export enum PresenceType {
    ONLINE = 'ONLINE',
    PRESENCE = 'PRESENCE',
    HYBRID = 'HYBRID',
}

export class LocationInfo {
    displayName: string;

    constructor(displayName: string) {
        this.displayName = displayName;
    }
}

export enum ExerciseSubType {
    MISSION = 'MISSION',
    FREETEXT = 'FREETEXT',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
    VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
    UPLOAD = 'UPLOAD',
    FHIR_QUESTIONNAIRE = 'FHIR_QUESTIONNAIRE',
}
