<div class="flex-container-row justify-content-space-between align-items-center full-width">
    <ion-segment
        (ionChange)="segmentChanged($event)"
        *ngIf="mode === 'md'"
        class="full-width flex-container-row no-horizontal-margin"
        mode="md"
        value="{{ segmentType }}">
        <ion-segment-button
            *ngFor="let tabItem of mainList"
            [disabled]="tabItem.disabled"
            [hidden]="!tabItem.isElementShow"
            [ngClass]="{
                'segment-button': mode === 'md',
                'segment-button-with-icon': tabItem.icon,
                'segment-button-without-icon': !tabItem.icon
            }"
            value="{{ tabItem.value }}">
            <curafida-app-icon
                *ngIf="tabItem.icon"
                [iconName]="tabItem.icon"
                [iconColour]="IconColour.PRIMARY"
                [iconSizeType]="IconSizeTyp.segment"></curafida-app-icon>
            <ion-text
                [ngClass]="{ 'margin-bottom-segment-button': !tabItem.icon }"
                class="segment-name"
                >{{ tabItem.name | translate }}</ion-text
            >
            <ion-badge
                *ngIf="tabItem.badge > 0 && !tabItem.disabled"
                class="cart-badge"
                color="secondary"
                >{{ tabItem.badge }}</ion-badge
            >
        </ion-segment-button>
    </ion-segment>

    <ion-segment
        (ionChange)="segmentChanged($event)"
        *ngIf="segmentList?.length > 0 && mode === 'ios'"
        [style.max-width]="completionPercent"
        class="background-primary no-horizontal-margin"
        mode="ios"
        style="height: 40px"
        value="{{ segmentType }}">
        <ion-segment-button
            *ngFor="let tabItem of mainList"
            [disabled]="tabItem.disabled"
            [hidden]="!tabItem.isElementShow"
            class="ios-button"
            value="{{ tabItem.value }}"
            >{{ tabItem.name | translate }}</ion-segment-button
        >
    </ion-segment>
    <ion-button
        (click)="presentPopover($event)"
        *ngIf="isMobile && segmentList.length > maxMainListItemMobile"
        [ngClass]="{ 'border-bottom': isSelectedSegmentInDropdownListIncluding() }"
        class="dropdown-button"
        fill="clear">
        <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-button>
    <ion-button
        (click)="presentPopover($event)"
        *ngIf="!isMobile && segmentList.length > maxMainListItem"
        [ngClass]="{ 'border-bottom': isSelectedSegmentInDropdownListIncluding() }"
        class="dropdown-button"
        fill="clear">
        <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-button>
</div>
