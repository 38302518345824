import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { ExerciseGoal, ExerciseGoalDtoWrapper } from '../../entities/exerciseGoal/exerciseGoalDto';

@Injectable({
    providedIn: 'root',
})
export class UsersExerciseGoalsService {
    constructor(public router: Router, protected http: HttpClient) {}

    getUserExerciseGoals(username: string, onlyListActive?: boolean): Promise<ExerciseGoal[]> {
        let url = `${ApiService.url}users/${username}/exerciseGoals`;
        let queryParams = '';
        if (onlyListActive) queryParams = `${queryParams}onlyListActive=${onlyListActive}&`;

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.get<ExerciseGoal[]>(url, ApiService.options).toPromise();
    }

    createUserExerciseGoal(username: string, exerciseGoalDtoWrapper: ExerciseGoalDtoWrapper): Promise<ExerciseGoal> {
        const url = `${ApiService.url}users/${username}/exerciseGoals`;
        return this.http.post<ExerciseGoal>(url, exerciseGoalDtoWrapper, ApiService.options).toPromise();
    }
}
