import { AfterViewInit, Component } from '@angular/core';
import { JitsiService } from '../jitsi.service';
import { Router } from '@angular/router';

@Component({
    selector: 'lib-jitsi',
    templateUrl: './jitsi.component.html',
    styleUrls: ['./jitsi.component.scss'],
})
export class JitsiComponent implements AfterViewInit {
    constructor(readonly router: Router, readonly jitsiService: JitsiService) {}

    ngAfterViewInit(): void {
        this.jitsiService.startJitsiConference();
    }

    hangUp(): void {
        console.log('Meeting end, navigate back: ' + this.jitsiService.current.lastNavigationUrl);
        if (this.jitsiService.current.lastNavigationUrl) {
            this.router.navigateByUrl(this.jitsiService.current.lastNavigationUrl).catch(console.error);
        } else {
            this.router.navigateByUrl('/').catch(console.error);
        }
    }
}
