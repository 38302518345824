<ion-list>
    <ion-item
        button
        *ngFor="let lang of langList"
        (click)="selectLanguage(lang)">
        <div
            class="lang-item-container"
            style="">
            <div [ngClass]="{ highlight: currentLang === lang }">
                {{ lang.toUpperCase() | translate }} - {{ lang.toUpperCase() }}
            </div>
            <img
                class="img-scale"
                src="assets/imgs/{{ lang }}.ico" />
        </div>
    </ion-item>
</ion-list>
