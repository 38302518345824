import { BuildType } from '../../config/entities/build-type';
import { FeaturesConfiguration } from '../../config/entities/base-page-config';
import { CurafidaKeycloakConfig } from '../../kc-auth/entities/curafida-keycloak-config.type';

export class CurafidaEnvironmentConfig {
    production?: boolean;
    appDisplayName?: string;
    appName?: string;
    appId?: string;
    isUsingMyMedax?: boolean;
    developmentStage: BuildType;
    keycloak: CurafidaKeycloakConfig;
    theme?: {
        colors?: {
            primary?: string;
            primaryContrast?: string;
            secondary?: string;
            secondaryContrast?: string;
            tertiary?: string;
            tertiaryContrast?: string;
            card?: string;
            cardContrast?: string;
        };
        logoUrl?: string;
        faviconUrl?: string;
    };
    features?: FeaturesConfiguration;
}
