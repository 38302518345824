<div
    class="flex-container-column"
    *ngIf="exerciseGoal">
    <div class="flex-container-row full-width">
        <div
            *ngIf="!isLabelDisabled && exerciseGoal.series && exerciseGoal.durationUnit"
            class="label-col">
            Dauer
        </div>
        <div
            *ngIf="exerciseGoal && exerciseGoal.series && exerciseGoal.durationUnit && !isOnlyToShow"
            class="flex-container-row">
            <lib-range-selector-group
                (setMainValue)="changeRangeValue($event, exerciseGoal, RangeTyp.SERIES)"
                (setSecondaryValue)="changeRangeValue($event, exerciseGoal, RangeTyp.DURATION)"
                [hidden]="isRangeSelectorDisabled"
                [mainValue]="{ value: exerciseGoal?.series, range: 1, type: RangeTyp.SERIES }"
                [operator]="'x'"
                [secondaryValue]="{
                    value: exerciseGoal.duration,
                    range: 1,
                    unit: exerciseGoal.durationUnit,
                    type: RangeTyp.DURATION
                }">
            </lib-range-selector-group>
        </div>
        <div
            *ngIf="exerciseGoal && isOnlyToShow && exerciseGoal?.series"
            class="value-col">
            {{ exerciseGoal?.series?.toString() }}
            x {{ exerciseGoal?.duration?.toString() }} {{ exerciseGoal.durationUnit | translate }}
        </div>
    </div>
    <div
        class="flex-container-row full-width"
        *ngIf="exerciseGoal.stressUnit !== StressUnit.NONE">
        <div
            *ngIf="!isLabelDisabled"
            class="label-col">
            Puls
        </div>
        <div
            *ngIf="exerciseGoal && !isOnlyToShow"
            class="flex-container-row">
            <lib-range-selector-group
                (setMainValue)="changeRangeValue($event, exerciseGoal, RangeTyp.PULSE)"
                (setSecondaryValue)="changeRangeValue($event, exerciseGoal, RangeTyp.PULSE_VARIANCE)"
                [hidden]="isRangeSelectorDisabled"
                [mainValue]="{ value: exerciseGoal.pulse, range: 1, type: RangeTyp.PULSE }"
                [operator]="'+/-'"
                [secondaryValue]="{ value: exerciseGoal.pulseVariance, range: 1, type: RangeTyp.PULSE_VARIANCE }">
            </lib-range-selector-group>
        </div>
        <div
            *ngIf="exerciseGoal?.pulse && isOnlyToShow"
            class="value-col">
            {{ exerciseGoal?.pulse?.toString() }}&nbsp;+/-&nbsp;{{ exerciseGoal?.pulseVariance?.toString() }}
        </div>
    </div>
    <div
        class="flex-container-row full-width"
        *ngIf="exerciseGoal.intensityUnit !== IntensityUnit.NONE && exerciseGoal.intensityUnit">
        <div
            *ngIf="!isLabelDisabled"
            class="label-col">
            <span *ngIf="!exerciseGoal.intensityUnit">Intensität</span>
            <span *ngIf="exerciseGoal.intensityUnit && !isRangeSelectorDisabled">{{
                exerciseGoal.intensityUnit | translate
            }}</span>
        </div>
        <div
            *ngIf="exerciseGoal && exerciseGoal.intensityUnit && !isOnlyToShow"
            class="flex-container-row">
            <lib-range-selector-group
                (setMainValue)="changeRangeValue($event, exerciseGoal, RangeTyp.INTENSITY)"
                [hidden]="isRangeSelectorDisabled"
                [mainValue]="{ value: exerciseGoal.intensity, range: 1, type: RangeTyp.INTENSITY }">
            </lib-range-selector-group>
        </div>
        <div
            *ngIf="exerciseGoal && exerciseGoal.intensityUnit && isOnlyToShow"
            class="value-col">
            {{ exerciseGoal?.intensity?.toString() }}
        </div>
    </div>
</div>
