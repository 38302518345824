import { ItemType } from './item-type';
import { ActionType } from './action-type';
import { SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { EventEmitter, Type } from '@angular/core';

export interface ItemAdapterComponent {
    data: any;
    itemSetting: TableItem;
    updateList?: EventEmitter<boolean>;
    actionSelected?: EventEmitter<{ action: ActionType; mouseEvent: MouseEvent }>;
    name?: string;
    rowIndex?: number;
    isReorderDisable?: boolean;
}

export class ActionSelection {
    value: ActionType;
    label: string;
    disabled?: boolean;
}

export enum DateFormat {
    DATE = 'DATE',
    DATE_AND_HOUR = 'DATE_AND_HOUR',
    DATE_AND_HOUR_2_LINES = 'DATE_AND_HOUR_2_LINES',
}

export class TableItem {
    actionType?: ActionType;
    actions?: { value: ActionType; label: string; disabled?: boolean }[];
    adapter?: Type<ItemAdapterComponent>;
    color?: string;
    disabled?: boolean;
    fill? = 'solid';
    format?: DateFormat;
    header?: string;
    headerIcon?: string;
    headerIconColor? = '';
    hasColor?: boolean;
    icon?: string;
    id?: string;
    isLabelDisabled?: boolean;
    isBold? = false; // only for type appointment, duration, string, date
    isMobileBold? = false; // only for type appointment, duration, string, date
    isNotes? = false;
    isOnlyToShow?: boolean;
    label?: string;
    prop: string;
    showColNameOnMobile? = false;
    sortBy?: SortBy | string;
    sortOrder?: SortOrder;
    sortOrderMobile?: number;
    sortOrderWeb?: number;
    starMaxValue?: number;
    starValue?: number;
    tooltip? = '';
    type?: ItemType;
    unit?: string;
    width?: number | string;
    maxTextLines?: number;
    isMobileButton?: boolean;
    disabledProp?: string;
    isHidden? = false;
}
