export enum RoutingSegment {
    MEASUREMENT = 'measurement',
    MEASUREMENTS = 'measurements',
    MEMBER = 'member',
    DETAIL = 'detail',
    USER = 'user-management',
    LIST = 'list',
    EXERCISE = 'exercises',
    CATEGORY = 'category',
    COURSE_MANAGEMENT = 'courses',
    THERAPY = 'therapies',
    THERAPY_TEMPLATE = 'therapy-template',
    PATIENT_MANAGEMENT = 'patient',
    SUPERVISOR_MANAGEMENT = 'supervisor',
    CAREGIVER_MANAGEMENT = 'caregiver',
    THERAPIST_MANAGEMENT = 'therapist',
    TRAINING_MANAGEMENT = 'training',
    TRAINING_TEMPLATE = 'training-template',
    MEASUREMENT_MANAGEMENT = 'measurement-management',
    MEDICATION_MANAGEMENT = 'medication-management',
    CONSULTATION_MANAGEMENT = 'consultation',
    NOTE_MANAGEMENT = 'note-management',
    COMMON = 'common',
    TASK_MANAGEMENT = 'task-management',
    TASK_TEMPLATES = 'task-templates',
    PROGRAM_TEMPLATES = 'program-templates',
    TASK_PLAN_TEMPLATES = 'task-plan-templates',
    LEARNING_PLAN_TEMPLATES = 'learning-plan-templates',
    TASKS = 'tasks',
    HOME = 'home',
    PROFILE = 'profile',
    DEFAULT = 'default',
    ADMINISTRATION = 'administration',
    MAIN = 'main',
    PROGRESSION = 'progression',
    PRIVACY = 'privacy',
    STANDARD_PRIVACY = 'standard-privacy',
    VERSION = 'version-info',
    PUBLIC = 'public',
    EXPERT_MODE = 'expert-mode-measurement',
    NORM_VALUES = 'norm-values',
    BASIC = 'basic',
    CONTACT = 'contact',
    IMPRINT = 'imprint',
    QUESTIONNAIRE = 'questionnaire',
    CONFORMITY = 'conformity',
    CHATBOT = 'chatbot',
    CHAT = 'chat',
    FIRST_STEPS = 'first-step',
    ONBOARDING = 'ONBOARDING',
    NEED = 'NEED',
    PRODUCT_SELECTION = 'PRODUCT_SELECTION',
    PREPARATION = 'PREPARATION',
    PRODUCT_LAUNCH = 'PRODUCT_LAUNCH',
    EVALUATION = 'EVALUATION',
    PRODUCT_EVALUATION = 'PRODUCT_EVALUATION',
    PRODUCT_EVALUATION_QUERY = 'product-evaluation-query',
    STRATEGY = 'STRATEGY',
    AWARENESS = 'awareness',

    FEEDBACK_SUMMARY = 'feedback-summary',
    FEEDBACK = 'feedback',
    REQUIREMENT = 'requirement',
    LEARNING_MANAGEMENT = 'learning-management',
    KNOWLEDGE = 'knowledge',
    LEARNING_COURSE = 'learning-course',
    TECHNOLOGIES = 'technologies',
    TECHNOLOGIES_SEARCH = 'technologies-search',
    TECHNOLOGIES_FILTER = 'technologies-filter',
    TECHNOLOGIE = 'technologie',
    TECHNOLOGY_DETAILS = 'technology-details',
    MOBILITY = 'mobility',
    STEPS = 'steps',
    MANUAL = 'manual',
    COURSES = 'courses',
    CONCEPT = 'concept',
    USER_AGREEMENT = 'user-agreement',
    HELP = 'help',
    USER_MANAGEMENT = 'user-management/user-management', // This was implemented exclusively for Toolbar header button highlighting
    QUESTIONNAIRE_MANAGEMENT = 'questionnaire-management',
    LOGIN = 'login',
    SETTINGS = 'settings',
    DEVICES = 'devices',
    EXAMINATION = 'examination',
    VIDEO_CALL = 'video-call',
    PATIENT_LIST = 'patient-list',
    DASHBOARD = 'dashboard',
    SCENARIO = 'scenario',
    CONTROL = 'control',
    PARAMETER = 'parameter',
    STRATEGIE = 'strategie',
    ANALYSE = 'analyse',
    ORGANIZATION = 'organization',
    JOURNAL = 'journal',
    GROUP = 'group',
    APPOINTMENT_PLAN = 'appointment_plan',
    APPOINTMENT_MANAGEMENT = 'appointment',
    MANAGEMENT = 'management',
    ECG = 'ecg',
    DICO_HOME = 'dico_home',
    DIALOG = 'dialog',
    DIGITAL_TWIN = 'digital-twin',
    NAVIGATION = 'navigation',
    DOCUMENTS = 'documents',
    CONSTRUCTION = 'construction',
    REGISTER = 'register',
    INFO = 'info',
    TRIAL = 'trial',
    AUTO = 'auto',
    EDIT = 'edit',
    CONCLUSION = 'conclusion',
    ORIENTATION = 'orientation',
    PAGE_ONE = 'page-1',
    PAGE_TWO = 'page-2',
    PAGE_THREE = 'page-3',
    PAGE_FOUR = 'page-4',
    COMPASS = 'compass',
    REGISTRATION_CHECK = 'registration-check',
    SHOP = 'shop',
    ARTICLE = 'article',
    CHECKOUT = 'checkout',
    EMAIL_UPDATE = 'email-update',
    SUBJECT_SELECTION = 'subject-selection',
    SUBJECT_GOAL_SELECTION = 'subject-goal-selection',
    SUBJECT_GOAL_SELECTED = 'subject-goal-selected',
    SHORT = 'short',
    SCREENING_MANAGEMENT = 'screening',
    PROGRAM_MANAGEMENT = 'program-management',
    SUCCESSFUL = 'successful',
    ORDER_MANAGEMENT = 'order',
    KIOSK = 'kiosk',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    VIDEO_CONFERENCE = 'video-conference',
    EXERCISE_SESSIONS = 'exercise-sessions',
}
