import Keycloak from 'keycloak-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { HttpClient } from '@angular/common/http';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionFactoryInterface } from './session-factory-interface';
import { ToastServiceInterface } from './toast-service.interface';
import { BeforeSessionChangeListener } from './before-session-change-listener';
import { ReplaySubject } from 'rxjs';
import { CordovaOptions } from '@ionic-native/core';
import jwt_decode from 'jwt-decode';
import { Session } from '../entities/session';
import { CurafidaKeycloakConfig } from '../entities/curafida-keycloak-config.type';
import { TokenSet } from '../entities/token-set';
import { LoggerFactory } from './logger-factory';
import { Logger } from 'loglevel';

@Injectable()
export abstract class AbstractAuthService<S extends Session> {
    private get currentSession(): S {
        return this._currentSession;
    }

    private set currentSession(value: S) {
        this._currentSession = value;
        this.beforeSessionChangeListener?.sessionChanged(value);
        this.session$.next(value);
    }

    private keycloakJsInstance: Keycloak.KeycloakInstance;
    protected readonly log: Logger;
    private accessTokenMinValiditySeconds = 90;
    private refreshIntervalSeconds = 10;
    private refreshIntervalHandle;
    private _currentSession: S;
    private jwtHelper = new JwtHelperService();

    constructor(
        @Inject('keycloakConfig') private keycloakConfig: CurafidaKeycloakConfig,
        private storage: Storage,
        private http: HttpClient,
        private iab: InAppBrowser,
        private platform: Platform,
        @Inject('LOGGER_FACTORY') private loggerFactory: LoggerFactory,
        private router: Router,
        private zone: NgZone,
        private route: ActivatedRoute,
        @Inject('SESSION_FACTORY_INTERFACE') private sessionFactory: SessionFactoryInterface<S>,
        @Optional() @Inject('TOAST_SERVICE_INTERFACE') private toastService?: ToastServiceInterface,
        @Optional()
        @Inject('BeforeSessionChangeListener')
        private beforeSessionChangeListener?: BeforeSessionChangeListener<S>,
    ) {
        this.log = this.loggerFactory.getLogger(this.constructor.name);
        if (!this.keycloakConfig) {
            throw new Error('KeycloakConfig is not set (not injected in AuthService)');
        }
        this.storage.create();
        this.storage.get('session').then((session: S) => {
            if (session != null) this.currentSession = session;
        });
        this.init();
    }

    public session$ = new ReplaySubject<S>(1);

    /**
     * Returns the current authentication session.
     * This session will be updated on every Login/Logout/Token-refresh.
     */
    public getSession(): S {
        return this._currentSession;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected init(): void {}

    private async initKeycloakJs() {
        this.log.debug(`[initKeycloakJs]`, this.keycloakConfig);
        if (!this.currentSession?.tokenSet?.refresh_token) {
            const session = await this.storage.get('session');
            if (session != null) this.currentSession = session;
        }
        const authServerReachable = await this.isAuthServerReachable();
        if (authServerReachable) {
            await this.revokeTokenIfRequested();
        }
        if (!this.keycloakJsInstance) {
            this.keycloakJsInstance = new Keycloak({
                url: this.keycloakConfig.url,
                realm: this.keycloakConfig.realm,
                clientId: this.keycloakConfig.clientId,
            } as Keycloak.KeycloakConfig);
        }
        this.defineKeycloakjsHandlers(this.keycloakJsInstance);
        const keycloakConfig = {
            adapter: 'default',
            flow: 'standard',
            responseMode: 'query', // Dieser Parameter ist notwendig für die Side-Menu Links auf der KC-Login-Page unter Android & iOS
            useNonce: true,
            checkLoginIframe: true,
            enableLogging: true,
            onLoad: null,
            silentCheckSsoRedirectUri: window.location.origin + '/assets/kc-silent-check-sso.html',
            // initialize with tokens if already available
            token: this.currentSession?.tokenSet?.access_token,
            refreshToken: this.currentSession?.tokenSet?.refresh_token,
            idToken: this.currentSession?.tokenSet?.id_token,
            timeSkew: 0,
        } as Keycloak.KeycloakInitOptions;
        if (this.platform.is('cordova') && this.keycloakConfig.kcAdapterOnMobile === 'cordova') {
            this.log.debug('[initKeycloakJs] Use cordova InAppBrowser');
            keycloakConfig.adapter = 'cordova';
            // keycloakConfig.responseMode = 'fragment'
            // See https://www.keycloak.org/docs/latest/securing_apps/#_modern_browsers
            // keycloakConfig.silentCheckSsoFallback = true
            keycloakConfig.checkLoginIframe = false;
            keycloakConfig.silentCheckSsoRedirectUri = undefined;
        } else if (this.platform.is('cordova')) {
            this.log.debug('[initKeycloakJs] Use cordova native system browser');
            keycloakConfig.adapter = 'cordova-native';
            keycloakConfig.redirectUri = this.keycloakConfig.cordovaRedirectUri;
            // This has to be query for app deeplinks to work on keycloak login page.
            // Contact, Privacy, etc.
            keycloakConfig.responseMode = 'query';
            // checkLoginIframe cannot be used with cordova-native (system browser)
            keycloakConfig.checkLoginIframe = false;
            keycloakConfig.silentCheckSsoRedirectUri = undefined;
        }
        this.log.debug('[initKeycloakJs] Init Keycloak-JS Instance');
        await this.keycloakJsInstance.init(keycloakConfig);
        this.log.debug(keycloakConfig);
        this.log.debug(this.keycloakJsInstance);
        this.log.debug('[initKeycloakJs] after keycloakJsInstance.init(');
        await this.processTokenSet(this.getTokenSetFromKeycloakJs());
    }

    public async authorizationCodeAuth(updatePassword = false): Promise<void> {
        this.log.debug('[authorizationCodeAuth] method triggered');
        clearInterval(this.refreshIntervalHandle);
        if (!this.keycloakJsInstance) {
            this.log.debug('[authorizationCodeAuth] init KeycloakJs');
            await this.initKeycloakJs();
        }
        const authServerReachable = await this.isAuthServerReachable();
        if (
            authServerReachable &&
            this.keycloakJsInstance &&
            (!this.keycloakJsInstance?.authenticated || updatePassword)
        ) {
            this.log.info('[authorizationCodeAuth] Not yet Authenticated -> Start redirect based Auth Code Flow');
            await new Promise((r) => setTimeout(r, 150));
            this.log.info('after Promise');
            const loginOptions = {
                cordovaOptions: { zoom: 'no' } as CordovaOptions,
                scope: 'openid',
            } as Keycloak.KeycloakLoginOptions;
            if (updatePassword) {
                loginOptions.action = 'UPDATE_PASSWORD';
            }
            this.log.info('loginOptions', loginOptions);
            await this.keycloakJsInstance.login(loginOptions);
        }
        await this.processTokenSet(this.getTokenSetFromKeycloakJs());
    }

    /**
     * Performs a HTTP request against the well-known OIDC config URL
     * of the keycloak realm to check whether the auth server is reachable.
     */
    public async isAuthServerReachable(): Promise<boolean> {
        try {
            const url = `${this.keycloakConfig.url}/realms/${this.keycloakConfig.realm}/.well-known/openid-configuration`;
            const response: any = await this.http.get(url).toPromise();
            if (response && response.issuer === `${this.keycloakConfig.url}/realms/${this.keycloakConfig.realm}`) {
                this.log.debug('[isAuthServerReachable] Keycloak is reachable');
                return true;
            }
        } catch (err) {
            this.log.error('[isAuthServerReachable] Error fetching well known oidc endpoint -> reachable=false');
            return false;
        }
        this.log.error('[isAuthServerReachable] Well known oidc endpoint response is invalid -> reachable=false');
        return false;
    }

    /**
     * Performs a logout
     */
    public async logout(redirectUri?: string): Promise<void> {
        this.log.debug('[logout] AuthService.logout() method is triggered');

        clearInterval(this.refreshIntervalHandle);
        this.refreshIntervalHandle = null;

        const authServerReachable = await this.isAuthServerReachable();
        const refreshToken = this.currentSession?.tokenSet?.refresh_token;

        this.currentSession = null;
        // Clear the whole key value store (this is usally IndexedDB)
        await this.storage.clear();
        // Try also to clear the synchronous localstorage. It is used at some places and in libs
        window?.localStorage?.clear();

        if (authServerReachable && refreshToken) {
            // await this.revokeToken(refreshToken)
        } else if (refreshToken) {
            this.log.warn('[logout] auth server not reachable set logoutRequest in local storage');
            await this.storage.set('logoutRequest', refreshToken);
        }
        if (this.keycloakJsInstance && authServerReachable) {
            this.log.info(
                `[AuthService][logout] Perform an OIDC Front Channel Logout via redirect.`,
                this.platform.is('cordova'),
                this.keycloakConfig.kcAdapterOnMobile,
            );
            if (this.platform.is('cordova') && this.keycloakConfig.kcAdapterOnMobile === 'cordova') {
                // Keycloak integration mit InAppBrowser
                await this.iabOidcFrontChannelLogout();
            } else {
                await this.keycloakJsInstance.logout({ redirectUri });
            }
        } else {
            // Cannot clear SSO Cookies while offline and using system browser
            this.log.info(`[AuthService][logout] Just clear the local keycloakjs instance tokens.`);
            this.keycloakJsInstance?.clearToken();
        }
        this.keycloakJsInstance?.clearToken();

        await this.navigateToLoginPage();
    }

    /**
     * Show an error message and redirects the user to the login page after timeout ms.
     * @param errorMessage the user facing toast message.
     * @param timeout timeout in ms before redirect to login page is performed.
     */
    public async logoutUserWithError(errorMessage: string, timeout = 10000): Promise<void> {
        this.log.error(errorMessage);
        await this.toastService?.showToast(errorMessage, 'danger', timeout);
        await new Promise((r) => setTimeout(r, timeout));
        await this.logout();
    }

    /**
     * Checks whether the user is authenticated
     * See also: <https://stackoverflow.com/questions/43788131/jwt-verify-client-side>
     */
    public async isAuthenticated(navigateToLoginPageOnFail = false, initKeycloakJs = false): Promise<boolean> {
        if (!this.keycloakJsInstance && initKeycloakJs) {
            await this.initKeycloakJs();
        }
        if (!this.keycloakJsInstance?.refreshToken || !this.keycloakJsInstance?.authenticated) {
            if (navigateToLoginPageOnFail) {
                await this.navigateToLoginPage();
            }
            this.log.info('[isAuthenticated] KeycloakJs.authenticated says false. Return false');
            return false;
        }
        if (!this.currentSession?.tokenSet?.refresh_token) {
            this.log.info('[isAuthenticated] There is no refresh token in AuthService.session. Return false');
            return false;
        }
        // authentication state is determined by the refresh token, not by the access token
        const refreshTokenDecoded = this.jwtHelper.decodeToken(this.currentSession?.tokenSet.refresh_token);
        if (refreshTokenDecoded && !refreshTokenDecoded.exp && refreshTokenDecoded.typ === 'Offline') {
            // Offline tokens which have not set "Offline Session Max Limited" in keycloak
            // have no expiration time (exp) field
            return true;
        }
        if (!this.jwtHelper.isTokenExpired(this.currentSession?.tokenSet.refresh_token)) {
            return true;
        }
        this.log.error('[isAuthenticated] refresh token is expired => logout');
        await this.logout();
        return false;
    }

    async login(): Promise<void> {
        this.log.debug('[checkLoginState] Check whether a login is required.');
        try {
            await this.authorizationCodeAuth();
        } catch (err) {
            this.log.error('Authorization Code Auth Error.');
            this.log.error(err);
        } finally {
            if (await this.isAuthenticated(false, false)) {
                await this.redirectToAppPage();
            } else {
                this.log.warn('Set login hint to true');
            }
        }
    }

    private async redirectToAppPage() {
        let destinationPage = '/';
        const redirectUrl = this.route.snapshot.queryParamMap.get('redirect');
        if (redirectUrl?.length > 0) {
            // prevent navigation to other websites for security reasons
            if (!this.isUrlAbsolute(redirectUrl)) {
                destinationPage = redirectUrl;
            } else {
                this.log.error(`Redirect to an absolute URL is forbidden.`);
            }
        }
        this.log.debug('LoginPage redirectToAppPage:', destinationPage);
        await this.router.navigateByUrl(destinationPage);
    }

    private async iabOidcFrontChannelLogout() {
        return new Promise((resolve, reject) => {
            if (!this.keycloakJsInstance) {
                this.log.error('[iabOidcFrontChannelLogout] this.keycloakJsInstance is not initialized. Return');
                reject();
            }
            const logoutUrl = this.keycloakJsInstance.createLogoutUrl();
            if (!logoutUrl) {
                this.log.error('[iabOidcFrontChannelLogout] There is no logoutUrl. Return');
                reject();
            }
            this.log.debug(`Visit logout URL in InAppBrowser: ${logoutUrl}`);
            const iabInstance = this.iab.create(logoutUrl, '_blank', {
                hidden: 'yes',
            });
            iabInstance.on('loaderror').subscribe(() => {
                iabInstance.close();
                resolve(null);
            });
            iabInstance.on('loadstop').subscribe(() => {
                iabInstance.close();
                resolve(null);
            });
        });
    }

    private async revokeTokenIfRequested() {
        const refreshToken = await this.storage.get('logoutRequest');
        if (refreshToken) {
            this.log.warn(
                '[revokeTokenIfRequested] there is a pending logout request for refresh token -> logout',
                refreshToken,
            );
            // await this.revokeToken(refreshToken)
            await this.logout();
        }
    }

    private async processTokenSet(tokenSet: TokenSet): Promise<void> {
        this.log.debug('[processTokenSet] method triggered');
        if (!tokenSet || !tokenSet.access_token) {
            this.log.error('[processTokenSet] TokenSet does not contain an access token -> Abort');
            return;
        }
        const errorMessage =
            'Es ist ein Fehler im Zusammenhang mit Ihrem Benutzerkonto aufgetreten. Daher werden Sie in 10 Sekunden automatisch ausgeloggt. Bitte wenden Sie sich an service@ztm.de um dieses Problem zu beheben.';
        let session: S;
        try {
            session = this.sessionFactory.create(tokenSet);
        } catch (e) {
            this.log.error(`Error in SessionFactory: ` + e);
            await this.logoutUserWithError(errorMessage);
            return;
        }
        const issuerFromToken = this.getIssuerFromAccessToken(tokenSet.access_token);
        if (issuerFromToken && issuerFromToken !== `${this.keycloakConfig.url}/realms/${this.keycloakConfig.realm}`) {
            this.log.error('[processTokenSet] Unexpected issuer -> Logout');
            await this.logoutUserWithError(errorMessage);
            return;
        }
        this.currentSession = session;
        // noinspection ES6MissingAwait
        this.storage.set('session', session);
    }

    private defineKeycloakjsHandlers(keycloakjs: Keycloak.KeycloakInstance) {
        keycloakjs.onAuthSuccess = async () => {
            this.log.debug(`[Keycloakjs] onAuthSuccess`);
            await this.processTokenSet(this.getTokenSetFromKeycloakJs());
            await this.startAutoTokenSetRefresh();
        };
        keycloakjs.onAuthError = async (err: Keycloak.KeycloakError) => {
            this.log.error(`[Keycloakjs] onAuthError`);
            const decodedUrl = decodeURIComponent(err.error_description);
            if (err?.error_description && (decodedUrl.startsWith('common') || decodedUrl.startsWith('/common'))) {
                /*
                 * Wenn ein Benutzer der Mobilen Version auf der Keycloak Seite auf
                 * App Links drücken. Z.b. App Datenschutz Seite.
                 * Dann w wird mit den Query Params, die keycloakjs unterstützt,
                 * der App mitgeteilt, auf welche Seite navigiert werden soll.
                 */
                this.log.error(err);
                const url = decodeURIComponent(err.error_description);
                this.log.debug(`Redirect to page given by App external browsers: ${url}`);
                this.router.navigate([url]);
                return;
            }
            this.log.error(err);
        };
        // Try to avoid the onAuthLogout Callback. It does not work as expected.
        keycloakjs.onAuthLogout = async () => {
            this.log.debug(`[Keycloakjs] onAuthLogout`);
            // await this.navigateToLoginPage()
        };
        keycloakjs.onReady = async (authenticated: boolean) => {
            this.log.debug(`[Keycloakjs] onReady: keycloakjs adapter is initialized (authenticated=${authenticated})`);
        };
        keycloakjs.onTokenExpired = async () => {
            this.log.warn('[Keycloakjs] onTokenExpired: access token is expired');
            await this.startAutoTokenSetRefresh(true, true);
        };
        keycloakjs.onAuthRefreshSuccess = async () => {
            // this.log.debug('[AuthService][Keycloakjs] onAuthRefreshSuccess')
        };
        keycloakjs.onAuthRefreshError = () => {
            this.log.error('[Keycloakjs] onAuthRefreshError: Error refreshing auth tokens');
        };
    }

    private async navigateToLoginPage() {
        const currUrl = this.router.url;
        const rand = Math.random();
        this.log.info(`[AuthService][navigateToLoginPage][${rand}]`);
        this.router.onSameUrlNavigation = 'reload';
        if (currUrl.includes('/login')) {
            this.log.info(`[AuthService][navigateToLoginPage][${rand}] Already on ${currUrl}. Skip navigating.`);
            return;
        }
        const nextUrl = `login`;
        this.log.debug(`[AuthService][navigateToLoginPage][${rand}] Navigate to ${nextUrl}`);
        await this.zone.run(async () => {
            try {
                const res = await this.router.navigate([nextUrl]);
                if (!res) {
                    this.log.warn(
                        `[AuthService][navigateToLoginPage][${rand}] Navigating from ${currUrl} to ${nextUrl} page failed with false`,
                    );
                }
            } catch (error) {
                this.log.error(
                    `[AuthService][navigateToLoginPage][${rand}] Navigating from ${currUrl} to ${nextUrl} page raised error:`,
                    error,
                );
            }
        });
        this.log.debug(`[AuthService][navigateToLoginPage][${rand}] After navigate`);
    }

    /**
     * Constructs a TokenSet object from KeycloakJs Instance.
     * Returns null in case it can not be constructed.
     */
    private getTokenSetFromKeycloakJs(): TokenSet {
        if (!this.keycloakJsInstance || !this.keycloakJsInstance.refreshToken) {
            return null;
        }
        return {
            access_token: this.keycloakJsInstance.token,
            refresh_token: this.keycloakJsInstance.refreshToken,
            id_token: this.keycloakJsInstance.idToken,
        } as TokenSet;
    }

    /**
     * Performs a token refresh if a refresh token is available
     * and valid less than minValiditySeconds.
     */
    private async refreshTokenSetKeycloak(minValiditySeconds = 90): Promise<void> {
        if (!this.keycloakJsInstance?.refreshToken) {
            this.log.warn('[refreshTokenSetKeycloak] No refresh token in keycloakjs available. Skip refresh.');
            await this.initKeycloakJs();
            return;
        }
        try {
            const tokenSetUpdated = await this.keycloakJsInstance.updateToken(minValiditySeconds);
            if (tokenSetUpdated) {
                await this.processTokenSet(this.getTokenSetFromKeycloakJs());
            }
        } catch (error) {
            this.log.warn('[refreshTokenSetKeycloak] Refresh error: ', error);
        }
    }

    private async startAutoTokenSetRefresh(now = false, forceRestart = false): Promise<void> {
        if (!this.accessTokenMinValiditySeconds) {
            throw Error('this.accessTokenMinValiditySeconds is not set in AuthService');
        }
        if (!this.refreshIntervalSeconds) {
            throw Error('this.refreshIntervalSeconds is not set in AuthService (set to -1 to turn refresh off)');
        }
        if (this.refreshIntervalSeconds <= 0) {
            this.log.warn('refreshIntervalSeconds <= 0 => Auto refreshing TokenSet is turned off');
            return;
        }
        if (now) {
            await this.refreshTokenSetKeycloak(-1);
        }
        if (forceRestart || !this.refreshIntervalHandle) {
            // Ensure the auto refresh interval only runs once
            clearInterval(this.refreshIntervalHandle);
            this.refreshIntervalHandle = setInterval(async () => {
                await this.refreshTokenSetKeycloak(this.accessTokenMinValiditySeconds);
            }, this.refreshIntervalSeconds * 1000);
        }
    }

    private getIssuerFromAccessToken(accessToken: string): string {
        try {
            const decoded: any = jwt_decode(accessToken);
            return decoded.iss;
        } catch (error) {
            this.log.error('Reading issuer from access token payload failed!');
            return null;
        }
    }

    private isUrlAbsolute = (url) => url.indexOf('://') > 0 || url.indexOf('//') === 0;
}
