import { TherapyDto } from './therapy-dto';
import { Exercise } from '../exercise';
import { FrontendTherapySession, TherapySession } from '../therapy-session/therapy-session';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { TherapyTemplate } from './therapy-template';
import { User } from '../../../auth/entities/user';
import { TherapyState } from './therapy-state';

export class Therapy extends TherapyDto {
    id: number;
    templateId: number;
    created_at: number;
    updated_at: number;
    isCollapsed = false;
    tags: string[];
    therapyGoal: string;
    finishedExercises: number;
    notes: string;
    therapyTemplate: TherapyTemplate;
    participants: User[];
    parentTherapy: Therapy[];

    private _exercises: Exercise[] = [];
    childTherapies?: Therapy[];

    get exercises(): Exercise[] {
        return this._exercises;
    }

    set exercises(value: Exercise[]) {
        this._exercises = value;
    }
    therapyState: TherapyState;
}

export class FrontendTraining extends Therapy {
    therapySessions: PaginatedResponse<FrontendTherapySession[]>;
    finishedTherapySessions: number;
    runningTherapySessions: number;
    runningExercises: number;
    isLoadingFinished = false;
    isTherapyStillFinished: boolean;
}

export class TherapyWithTherapySessions extends Therapy {
    completeTherapySession: PaginatedResponse<TherapySession[]>;
    runningTherapySession: PaginatedResponse<TherapySession[]>;
}
