<div
    class="flex-container-row justify-content-space-between ion-padding"
    style="height: 100%">
    <ion-button (click)="backToForm()">
        <ion-icon name="contract-outline"></ion-icon>
    </ion-button>
    <ion-textarea
        rows="30"
        auto-grow=""
        style="border: 1px solid var(--ion-color-medium); color: black"
        class="ion-margin-start full-height"
        [(ngModel)]="text"
        type="text"></ion-textarea>
</div>
