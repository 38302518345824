import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CurafidaTableComponent } from './curafida-table/curafida-table.component';
import { CurafidaColumnDefinitionComponent } from './column-definition/curafida-column-definition.component';
import { ItemAdapterHostDirective } from './column-definition/item-adapter-host.directive';
import { ButtonItemAdapterComponent } from './table-adapter/button-item-adapter.component';
import { StringItemAdapterComponent } from './table-adapter/string-item-adapter.component';
import { StarProcessingComponent } from './star-processing/star-processing.component';
import { FileUploadModule } from 'ng2-file-upload';
import { PipeTableLib } from '../pipe/pipes-table-lib.module';
import { CurafidaPopoverSelectionComponent } from './curafida-popover-selection/curafida-popover-selection.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { ToggleItemAdapterComponent } from './table-adapter/toggle-item-adapter.component';
import { IconAdapterComponent } from './table-adapter/icon-adapter.component';
import { ReorderAdapterComponent } from './table-adapter/reorder-adapter.component';
import { DurationItemAdapterComponent } from './table-adapter/duration-item-adapter.component';
import { CheckBoxItemAdapterComponent } from './table-adapter/checkbox-item-adapter.component';
import { StarProcessingAdapterComponent } from './table-adapter/star-processing-adapter.component';
import { MediaContentAdapterComponent } from './table-adapter/media-content-adapter.component';
import { NumberAndReorderAdapterComponent } from './table-adapter/number-and-reorder-adapter.component';

@NgModule({
    declarations: [
        CurafidaTableComponent,
        CurafidaColumnDefinitionComponent,
        StarProcessingComponent,
        ButtonItemAdapterComponent,
        StringItemAdapterComponent,
        ItemAdapterHostDirective,
        CurafidaPopoverSelectionComponent,
        ToggleItemAdapterComponent,
        IconAdapterComponent,
        ReorderAdapterComponent,
        DurationItemAdapterComponent,
        CheckBoxItemAdapterComponent,
        StarProcessingAdapterComponent,
        MediaContentAdapterComponent,
        NumberAndReorderAdapterComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        FileUploadModule,
        PipeTableLib,
        NgxIonicImageViewerModule,
    ],
    exports: [
        CurafidaTableComponent,
        CurafidaColumnDefinitionComponent,
        StarProcessingComponent,
        ButtonItemAdapterComponent,
        StringItemAdapterComponent,
        ItemAdapterHostDirective,
        CurafidaPopoverSelectionComponent,
        ToggleItemAdapterComponent,
        IconAdapterComponent,
        ReorderAdapterComponent,
        DurationItemAdapterComponent,
        CheckBoxItemAdapterComponent,
        StarProcessingAdapterComponent,
        MediaContentAdapterComponent,
        NumberAndReorderAdapterComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableComponentsModule {}
