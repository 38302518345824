<div
    *ngIf="!isMobile"
    class="flex-container-row justify-content-space-between full-width ion-padding-top"
    style="padding-bottom: 4px">
    <div class="flex-container-row justify-content-flex-start full-width align-items-center">
        <curafida-segment
            (sendSegmentType)="emitSegmentChange($event)"
            *ngIf="segmentList.length > 0"
            [mode]="'ios'"
            [segmentList]="segmentList"
            [segmentValue]="segmentValue"></curafida-segment>
        <div *ngIf="!hideFilter">
            <ion-button
                (click)="setFilter()"
                class="ion-no-margin"
                fill="clear"
                style="width: 150px">
                <ion-icon name="funnel-outline"></ion-icon>
                {{ filterLabel | translate }} ({{ filterLength }})
            </ion-button>
        </div>
        <div
            *ngIf="!hideSearch"
            style="min-width: 30%">
            <ion-item
                class="curafida-search-input web"
                lines="none">
                <ion-label>
                    <ion-icon name="search-outline"></ion-icon>
                </ion-label>
                <ion-input
                    [placeholder]="searchBarLabel | translate"
                    (ionChange)="search()"
                    [(ngModel)]="value"></ion-input>
            </ion-item>
        </div>
        <div
            *ngIf="buttonList"
            class="flex-container-row justify-content-flex-end"
            style="flex-grow: 1">
            <ion-button
                (click)="openActionButton(button.actionName)"
                *ngFor="let button of buttonList"
                class="ion-no-margin"
                style="margin-left: 8px">
                <ion-icon
                    *ngIf="button?.iconName"
                    name="{{ button.iconName }}"></ion-icon>
                <ion-icon
                    *ngIf="button?.iconSrc"
                    src="{{ button.iconSrc }}"
                    style="fill: white !important"></ion-icon>
            </ion-button>
        </div>
    </div>
    <div class="flex-container-row justify-content-flex-end ion-margin-start self-align-center">
        <div
            *ngIf="!hideButton"
            class="ion-no-margin"
            style="flex-grow: 1">
            <ion-button
                (click)="openActionButton('add')"
                *ngIf="buttonLabel"
                class="btn-font-medium ion-no-margin"
                >{{ buttonLabel | translate }}</ion-button
            >
            <ion-button
                (click)="openActionButton('add')"
                *ngIf="buttonIcon"
                class="ion-no-margin">
                <ion-icon name="{{ buttonIcon }}"></ion-icon>
            </ion-button>
        </div>
    </div>
</div>

<div
    *ngIf="isMobile"
    class="flex-container-column">
    <ion-toolbar
        style="--background: none"
        class="flex-container-row align-items-center justify-content-flex-start full-width">
        <curafida-segment
            slot="start"
            (sendSegmentType)="emitSegmentChange($event)"
            [mode]="'ios'"
            [segmentList]="segmentList"
            [segmentValue]="segmentValue"
            [ngClass]="{
                'segment-xtra-wide': hideFilter && hideSearch && !hideButton,
                'full-width': hideFilter && hideSearch && hideButton
            }"></curafida-segment>
        <ion-button
            (click)="setFilter()"
            *ngIf="!hideFilter"
            class="ion-no-margin"
            fill="clear"
            style="width: 150px">
            <ion-icon name="funnel-outline"></ion-icon>
            {{ filterLabel | translate }} ({{ filterLength }})
        </ion-button>
        <ion-button
            *ngIf="!hideButton && buttonLabel"
            slot="end"
            (click)="openActionButton('add')"
            class="btn-font-medium ion-no-margin"
            >{{ buttonLabel | translate }}</ion-button
        >
        <!-- add patient -->
        <ion-button
            *ngIf="!hideButton && buttonIcon"
            slot="end"
            (click)="openActionButton('add')"
            class="ion-no-margin"
            style="margin-left: 8px">
            <ion-icon name="{{ buttonIcon }}"></ion-icon>
        </ion-button>
    </ion-toolbar>
    <ion-toolbar
        style="--background: none"
        *ngIf="!hideSearch && buttonList?.length > 0"
        class="flex-container-row align-items-center justify-content-flex-start full-width">
        <ion-item
            *ngIf="!hideSearch"
            slot="start"
            class="curafida-search-input mobile"
            lines="none">
            <ion-label>
                <ion-icon name="search-outline"></ion-icon>
            </ion-label>
            <ion-input
                [placeholder]="searchBarLabel | translate"
                (ionChange)="search()"
                [(ngModel)]="value"></ion-input>
        </ion-item>
        <ion-button
            slot="end"
            (click)="openActionButton(button.actionName)"
            *ngFor="let button of buttonList"
            class="ion-no-margin"
            style="margin-left: 8px">
            <ion-icon name="{{ button.iconName }}"></ion-icon>
        </ion-button>
    </ion-toolbar>
</div>
