import { ElementRef, Injectable, ViewChild, ViewChildren } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastPosition } from '../../entities/toast/toast-message';
import { IonicColor } from '../../entities/toast/ionic-color';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';
import { ToastServiceInterface } from '../../../kc-auth/services/toast-service.interface';

@Injectable({
    providedIn: 'root',
})
export class ToastService implements ToastServiceInterface {
    static changeSavedMessage = 'Ihre Änderungen wurden gespeichert.';
    static sendMeasurement = 'Ihre Messungen wurden nicht versendet.';
    static sendMessage = 'Ihre Nachricht wird versendet.';
    static measurementNotSendButLater = 'Ihre Messungen wurden nicht versendet. Wir versuchen später wieder.';
    static errorMessage = 'Beim Laden dieser Seite ist ein Fehler aufgetreten.';
    static errorMessageSave = 'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
    static errorMessageDelete = 'Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
    static loginMessage = `Bei der Anmeldung ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort.`;
    static formMessage = `Bitte überprüfen Sie Ihr Formular.`;
    static formMessageEndDate = `Bitte überprüfen Sie Ihr Enddatum.`;
    static titleOrDescriptionMessage = `Beim Speichern ist ein Fehler aufgetreten. Bitte überprüfen Sie die Überschrift und die Beschreibung.`;
    // Bluetooth errors
    static notConnected = `Die Verbindung zum Gerät ist nicht möglich. Bitte prüfen Sie, ob Bluetooth bei Ihrem Gerät eingeschaltet ist.`;
    static success_connection = `Gerät erfolgreich verbunden, Sie können mit der Messung starten.`;

    changeSavedMessage = 'Ihre Änderungen wurden gespeichert.';
    errorMessage = 'Beim Laden ist ein Fehler aufgetreten.';
    loginMessage = `Bei der Anmeldung ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort.`;
    formMessage = `Bitte überprüfen Sie Ihr Formular.`;

    @ViewChildren(ToastContainerDirective) inlineContainers!: ToastContainerDirective;
    @ViewChild('toast') toast: ElementRef;
    options: GlobalConfig;
    toastType = iconClasses.success;

    constructor(
        protected toastCtrl: ToastController,
        private translate: TranslateService,
        private toastrService: ToastrService,
    ) {
        this.options = this.toastrService.toastrConfig;
    }

    async showToast(text: string, type: IonicColor | string, duration?: number, position?: ToastPosition) {
        const positionInput = position ? position : this.toastrService.toastrConfig.positionClass;
        const durationInput = duration ? duration : 6000;
        if (text) {
            this.translate.get(text).subscribe((value) => (text = value));
        }
        if (!text) {
            throw Error('Text must be valid. Impossible to show a ToastMessage without a text');
        }
        if (type === IonicColor.danger) {
            this.toastType = iconClasses.error;
        } else if (type === IonicColor.success) {
            this.toastType = iconClasses.success;
        } else if (type === IonicColor.warning) {
            this.toastType = iconClasses.warning;
        } else if (type === IonicColor.primary) {
            this.toastType = iconClasses.info;
        } else if (type === IonicColor.medium) {
            this.toastType = iconClasses.medium;
        }
        const opt = cloneDeep(this.options);
        opt.closeButton = true;
        opt.timeOut = durationInput;
        opt.positionClass = positionInput;
        this.toastrService.show(
            text,
            '',
            opt,
            this.toastType,
            // this.options.iconClasses[this.toastType],
        );
    }
}

const iconClasses = {
    error: 'toast-error',
    info: 'toast-info',
    success: 'toast-success',
    warning: 'toast-warning',
    medium: 'toast-medium',
};
