import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';

@Component({
    template: `
        <lib-exercise-goal-col
            [exerciseGoal]="data[itemSetting.prop]"
            [isOnlyToShow]="itemSetting.isOnlyToShow"
            [isLabelDisabled]="itemSetting.isLabelDisabled"></lib-exercise-goal-col>
    `,
})
export class RangeSelectAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean };
    @Input() itemSetting: TableItem;
}
