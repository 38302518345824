import { NgModule } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { Media } from '@ionic-native/media/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';
import { Brightness } from '@ionic-native/brightness/ngx';
import { Hotspot } from '@ionic-native/hotspot/ngx';
import { PipesMeasurementModule } from './pipes/pipes-measurement.module';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { BluetoothClassicSerial } from '../custom-plugin/native-wrapper-plugin/bluetooth-classic-serial/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Stethoscope } from '../custom-plugin/native-wrapper-plugin/ekuore-pro-stethoscope/ngx';
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/ngx';

@NgModule({
    declarations: [],
    providers: [
        Camera,
        File,
        WebView,
        Network,
        InAppBrowser,
        BluetoothLE,
        BluetoothSerial,
        BackgroundMode, // TODO: it might not be necessary, to prove when there is a stable version
        AppLauncher,
        Brightness,
        Hotspot, // TODO: it might not be necessary, to prove when there is a stable version
        Media,
        InAppBrowser,
        BluetoothClassicSerial,
        OpenNativeSettings,
        Stethoscope,
        WifiWizard2,
    ],
    imports: [PipesMeasurementModule],
    exports: [],
})
export class MeasurementModule {}
