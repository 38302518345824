<div
    class="flex-container-row full-width text-standard-input-size"
    *ngIf="formGroup && formControlName && datePickerFormGroup"
    [formGroup]="datePickerFormGroup"
    [style.pointer-events]="isEditEnabled ? 'all' : 'none'">
    <ion-label
        *ngIf="!isMobile"
        [ngClass]="{
            'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
            'ion-label-color-enabled': formGroup.controls[formControlName].status !== 'DISABLED'
        }"
        class="item-label-user-info self-align-center ion-padding-top word-breaking">
        {{ label }}
    </ion-label>
    <ion-item
        *ngIf="!isMobile"
        [ngClass]="{
            'ion-item-box-enable pointer': formGroup.controls[formControlName].status !== 'DISABLED',
            'ion-item-box-disable': formGroup.controls[formControlName].status === 'DISABLED',
            'border-color-primary': borderColorPrimary
        }"
        class="item-input-width ion-item-box text-standard-input-size">
        <ion-input
            (ionChange)="setValueOnParentComponent($event)"
            [dpDayPicker]="config"
            [formGroup]="datePickerFormGroup"
            [formControlName]="'picker'"
            [mode]="datePickerMode"
            [displayDate]="displayDate"
            [theme]="'dp-material dp-main'">
        </ion-input>
    </ion-item>
    <ion-item
        *ngIf="isMobile"
        [ngClass]="{
            'ion-item-box-enable': formGroup.controls[formControlName].status !== 'DISABLED',
            'ion-item-box-disable': formGroup.controls[formControlName].status === 'DISABLED',
            'border-color-primary': borderColorPrimary
        }"
        [style.pointer-events]="isEditEnabled ? 'all' : 'none'"
        class="item-input-full-width">
        <ion-label
            [ngClass]="{
                'ion-label-color-disabled': formGroup.controls[formControlName].status === 'DISABLED',
                'ion-label-color-enabled': formGroup.controls[formControlName].status === 'VALID'
            }"
            position="stacked">
            {{ label }}
        </ion-label>
    </ion-item>
</div>
<div
    *ngIf="datePickerFormGroup"
    [formGroup]="datePickerFormGroup">
    <error-message
        *ngIf="datePickerFormGroup && formErrors"
        [errorMessageInput]="formErrors"
        [formControlName]="'picker'"
        [formGroup]="datePickerFormGroup">
    </error-message>
</div>
