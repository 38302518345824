<div class="button-container">
    <ion-button
        (click)="cancelEditEmit.emit()"
        *ngIf="element != null && isEditEnabled && !isNewElement"
        [ngClass]="{ 'btn-font-medium': !isMobile, 'half-width': isMobile }"
        color="danger">
        {{ 'CANCEL' | translate }}
    </ion-button>
    <ion-button
        (click)="toggleEditEmit.emit()"
        *ngIf="element != null && !isEditEnabled && !isNewElement"
        [ngClass]="{ 'btn-font-medium': !isMobile, 'full-width': isMobile }"
        >{{ 'EDIT' | translate }}</ion-button
    >
    <ion-button
        (click)="saveInformationEmit.emit()"
        *ngIf="element != null && isEditEnabled && !isNewElement"
        [disabled]="!formGroup.dirty || !formGroup.valid"
        [ngClass]="{ 'btn-font-medium': !isMobile, 'half-width': isMobile }"
        >{{ 'SAVE_CHANGES' | translate }}</ion-button
    >
    <ion-button
        (click)="saveInformationEmit.emit()"
        *ngIf="element != null && isEditEnabled && isNewElement"
        [disabled]="!formGroup.valid"
        [ngClass]="{ 'btn-font-medium': !isMobile, 'half-width': isMobile }"
        >{{ saveLabel | translate }}</ion-button
    >
</div>
