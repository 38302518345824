<ion-toolbar
    *ngIf="appConfig?.pageConfigs && toolbarType === ToolbarType.NORMAL && !hideToolbar"
    [ngClass]="{
                    'wrapper-without-height-main' : !router.url.includes('login'),
                    'wrapper-without-height-login' : router.url.includes('login')
                    }"
    color="primary"
    style="--padding-start: 0; --padding-end: 0">
    <ion-buttons
        *ngIf="showBackButtonAndBurgerMenuIcon"
        slot="start"
        style="--margin-inline-start: 0; margin-left: 0">
        <ion-menu-toggle>
            <ion-button [ngClass]="{'no-padding': styleService.isPortal()}">
                <ion-icon
                    name="menu"
                    slot="start"
                    style="color: white !important; font-size: 24px"></ion-icon>
            </ion-button>
        </ion-menu-toggle>
        <ion-back-button
            (click)="goToPreviousPage()"
            *ngIf="!isRootPage() && appConfig.appName !== 'dico'"
            [defaultHref]="'/'"
            [text]="backButtonStringName"
            style="color: white !important">
        </ion-back-button>
        <ion-button
            (click)="goToPreviousPage()"
            *ngIf="!isRootPage() && appConfig.appName === 'dico'"
            color="primary-inverse"
            fill="clear">
            <ion-icon name="arrow-back-outline"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-buttons
        *ngIf="!isLoginPage() && roles"
        slot="end"
        style="margin-right: 15px">
        <ion-button
            (click)="login()"
            [hidden]="roles?.length > 0"
            [id]="'login'"
            class="web main-title">
            <span style="margin-left: 10px; margin-right: 10px">{{'LOGIN' | translate}}</span>
        </ion-button>
        <div *ngFor="let pageConfig of appConfig.pageConfigs | MenuItemsFilter: roles: configService.config.features">
            <ion-button
                (click)="openPage(pageConfig.url)"
                [hidden]="!pageConfig.url"
                [id]="pageConfig.pageId"
                [ngClass]="{
                    'main-title-active' : mainTitleActive(pageConfig.categories) && pageConfig.configuration.headerVisibility,
                    'main-title' : !mainTitleActive(pageConfig.categories) && pageConfig.configuration.headerVisibility,
                    'main-title-display-none' : !pageConfig.configuration.headerVisibility
                     }"
                class="web">
                <ion-icon
                    *ngIf="pageConfig.logo"
                    [ngClass]="{'extra-right-margin' : pageConfig.configuration.onlyIcon}"
                    class="medium-icon"
                    name="{{pageConfig.logo}}"></ion-icon>
                <span
                    *ngIf="!pageConfig.configuration.onlyIcon"
                    style="margin-left: 10px; margin-right: 10px"
                    >{{pageConfig.title | translate}}</span
                >
            </ion-button>

            <ion-button
                (click)="presentPopover($event, pageConfig.childPageConfigs)"
                [hidden]="pageConfig.url"
                [id]="pageConfig.pageId"
                [ngClass]="{
                    'main-title-active' : mainTitleActive(pageConfig.categories) && pageConfig.configuration.headerVisibility,
                    'main-title' : !mainTitleActive(pageConfig.categories) && pageConfig.configuration.headerVisibility,
                    'main-title-display-none' : !pageConfig.configuration.headerVisibility
                     }"
                class="web"
                >{{pageConfig.title | translate}}
            </ion-button>
        </div>

        <ion-button
            *ngFor="let generalPageConfig of appConfig.generalPageConfigs"
            [ngClass]="{
                    'main-title-active' : mainTitleActive(generalPageConfig.categories) && generalPageConfig?.configuration?.headerVisibility,
                    'main-title' : !mainTitleActive(generalPageConfig.categories) && generalPageConfig?.configuration?.headerVisibility,
                    'main-title-display-none' : !generalPageConfig?.configuration?.headerVisibility
                     }"
            [routerLink]="generalPageConfig.url"
            class="web">
            <span style="margin-left: 10px; margin-right: 10px">{{generalPageConfig.title | translate}}</span>
        </ion-button>
        <ion-button
            (click)="openHelp()"
            *ngIf="helpURL|| modalDefinition"
            title="Unsere Online-Hilfe steht Ihnen jederzeit zur Verfügung.">
            <ion-icon
                class="medium-icon-without-horizontal-margin"
                name="help-circle-outline"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title
        *ngIf="styleService.isMobile()"
        slot=""
        >{{title | translate}}</ion-title
    >
    <ion-buttons
        [ngClass]="{'logo-button' : !isLoginPage}"
        slot="end">
        <ion-icon
            *ngIf="showOfflineIcon"
            class="medium-icon"
            name="cloud-offline-outline"></ion-icon>
        <img
            *ngIf="!isLoginPage() && !appConfig?.toolbarLogoWhiteBackground"
            [routerLink]="''"
            alt="logo"
            class="logo-header web pointer"
            src="{{logoUrl}}" />
        <ion-button
            *ngIf="appConfig?.toolbarLogoWhiteBackground"
            [hidden]="!logoName"
            class="button-logo button-logo-background web"
            fill="'clear'">
            <img
                *ngIf="!isLoginPage()"
                [routerLink]="''"
                alt="logo"
                class="logo-header web pointer"
                src="{{logoUrl}}" />
        </ion-button>
        <ion-button
            (click)="presentLangPopover($event)"
            *ngIf="langList.length > 1">
            <img
                alt="language_flag"
                class="flag-img-scale"
                src="assets/imgs/{{currentLang}}.ico" />
        </ion-button>

        <ion-button
            (click)="openProfile()"
            *ngIf="isPatient">
            <ion-icon
                class="medium-icon-without-horizontal-margin"
                name="person-circle"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>
