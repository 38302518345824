import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalConfig } from '../../entities/modal/modal-config';
import { ActionSelection } from '../../../table/entities/table';

@Component({
    selector: 'lib-main-modal',
    templateUrl: './main-modal.component.html',
    styleUrls: ['./main-modal.component.scss'],
})
export class MainModalComponent implements OnInit {
    title: string;

    @Input()
    modalConfig: ModalConfig;
    itemSelected: ActionSelection;
    isButtonDisabled = false;

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        if (this.modalConfig.descriptionIsHTML) {
            document.getElementById('modalDescription').innerHTML = this.modalConfig.description;
        }
    }

    dismissModal() {
        this.modalController.dismiss({
            action: 'none',
            itemSelected: this.itemSelected,
        });
    }

    emitLeftButton() {
        this.modalController.dismiss({
            action: 'left',
            itemSelected: this.itemSelected,
        });
    }

    emitRightButton() {
        this.modalController.dismiss({
            action: 'right',
            itemSelected: this.itemSelected,
        });
    }

    setButtonState() {
        this.isButtonDisabled = !!!this.itemSelected;
    }
}
