import packageJson from '../../../package.json';
import { UserRoles } from '../../auth/entities/user';
import { PageConfig } from './page-config';
import { BuildType } from './build-type';
import { CurafidaEnvironmentConfig } from '../../common/entities/curafida-environment-config.type';
import { FeaturesConfiguration } from './base-page-config';
import { CurafidaKeycloakConfig } from '../../kc-auth/entities/curafida-keycloak-config.type';

export class AppConfigBaseClass extends CurafidaEnvironmentConfig {
    appVersion = packageJson.version;
    appId: string;
    iosAppStoreId?: string;
    androidPlayStoreId?: string;
    defaultUrl?: string;
    isUsingMyMedax?: boolean;

    welcomeUrl?: {
        url: string;
        roles: UserRoles[];
    }[];

    generalPageConfigs?: PageConfig[];

    pageConfigs?: PageConfig[];
    rootUrls?: {
        url: string;
        role: UserRoles | string;
    }[];
    userRoleOfBetreuer?: UserRoles;
    rolesOfBetreuer?: UserRoles[];
    toolbarLogoWhiteBackground?: boolean;

    isUsingCurafidaQuestionnaireTasks?: boolean;
    features: FeaturesConfiguration;
    keycloak: CurafidaKeycloakConfig;
    production: boolean;
    theme: {
        colors?: {
            primary?: string;
            primaryContrast?: string;
            secondary?: string;
            secondaryContrast?: string;
            tertiary?: string;
            tertiaryContrast?: string;
            card?: string;
            cardContrast?: string;
        };
        logoUrl?: 'assets/extra/logo.png'; // [](../../../apps/dico/src/assets/imgs/logo.png)
        faviconUrl?: string;
    };
    developmentStage: BuildType;
}
