import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { ActionItemType, ActionMenuItem } from '../../entities/action-menu.item';

@Component({
    selector: 'lib-curafida-popover-selection',
    templateUrl: './curafida-popover-selection.component.html',
    styleUrls: ['./curafida-popover-selection.component.scss'],
})
export class CurafidaPopoverSelectionComponent implements OnInit {
    ActionMenuItemType = ActionItemType;
    actionItems: ActionMenuItem[] = [];

    constructor(private navParams: NavParams, private popoverController: PopoverController) {}

    ngOnInit() {
        this.actionItems = this.navParams.get('actionItems');
    }

    async sendAction(value: any) {
        await this.popoverController.dismiss(value);
    }

    isType(item: ActionMenuItem, type: ActionItemType) {
        return type === item.type;
    }
}
