import { NgModule } from '@angular/core';
import { JoinVideoConferenceComponent } from './join-conference/join-video-conference.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HateoasModule } from '../hateoas/hateoas.module';

@NgModule({
    declarations: [JoinVideoConferenceComponent],
    imports: [CommonModule, IonicModule, RouterModule, TranslateModule, HateoasModule],
    exports: [JoinVideoConferenceComponent],
})
export class VideoConferenceModule {}
