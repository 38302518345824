import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ExerciseSession,
    ExerciseSessionDto,
    ExerciseSessionState,
    ExerciseToolSetting,
    ExerciseType,
} from '../../entities/exerciseSession';
import { ExerciseSessionAppointment, ExerciseSessionAppointmentDto } from '../../entities/appointement';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { AddChildExerciseSessionDto } from '../../entities/exerciseSession/add-children-exercise-session-dto';
import { ReorderChildExerciseSessionsDto } from '../../entities/exerciseSession/reorder-child-exercise-sessions-dto';
import { map } from 'rxjs/operators';
import { JitsiService } from '../../../video-conference/jitsi/jitsi.service';
import { HypermediaResource } from '../../../hateoas/hateoas.model';
import { NoAuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { TranslateService } from '@ngx-translate/core';
import { TaskActionLinkName } from '../../components/task/task.resource';

@Injectable({
    providedIn: 'root',
})
export class ExerciseSessionsService {
    constructor(
        protected http: HttpClient,
        private jitsiService: JitsiService,
        private readonly noAuthorizationPipe: NoAuthorizationPipe,
        private readonly translateService: TranslateService,
    ) {}

    getExerciseSessions(
        offset?: number,
        limit?: number,
        therapyId?: number,
        exerciseSessionState?: ExerciseSessionState,
        sortBy?: SortBy,
        sortOrder?: SortOrder,
        exerciseType?: ExerciseType,
        includeParticipants = false,
        exerciseSessionStates?: ExerciseSessionState[],
        exerciseSessionUserStates?: ExerciseSessionState[],
        responsible?: string,
        includeStateChanges?: boolean,
        startTimeBefore?: string,
        startTimeAfter?: string,
        delayedTimeBefore?: string,
        delayedTimeAfter?: string,
        endTimeBefore?: string,
        endTimeAfter?: string,
        isGroup?: boolean,
    ): Promise<PaginatedResponse<ExerciseSession[]>> {
        let url = `${ApiService.url}exerciseSessions`;

        // build query param string
        let queryParams = '';
        if (offset) queryParams = `${queryParams}offset=${offset}&`;
        if (limit) queryParams = `${queryParams}limit=${limit}&`;
        if (therapyId) queryParams = `${queryParams}therapyId=${therapyId}&`;
        if (exerciseSessionState) queryParams = `${queryParams}exerciseSessionState=${exerciseSessionState}&`;
        if (sortBy) queryParams = `${queryParams}sortBy=${sortBy}&`;
        if (sortOrder) queryParams = `${queryParams}sortOrder=${sortOrder}&`;
        if (exerciseType) queryParams = `${queryParams}exerciseType=${exerciseType}&`;
        if (responsible) queryParams = `${queryParams}responsible=${responsible}&`;
        if (includeParticipants) queryParams = `${queryParams}includeParticipants=true&`;
        if (includeStateChanges) queryParams = `${queryParams}includeStateChanges=true&`;
        if (exerciseSessionStates) {
            for (const state of exerciseSessionStates) {
                {
                    queryParams = `${queryParams}exerciseSessionStates=${state}&`;
                }
            }
        }
        if (exerciseSessionUserStates) {
            for (const state of exerciseSessionUserStates) {
                {
                    queryParams = `${queryParams}exerciseSessionUserStates=${state}&`;
                }
            }
        }
        if (startTimeBefore) {
            queryParams = `${queryParams}appointmentFilterProperties[startTimeBefore]=${startTimeBefore}&`;
        }
        if (startTimeAfter) {
            queryParams = `${queryParams}appointmentFilterProperties[startTimeAfter]=${startTimeAfter}&`;
        }
        if (delayedTimeBefore) {
            queryParams = `${queryParams}appointmentFilterProperties[delayedTimeBefore]=${delayedTimeBefore}&`;
        }
        if (delayedTimeAfter) {
            queryParams = `${queryParams}appointmentFilterProperties[delayedTimeAfter]=${delayedTimeAfter}&`;
        }
        if (endTimeBefore) queryParams = `${queryParams}appointmentFilterProperties[endTimeBefore]=${endTimeBefore}&`;
        if (endTimeAfter) queryParams = `${queryParams}appointmentFilterProperties[endTimeAfter]=${endTimeAfter}&`;
        if (isGroup) queryParams = `${queryParams}isGroup=${isGroup}&`;

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.get<PaginatedResponse<ExerciseSession[]>>(encodeURI(url), ApiService.options).toPromise();
    }

    postExerciseSession(exerciseSessionDto: ExerciseSessionDto): Promise<ExerciseSession> {
        const url = `${ApiService.url}exerciseSessions`;
        return this.http.post<ExerciseSession>(url, exerciseSessionDto, ApiService.options).toPromise();
    }

    getExerciseSession(exerciseSessionId: number): Promise<ExerciseSession> {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}`;
        return this.http.get<ExerciseSession>(url, ApiService.options).toPromise();
    }

    getExerciseSessionsFromTherapy(therapyId: number): Promise<PaginatedResponse<ExerciseSession[]>> {
        const url = `${ApiService.url}exerciseSessions?therapyId=${therapyId}`;
        return this.http.get<PaginatedResponse<ExerciseSession[]>>(url, ApiService.options).toPromise();
    }

    putUserIntoExerciseSession(exerciseSession_id: number, username: string, ignoreGroupSize?: boolean) {
        let url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/users/${username}`;

        // build query param string
        let queryParams = '';
        if (ignoreGroupSize) {
            queryParams = `${queryParams}ignoreGroupSize=${ignoreGroupSize}&`;
        }

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.put(url, null, ApiService.options).toPromise();
    }

    deleteUserFromExerciseSession(exerciseSession_id: number, username: string) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/users/${username}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    putTaskAppointment(
        taskResource: HypermediaResource,
        appointment: ExerciseSessionAppointmentDto,
    ): Promise<ExerciseSessionAppointment> {
        if (this.noAuthorizationPipe.transform(taskResource, 'appointment', 'write')) {
            throw new Error(this.translateService.instant('FORBIDDEN'));
        }
        const url = new URL(taskResource._links.appointment.href, ApiService.url);
        return this.http.put<ExerciseSessionAppointment>(url.toString(), appointment, ApiService.options).toPromise();
    }
    putAppointmentToExerciseSession(
        exerciseSession_id: number,
        appointment: ExerciseSessionAppointmentDto,
    ): Promise<ExerciseSessionAppointment> {
        return this.http
            .put<ExerciseSessionAppointment>(
                `${ApiService.url}exerciseSessions/${exerciseSession_id}/exerciseSessionAppointment`,
                appointment,
                ApiService.options,
            )
            .toPromise();
    }

    updateTherapistToExerciseSession(exerciseSession_id: number, username: string) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/therapists/${username}`;
        return this.http.put(url, '', ApiService.options).toPromise();
    }

    deleteTherapistToExerciseSession(exerciseSession_id: number, username: string) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/therapists/${username}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    postTaskAction(taskResource: HypermediaResource, taskAction: TaskActionLinkName) {
        if (this.noAuthorizationPipe.transform(taskResource, taskAction, 'write')) {
            throw new Error(this.translateService.instant('FORBIDDEN'));
        }
        const url = new URL(taskResource._links[taskAction].href, ApiService.url);
        return this.http.post<ExerciseSessionAppointment>(url.toString(), '', ApiService.options).toPromise();
    }

    postExerciseSessionConfirmPlanning(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/confirmPlanning`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionPostpone(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/postpone`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionCancel(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/cancel`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionActivate(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/activate`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionFinish(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/finish`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    deleteExerciseSession(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    addChildrenExerciseSession(exerciseSessionId: number, addChildExerciseSessionDto: AddChildExerciseSessionDto) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/childExerciseSessions`;
        return this.http.post(url, addChildExerciseSessionDto, ApiService.options).toPromise();
    }

    reorderChildrenExerciseSession(
        exerciseSessionId: number,
        reorderChildExerciseSessionsDto: ReorderChildExerciseSessionsDto,
    ) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/childExerciseSessions`;
        return this.http.put(url, reorderChildExerciseSessionsDto, ApiService.options).toPromise();
    }

    getExerciseSessionTool(exerciseSessionId: string) {
        this.http
            .get<ExerciseToolSetting>(
                `${ApiService.url}exerciseSessions/${exerciseSessionId}/toolSettings`,
                ApiService.options,
            )
            .pipe(
                map((it) => {
                    this.jitsiService.model$.next({ ...this.jitsiService.current, setting: it });
                }),
            );
    }
}
