import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { TherapySession, TherapySessionState } from '../../entities/therapy-session/therapy-session';
import { PaginatedResponse, SortOrder } from '../../../common/entities/paginated-response';
import { LoadingService } from '../../../common/services/loading/loading.service';

@Injectable({
    providedIn: 'root',
})
export class UsersTherapySessionsService {
    constructor(protected http: HttpClient, private loadingService: LoadingService) {}

    async getUserTherapySessions(
        username: string,
        therapyId: number,
        offset?: number,
        limit?: number,
        sortOrder: SortOrder = null,
        state?: TherapySessionState,
        earliestStart?: string,
        latestEnd?: string,
        therapyIsLocked?: boolean,
    ): Promise<PaginatedResponse<TherapySession[]>> {
        const url = new URL(`${ApiService.url}users/${username}/therapies/${therapyId}/therapySessions`);

        if (offset) url.searchParams.set('offset', offset.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (sortOrder) url.searchParams.set('sortOrder', sortOrder);
        if (state) url.searchParams.set('state', state);
        if (earliestStart) url.searchParams.set('earliestStart', earliestStart);
        if (latestEnd) url.searchParams.set('latestEnd', latestEnd);
        if (therapyIsLocked) url.searchParams.set('therapyIsLocked', therapyIsLocked.toString());

        return this.http.get<PaginatedResponse<TherapySession[]>>(url.toString(), ApiService.options).toPromise();
    }

    async getAllUserTherapySessions(
        username: string,
        offset?: number,
        limit?: number,
        sortOrder: SortOrder = null,
        state?: TherapySessionState,
        earliestStart?: string,
        latestEnd?: string,
    ): Promise<PaginatedResponse<TherapySession[]>> {
        let url = `${ApiService.url}users/${username}/therapies/-/therapySessions`;
        // build query param string
        let queryParams = '';
        if (offset) queryParams = `${queryParams}offset=${offset}&`;
        if (limit) queryParams = `${queryParams}limit=${limit}&`;
        if (sortOrder) queryParams = `${queryParams}sortOrder=${sortOrder}&`;
        if (state) queryParams = `${queryParams}state=${state}&`;
        if (earliestStart) queryParams = `${queryParams}earliestStart=${earliestStart}&`;
        if (latestEnd) queryParams = `${queryParams}latestEnd=${latestEnd}&`;

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }

        return this.http.get<PaginatedResponse<TherapySession[]>>(url, ApiService.options).toPromise();
    }

    async createUserTherapySession(username: string, therapyId: number): Promise<TherapySession> {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}users/${username}/therapies/${therapyId}/therapySessions`;
        return this.http
            .post<TherapySession>(url, null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async setTherapySessionStateToIncomplete(username: string, therapySessionId: string): Promise<TherapySession> {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}users/${username}/therapies/-/therapySessions/${therapySessionId}/setIncomplete`;
        return this.http
            .put<TherapySession>(url, null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    setTherapySessionStatusToComplete(username: string, therapySessionId: string) {
        this.loadingService.startLoadingModal();
        const url = `${ApiService.url}users/${username}/therapies/-/therapySessions/${therapySessionId}/end`;
        return this.http
            .put<TherapySession>(url, null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }
}
