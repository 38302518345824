import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { BasePageConfig } from '../../../config/entities';

@Component({
    selector: 'app-popover-menu',
    templateUrl: './popover-menu.component.html',
    styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit {
    subPages: BasePageConfig[] = [];

    constructor(private navParams: NavParams, public router: Router, private popoverController: PopoverController) {
        const allMenuItems = this.navParams.get('subPages');
        this.subPages = allMenuItems.filter((item) => {
            return item.category === 'therapy';
        });
    }

    ngOnInit() {}

    openPage(url: string) {
        this.router.navigate([url]);
        this.dismissClick();
    }

    async dismissClick() {
        await this.popoverController.dismiss();
    }
}
