import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IntensityUnit, PhysicalExerciseGoalDto, StressUnit } from '../../entities/exerciseGoal/exerciseGoalDto';
import { RangeTyp } from '../../pages/training/training-patient-detail/training-patient-detail.page';

@Component({
    selector: 'lib-exercise-goal-col',
    templateUrl: './exercise-goal-table-col.component.html',
    styleUrls: ['./exercise-goal-table-col.component.scss'],
})
export class ExerciseGoalTableColComponent {
    @Input()
    exerciseGoal: PhysicalExerciseGoalDto;
    @Input()
    isLabelDisabled = false;
    @Input()
    isOnlyToShow = false;
    @Input()
    isRangeSelectorDisabled = false;
    @Output()
    sendUpdateOfExerciseGoal = new EventEmitter();

    disabled = false;
    RangeTyp = RangeTyp;
    IntensityUnit = IntensityUnit;
    StressUnit = StressUnit;

    constructor() {}

    changeRangeValue(value: number, exerciseGoal: PhysicalExerciseGoalDto, rangeTyp: RangeTyp) {
        if (rangeTyp === RangeTyp.INTENSITY) exerciseGoal.intensity = value;
        if (rangeTyp === RangeTyp.DURATION) exerciseGoal.duration = value;
        if (rangeTyp === RangeTyp.SERIES) exerciseGoal.series = value;
        if (rangeTyp === RangeTyp.PULSE) exerciseGoal.pulse = value;
        if (rangeTyp === RangeTyp.PULSE_VARIANCE) exerciseGoal.pulseVariance = value;
        this.sendUpdateOfExerciseGoal.emit(exerciseGoal);
    }
}
