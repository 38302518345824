import { Column, Entity, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { DeviceClass } from './Device';
import {
    MeasurementContent,
    MeasurementContentBloodPressure,
    MeasurementContentBloodSugarLevel,
    MeasurementContentBodyWeight,
    measurementContentClassList,
    MeasurementContentECG,
    MeasurementContentFhirQuestionnaire,
    MeasurementContentHeight,
    MeasurementContentNote,
    MeasurementContentOtoscope,
    MeasurementContentPulse,
    MeasurementContentSpirometry,
    MeasurementContentSPO2,
    MeasurementContentStethoscope,
    MeasurementContentTemperature,
    MeasurementContentWoundDoc,
} from './MeasurementContent';
import * as validator from 'validator';
import { Type } from 'class-transformer';
import { AccountType, Genders, PlainUserDto, User } from '../../../auth/entities/user';
import { NormRange } from '../measurementMarker';
import { MeasurementType } from './measurement-type';
import { LogbookEntry } from '../../../logbook/entities/logbook-entry';

@Entity('Patient')
export class Patient {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column({ nullable: true })
    alternativeName?: string;

    @Column({ nullable: true })
    pvsId?: string;

    @Column({ nullable: true })
    firstname?: string;

    @Column({ nullable: true })
    lastname?: string;

    @Column({ nullable: true })
    birthdate?: Date;

    @Column({ nullable: true })
    gender?: Genders;

    @Column({ nullable: true })
    streetAddress?: string;

    @Column({ nullable: true })
    postalCode?: string;

    @Column({ nullable: true })
    city?: string;

    @OneToMany(() => Examination, (examination) => examination.patient, { cascade: false })
    examinations: Examination[];

    // Indicates whether this patient should be created/updated on server side
    @Column({ default: false })
    isExportPending?: boolean;

    @Column()
    createdAtLocal: Date;

    @Column({ nullable: false })
    tenantId?: string;

    static fromUser(user: User, tenantId: string) {
        const patient = new Patient();
        if (!validator.isUUID(user.username, 4)) {
            // console.warn('Transformation from User to Patient failed. User.username is no UUIDv4');
            return null;
        }
        patient.uuid = user.username;
        patient.alternativeName = user.alternativeName;
        patient.pvsId = user.pvsId;
        patient.firstname = user.firstname;
        patient.lastname = user.lastname;
        if (user.birthdate) {
            patient.birthdate = new Date(user.birthdate);
        }
        patient.gender = Genders[user.gender as keyof typeof Genders];
        patient.postalCode = user.postalCode;
        patient.city = user.city;
        patient.streetAddress = user.streetAddress;
        patient.isExportPending = false;
        patient.createdAtLocal = new Date();
        patient.tenantId = tenantId;
        return patient;
    }

    static getDisplaynameFromUserData(user): string {
        if (user.alternativeName) {
            return user.alternativeName;
        } else if (user.pvsId) {
            return user.pvsId;
        } else {
            if (user.lastname && user.firstname) {
                return user.lastname + ', ' + user.firstname;
            } else if (user.lastname) {
                return user.lastname;
            } else if (user.firstname) {
                return user.firstname;
            } else {
                return null;
            }
        }
    }

    static getPatientTitleLabel(patient: Patient): string {
        let patientLabel = '';
        if (patient.firstname && patient.lastname) {
            patientLabel = `${patient.lastname}, ${patient.firstname}`;
        } else if (patient.firstname && !patient.lastname) {
            patientLabel = `${patient.firstname}`;
        } else if (!patient.firstname && patient.lastname) {
            patientLabel = `${patient.lastname}`;
        }

        if (patient.getDisplayname()) {
            if (patientLabel !== '') {
                patientLabel = `${patientLabel}\n(${patient.getDisplayname()})`;
            } else {
                patientLabel = `${patient.getDisplayname()}`;
            }
        }
        return patientLabel;
    }

    toPlainUser(): PlainUserDto {
        const plainUser = new PlainUserDto();
        plainUser.accountType = AccountType.PLAIN_USER;
        plainUser.username = this.uuid;
        plainUser.alternativeName = this.alternativeName;
        plainUser.pvsId = this.pvsId;
        plainUser.firstname = this.firstname;
        plainUser.lastname = this.lastname;
        if (this.birthdate) {
            plainUser.birthdate = this.birthdate.toISOString();
        }
        plainUser.gender = this.gender;
        plainUser.postalCode = this.postalCode;
        plainUser.city = this.city;
        plainUser.streetAddress = this.streetAddress;
        return plainUser;
    }

    getDisplayname(): string {
        return this.pvsId || this.alternativeName || null;
    }

    getCompleteName(): string {
        if (this.lastname && this.firstname) {
            return this.lastname + ', ' + this.firstname;
        } else if (this.lastname) {
            return this.lastname;
        } else if (this.firstname) {
            return this.firstname;
        } else {
            return null;
        }
    }
}

@Entity('Examination')
export class Examination {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column()
    timestamp: string;

    @Column()
    patientUuid: string;

    @ManyToOne(() => Patient, (patient) => patient.examinations, { nullable: true, cascade: true })
    patient?: Patient;

    @Column({ nullable: true })
    creator: string;

    @OneToMany(() => Measurement, (measurement) => measurement.examination, {
        cascade: true,
    })
    measurements: Measurement[];

    // An examination is finished when the examination was already sent to Backend or when it comes from Backend
    // This attribute is used only in Frontend and not in Backend
    @Column()
    isFinished?: boolean;

    @Column({ nullable: false })
    tenantId: string;
}

export class ExaminationSimple {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column()
    timestamp: string;

    @ManyToOne(() => Patient, (patient) => patient.examinations, { nullable: true, cascade: true })
    patient?: string;

    patientName?: string;

    @Column({ nullable: true })
    creator: string;

    creatorObj: User;
    patientObj: User;

    @OneToMany(() => Measurement, (measurement) => measurement.examination, {
        cascade: true,
    })
    measurements: Measurement[];

    // An examination is finished when the examination was already sent to Backend or when it comes from Backend
    // This attribute is used only in Frontend and not in Backend
    @Column()
    isFinished: boolean;

    @Column({ nullable: false })
    tenantId: string;
    events: LogbookEntry[];
}

export class ExaminationSimpleBuilder {
    private examinationSimple = new ExaminationSimple();

    copyFromExaminationDto(examination: Examination): ExaminationSimpleBuilder {
        this.examinationSimple.uuid = examination.uuid;
        this.examinationSimple.timestamp = examination.timestamp;
        this.examinationSimple.patient = examination.patient.uuid;
        this.examinationSimple.creator = examination.creator;
        return this;
    }

    setExaminationState(state: boolean): ExaminationSimpleBuilder {
        this.examinationSimple.isFinished = state;
        return this;
    }

    build(): ExaminationSimple {
        return this.examinationSimple;
    }
}

export class ExaminationSimplePatient extends ExaminationSimple {
    height: MeasurementItem;
    weight: MeasurementItem;
    temperature: MeasurementItem;
    bloodPressure: MeasurementItem;
    glucose: MeasurementItem;
    oxygenSaturation: MeasurementItem;
    bpm: MeasurementItem;
    bmi: MeasurementItem;
    ecg: string;
    notes: string;
    diastolic: MeasurementItem;
    systolic: MeasurementItem;
    hasNotes: string;
    questionnaire: MeasurementItem;
    mobileTitle: string;
}

export class MeasurementItem {
    value: string;
    notMeasurable: boolean;
    notMeasured: boolean;
    icon: string;
    iconColor: string;

    constructor(value?: string, notMeasurable?: boolean, notMeasured?: boolean, icon?: string, iconColor?: string) {
        this.value = value;
        this.notMeasurable = notMeasurable;
        this.notMeasured = notMeasured;
        this.icon = icon;
        this.iconColor = iconColor;
    }
}

@Entity('Measurement')
export class Measurement {
    @PrimaryGeneratedColumn('uuid')
    uuid: string;

    @Column()
    type: MeasurementType;

    @Column({ default: false })
    notMeasurable: boolean;

    @Column({ default: false })
    notMeasured: boolean;

    @ManyToOne(() => Examination, (examination) => examination.measurements)
    @JoinColumn({ name: 'examinationUuid' })
    examination: Examination | ExaminationSimple;

    @OneToOne(() => DeviceClass)
    @JoinColumn()
    device: DeviceClass;

    @Column()
    timestamp: string;

    // https://github.com/typeorm/typeorm/blob/master/docs/entities.md#simple-json-column-type
    @Column({
        type: 'simple-json',
        nullable: true,
    })
    @Type(() => MeasurementContent, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'type',
            subTypes: measurementContentClassList.map((x) => {
                const obj = new x();
                return {
                    value: x,
                    name: obj.type,
                };
            }),
        },
    })
    content?:
        | MeasurementContent
        | MeasurementContentBodyWeight
        | MeasurementContentHeight
        | MeasurementContentWoundDoc
        | MeasurementContentBloodSugarLevel
        | MeasurementContentOtoscope
        | MeasurementContentSPO2
        | MeasurementContentSpirometry
        | MeasurementContentStethoscope
        | MeasurementContentNote
        | MeasurementContentBloodPressure
        | MeasurementContentECG
        | MeasurementContentFhirQuestionnaire
        | MeasurementContentTemperature
        | MeasurementContentPulse;

    @Column({ nullable: true })
    note?: string;

    @Column({ nullable: true })
    pdfContentUuid?: string;

    /* @Column({nullable: true})*/
    normalValueRange?: string;

    constructor(
        examination: Examination | ExaminationSimple,
        content: MeasurementContent,
        type: MeasurementType,
        note?: string,
        device?: DeviceClass,
        notMeasurable?: boolean,
        notMeasured?: boolean,
        normalValueRange?: NormRange,
    ) {
        this.examination = examination;
        this.note = note;
        this.timestamp = new Date().toISOString();
        this.device = device;
        this.type = type;
        this.notMeasurable = notMeasurable;
        this.notMeasured = notMeasured;
        this.content = this.checkContentValid(content, notMeasurable, notMeasured);
        this.normalValueRange = normalValueRange;
    }

    checkContentValid(content: MeasurementContent, notMeasurable: boolean, notMeasured: boolean) {
        if (notMeasurable || notMeasured) {
            return null;
        }
        return content;
    }
}
