import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionType, ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <ion-button
            *ngIf="!(itemSetting.actionType === 'DELETE' && data.hideDeleteButton)"
            color="{{ itemSetting.color }}"
            (click)="setActionTypeEvent($event)"
            [id]="itemSetting.id"
            [disabled]="itemSetting.disabled || data.disabled || data[itemSetting.disabledProp]"
            fill="{{ itemSetting.fill || 'solid' }}">
            <ion-icon
                *ngIf="itemSetting.icon"
                id="icon_{{ itemSetting.id }}"
                style="height: 30px; margin: 3px;"
                name="{{ itemSetting.icon }}"></ion-icon>
            <span *ngIf="data[itemSetting.label]"> {{ data[itemSetting.label] | translate }}</span>
        </ion-button>
    `,
    styles: ['ion-button{ height: 32px}'],
})
export class ButtonItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean; hideDeleteButton: boolean };
    @Input() itemSetting: TableItem;
    @Output() updateList = new EventEmitter<boolean>();
    @Output() actionSelected = new EventEmitter<{ action: ActionType; mouseEvent: MouseEvent }>();

    setActionTypeEvent(event: MouseEvent) {
        if (this.itemSetting.disabled || this.data.disabled || this.data[this.itemSetting.disabledProp]) return;
        this.actionSelected.emit({ action: this.itemSetting.actionType, mouseEvent: event });
    }
}
