import { ExerciseSessionDto } from './exercise-session-dto';
import { ExerciseSessionAppointment } from '../appointement';
import { ExerciseSessionTherapist } from './exercise-session-therapist';
import { ExerciseSessionParticipant } from './exercise-session-participant';
import { Exercise } from '../exercise';
import { ExerciseSessionStateChange } from './exercise-session-state-change';
import { TherapyExercise } from '../therapy';
import { isAfter } from 'date-fns';
import { ExerciseSessionState, ExerciseSessionUserState } from './exercise-session-state';
import { ActionMenuItem } from '../../../table/entities/action-menu.item';
import { ExerciseSessionCalendarEvent } from './exercise-session-calendar-event';
import { HypermediaLinks, HypermediaResource } from '../../../hateoas/hateoas.model';

export class ExerciseSession extends ExerciseSessionDto implements HypermediaResource {
    static appointmentPublishedStates = [
        ExerciseSessionState.PLANNED,
        ExerciseSessionState.ACTIVE,
        ExerciseSessionState.FINISHED,
        ExerciseSessionState.CANCELLED,
        ExerciseSessionState.POSTPONED,
        ExerciseSessionState.PATIENT_CANCELLED,
        ExerciseSessionState.DELETED,
        ExerciseSessionUserState.NO_SHOW,
    ];

    static userAttendanceRequiredStates = [
        ExerciseSessionUserState.NOT_PLANNED,
        ExerciseSessionUserState.SCHEDULED,
        ExerciseSessionUserState.PLANNED,
        ExerciseSessionUserState.ACTIVE,
        ExerciseSessionUserState.FINISHED,
        ExerciseSessionUserState.NO_SHOW,
        ExerciseSessionUserState.TECH_PROBLEM,
    ];

    static finishedStates = [
        ExerciseSessionState.FINISHED,
        ExerciseSessionState.CANCELLED,
        ExerciseSessionState.POSTPONED,
        ExerciseSessionState.PATIENT_CANCELLED,
        ExerciseSessionState.DELETED,
        ExerciseSessionUserState.NO_SHOW,
    ];
    static finishedFrontendStates = [ExerciseSessionState.FINISHED, ExerciseSessionUserState.NO_SHOW];
    static notPublishedStates = [ExerciseSessionState.NOT_PLANNED, ExerciseSessionState.SCHEDULED];

    static runningState = [ExerciseSessionState.PLANNED, ExerciseSessionState.ACTIVE];

    static inactiveState = [
        ExerciseSessionState.CANCELLED,
        ExerciseSessionState.PATIENT_CANCELLED,
        ExerciseSessionState.POSTPONED,
    ];

    id: number;
    appointment?: ExerciseSessionAppointment;
    participants?: ExerciseSessionParticipant[];
    therapists?: ExerciseSessionTherapist[];
    stateChanges?: ExerciseSessionStateChange[];
    therapyExercise: TherapyExercise;
    responsibleFullName?: string;
    exercise?: Exercise;
    created_at?: string;
    updated_at?: string;
    childExerciseSessions?: ExerciseSession[];
    calendarEvent?: ExerciseSessionCalendarEvent;
    currentGroupSize: number;
    parentSession?: ExerciseSession;
    latestStateChange?: string;
    _links: HypermediaLinks;

    static isAppointmentNotPlanned(exerciseSession: ExerciseSession) {
        return this.notPublishedStates.find((item) => item === exerciseSession.exerciseSessionState);
    }

    static isAppointmentInFuture(exerciseSession: ExerciseSession) {
        if (!exerciseSession.appointment.startTime) return true;
        return isAfter(new Date(exerciseSession.appointment.startTime), new Date());
    }

    static isAppointmentRunning(exerciseSession: ExerciseSession) {
        return this.runningState.find((item) => item === exerciseSession.exerciseSessionState);
    }

    static isAppointmentFinished(exerciseSession: ExerciseSession) {
        return this.finishedStates.find((item) => item === exerciseSession.exerciseSessionState);
    }

    static isAppointmentPlanned(exerciseSession: ExerciseSession) {
        return this.appointmentPublishedStates.find((item) => item === exerciseSession.exerciseSessionState);
    }
}

export class ExerciseSessionHome extends ExerciseSession {
    startDate: string;
    exerciseTitle: string;
    participantsName: string;
    type: string;
    actions: ActionMenuItem[];
}
