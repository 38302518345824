import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CurafidaCommonModule } from '../common/curafida-common.module';
import { KioskComponentsModule } from './components/kiosk-component.module';
import { IonicModule } from '@ionic/angular';
import { TherapyComponentsModule } from '../therapy/components/therapy-component.module';
import { UserModule } from '../user/user.module';

@NgModule({
    imports: [
        IonicModule,
        TherapyComponentsModule,
        UserModule,
        CommonModule,
        CurafidaCommonModule,
        FormsModule,
        TranslateModule,
    ],
    declarations: [],
    exports: [KioskComponentsModule],
})
export class KioskModule {}
