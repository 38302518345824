<div
    class="error-message flex-container-row"
    *ngIf="formGroup"
    [formGroup]="formGroup">
    <ion-label class="item-label-user-info self-align-center"></ion-label>
    <div
        class="flex-container-column full-width ion-item"
        style="margin-bottom: 5px; margin-left: 10px">
        <ng-container *ngFor="let error of errorMessageInput">
            <div
                class="error-message"
                *ngIf="formGroup.controls[formControlName].errors?.[error?.errorType] ">
                {{ error.message | translate }}
            </div>
        </ng-container>
    </div>
</div>
