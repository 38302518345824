<ion-app>
    <lib-curafida-side-menu [contentID]="'curafida-content'"></lib-curafida-side-menu>
    <ion-header *ngIf="isPlatformReady && !isSplashEnable" style="z-index: 999" #ionHeader>
        <lib-curafida-toolbar></lib-curafida-toolbar>
    </ion-header>
    <ion-router-outlet id="curafida-content" [swipeGesture]="false">
    </ion-router-outlet>
    <lib-splash></lib-splash>
    <lib-curafida-footer-console-log></lib-curafida-footer-console-log>
</ion-app>
