export enum MeasurementType {
    HEIGHT = 'HEIGHT', // Körpergröße
    BODY_WEIGHT = 'BODY_WEIGHT', // Körpergewicht
    TEMPERATURE = 'TEMPERATURE', // Temperatur
    BLOOD_PRESSURE = 'BLOOD_PRESSURE', // Blutdruck
    BLOOD_SUGAR_LEVEL = 'BLOOD_SUGAR_LEVEL', // Blutzucker
    SPO2 = 'SPO2', // pulsoxymetrisch gemessene Sauerstoffsättigung
    ECG = 'ECG', // Elektrokardiogramm
    WOUND_DOC = 'WOUND_DOC', // Wunddokumentation
    EMPTY = 'EMPTY', // Messung ohne MeasurementContent
    // QUESTIONNAIRE = 'QUESTIONNAIRE', // Fragebogen
    FHIR_QUESTIONNAIRE = 'FHIR_QUESTIONNAIRE', // Fragebogen
    STETHOSCOPE = 'STETHOSCOPE', // Stethoskop
    OTOSCOPE = 'OTOSCOPE', // Otoskop
    SPIROMETRY = 'SPIROMETRY', // Spirometrie - Messung des Lungenvolumens
    DOCUMENT = 'DOCUMENT', // Dokumenten vom Patient
    PULSE = 'PULSE', // Puls vom Patient
    // BMI = 'BMI', // BMI vom Patient
}
