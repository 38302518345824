import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';

@Component({
    selector: 'curafida-modal-select-input',
    templateUrl: './curafida-modal-select-input.component.html',
    styleUrls: ['./curafida-modal-select-input.component.scss'],
})
export class CurafidaModalSelectInputComponent extends CurafidaInputComponent {
    @Input()
    explanation: string;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
    ) {
        super(ngControl);
    }
}
