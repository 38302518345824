import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { DateFormat, ItemType, TableConfig } from '../../../../table/entities/table';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { FrontendExerciseSessionOfUser } from '../../../entities/exerciseSession';
import { UsersTherapySessionsService } from '../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { User } from '../../../../auth/entities/user';
import { TherapySession, TherapySessionState } from '../../../entities/therapy-session/therapy-session';
import { RangeSelectAdapterComponent } from '../../table-adapter/range-selector-item-adapter.component';
import { CommentFeedbackAdapterComponent } from '../../table-adapter/comment-feedback-adapter.component';
import { BorgPainFeedbackAdapterComponent } from '../../table-adapter/borg-pain-feedback-adapter.component';
import { FeedbackAdapterComponent } from '../../table-adapter/feedback-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';

@Component({
    selector: 'lib-exercise-training-list-chart-training',
    templateUrl: './exercise-training-list-chart-modal.component.html',
    styleUrls: ['./exercise-training-list-chart-modal.component.scss'],
})
export class ExerciseTrainingListChartModalComponent implements OnInit {
    @Input()
    exerciseId: number;
    @Input()
    therapyId: number;
    patient: User;

    title = 'Übungsstatistik';
    exerciseSessionsOfUserTable: TableConfig<FrontendExerciseSessionOfUser[]> = new TableConfig<
        FrontendExerciseSessionOfUser[]
    >();
    private therapySessions: PaginatedResponse<TherapySession[]>;

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
        private toastService: ToastService,
        private usersTherapySessionService: UsersTherapySessionsService,
    ) {
        this.exerciseId = params.get('exerciseId');
        this.therapyId = params.get('therapyId');
        this.patient = params.get('patient');
    }

    async ngOnInit() {
        this.initTable();
        this.exerciseSessionsOfUserTable.list.items = [];
        this.therapySessions = await this.usersTherapySessionService.getUserTherapySessions(
            this.patient.username,
            this.therapyId,
            null,
            null,
            null,
            TherapySessionState.COMPLETED,
        );
        for (const therapySession of this.therapySessions.items) {
            this.exerciseSessionsOfUserTable.list.items.push(
                therapySession.exerciseSessionsOfUser.find(
                    (e) => e.exerciseSession.exercise.id === this.exerciseId,
                ) as FrontendExerciseSessionOfUser,
            );
        }
        if (this.exerciseSessionsOfUserTable.list.items.length > 0) {
            this.title = `${this.title}: ${this.exerciseSessionsOfUserTable.list.items[0].exerciseSession.exercise.title}`;
            this.exerciseSessionsOfUserTable.list.total = this.exerciseSessionsOfUserTable.list.items.length;
            this.exerciseSessionsOfUserTable.list.limit = this.exerciseSessionsOfUserTable.list.items.length;
            this.exerciseSessionsOfUserTable.list.count = this.exerciseSessionsOfUserTable.list.items.length;
            this.exerciseSessionsOfUserTable.list.offset = 0;
            for (const exerciseSessionOfUser of this.exerciseSessionsOfUserTable.list.items) {
                exerciseSessionOfUser.exerciseName = exerciseSessionOfUser.exerciseSession.exercise.title;
                // @ts-ignore
                exerciseSessionOfUser.exerciseSessionUpdateTime = exerciseSessionOfUser.exerciseSession.updated_at;
                if (exerciseSessionOfUser.feedback?.borg) {
                    exerciseSessionOfUser.borg = exerciseSessionOfUser.feedback.borg;
                }
                exerciseSessionOfUser.comment = exerciseSessionOfUser?.feedback?.feedbackComment;
            }
        }
    }

    initTable() {
        this.exerciseSessionsOfUserTable.itemSettings = [
            {
                id: 'exerciseName',
                prop: 'exerciseSessionUpdateTime',
                header: 'Datum',
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                type: ItemType.ADAPTER,
                width: '12%',
                sortOrderWeb: 0,
            },
            {
                id: 'exerciseGoal',
                prop: 'exerciseGoal',
                header: 'SOLL Werte',
                type: ItemType.ADAPTER,
                adapter: RangeSelectAdapterComponent,
                width: '26%',
                sortOrderWeb: 0,
                label: 'true',
                isOnlyToShow: true,
            },
            {
                id: '',
                prop: '',
                header: 'IST Werte',
                type: ItemType.ADAPTER,
                adapter: FeedbackAdapterComponent,
                width: '18%',
                sortOrderWeb: 0,
                label: 'true',
            },
            {
                id: '',
                prop: 'feedback',
                header: 'Feedback',
                type: ItemType.ADAPTER,
                adapter: BorgPainFeedbackAdapterComponent,
                width: '15%',
                sortOrderWeb: 0,
            },
            {
                id: '',
                prop: 'comment',
                header: 'Kommentar',
                type: ItemType.ADAPTER,
                adapter: CommentFeedbackAdapterComponent,
                width: '17%',
                sortOrderWeb: 0,
            },
        ];
    }
}
