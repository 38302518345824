<div
    [hidden]="!(itemSetting && row)"
    [ngClass]="{ 'column-margin': !isMobile }"
    class="full-width">
    <div *ngIf="itemSetting.type === ItemType.REORDER">
        <ion-reorder slot="start"></ion-reorder>
    </div>
    <div *ngIf="itemSetting.type === ItemType.NUMBERING">
        {{ rowIndex + 1 }}
    </div>
    <ng-template itemAdapterHost></ng-template>
</div>
