import { UserRoles } from '../../auth/entities/user';
import { PageName } from './page.name';
import { RoutingSegment } from '../../common/entities/routing-segment';
import { ToolbarType } from '../../common/entities/view/toolbar-type';

export interface BasePageConfig {
    title: string;
    pageId?: string | PageName;
    showTitle?: boolean;
    url?: string;
    role: UserRoles[] | string[];
    categories: RoutingSegment[];
    logo?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    function?: Function;
    showBurgerMenu?: boolean;
    module?: CurafidaFeature;
    configuration?: PageParameters;
    text?: string;
    badge?: number;
}

export abstract class PageParameters {
    // main configuration
    toolbarVisibility? = true;
    menuVisibility? = false;
    headerVisibility? = false;
    showPageTitle? = false;
    showSubPagesDetailList? = false;
    showCurrentUser? = false;
    hasHelpIcon? = false;
    helpUrl?: string;
    toolbarType?: ToolbarType;
    onlyIcon?: boolean; // The title-text is not always desired on the toolbar.
    // The Icon is used as a reference for the user. It will be displayed on the toolbar

    // user content configuration
    hasUserTitle? = false;
    hasNationality? = false;
    hasMaritalStatus? = false;
    hasEmail? = false;
    // isEmailObligatory deprecated
    isEmailObligatory? = true;
    hasGender? = false;
    hasBirthdate? = false;
    isBirthdateObligatory? = false;
    hasHealthInsurance? = false;
    healthInsuranceAsSelectInput? = false;
    isInsuranceNumberObligatory? = false;
    isInsuranceObligatory? = false;
    hasPhone? = false;
    showUsername? = false;
    hasGeneralDoctor? = false;
    hasState? = false;
    isDeleteButtonShow? = true;
    hasPseudonym? = false;
    hasCaregiverManagement?: boolean;
    hasSupervisorManagement?: boolean;
    hasTagManagement?: boolean;

    // therapy configuration
    showQuestionnaireChart? = false;

    // main configuration
    hasRoleManagement? = false;
    hasInAppNotifications? = false;
    isMenuItemDisabled? = false;
}

export enum CurafidaFeature {
    training = 'training',
    task = 'task',
    note = 'note',
    measurement = 'measurement',
    medication = 'medication',
    screening = 'screening',
    anamnese = 'anamnese',
    chat = 'chat',
    learning = 'learning',
    mobility = 'mobility',
    course = 'course',
    appointment = 'appointment',
    consultation = 'consultation',
    order = 'order',
    program = 'program',
    log = 'log',
    user = 'user',
    shop = 'shop',
    organisation = 'organisation',
    common = 'common',
    video = 'video',
    manual = 'manual',
    kiosk = 'kiosk',
}

export abstract class FeaturesConfiguration {
    anamnese?: MedicalHistoryFeatureConfiguration;
    chat?: ChatFeatureConfiguration;
    consultation?: MainFeatureConfiguration;
    course?: CourseFeatureConfiguration;
    directus?: DirectusFeatureConfiguration;
    kiosk?: MainFeatureConfiguration;
    learning?: MainFeatureConfiguration;
    log?: MainFeatureConfiguration;
    manual?: MainFeatureConfiguration;
    measurement?: MeasurementFeatureConfiguration;
    medication?: MainFeatureConfiguration;
    mobility?: MainFeatureConfiguration;
    note?: MainFeatureConfiguration;
    order?: MainFeatureConfiguration;
    organisation?: MainFeatureConfiguration;
    program?: MainFeatureConfiguration;
    screening?: MainFeatureConfiguration;
    shop?: ShopFeatureConfiguration;
    task?: MainFeatureConfiguration;
    training?: MainFeatureConfiguration;
    user?: UserFeatureConfiguration;
    video?: MainFeatureConfiguration;
}

export class MainFeatureConfiguration {
    enabled: boolean;
}

export class MeasurementFeatureConfiguration extends MainFeatureConfiguration {
    peripherals: boolean;
}

export class DirectusFeatureConfiguration extends MainFeatureConfiguration {
    apiBaseUrl?: string;
    readOnlyAccessToken?: string;
}

export class ShopFeatureConfiguration extends MainFeatureConfiguration {
    shopUrl?: string;
}
export class ChatFeatureConfiguration extends MainFeatureConfiguration {
    welcomeLabelPatientActive?: string;
    welcomeLabelPatientPassive?: string;
    welcomeLabelNonPatientActive?: string;
    welcomeLabelNonPatientPassive?: string;
}

export class CourseFeatureConfiguration extends MainFeatureConfiguration {
    createEmptyExerciseSessions?: boolean;
    plannedExerciseSessionsWithoutParticipants?: boolean;
    hasAlternativeAppointment?: boolean;
}
export class MedicalHistoryFeatureConfiguration extends MainFeatureConfiguration {}

export class UserFeatureConfiguration extends MainFeatureConfiguration {
    admin?: MainFeatureConfiguration;
    caregiver?: MainFeatureConfiguration;
    catalog_manager?: MainFeatureConfiguration;
    analyst?: MainFeatureConfiguration;
    physician?: MainFeatureConfiguration;
    supervisor?: MainFeatureConfiguration;
    user_manager?: MainFeatureConfiguration;
    registerUrl?: string;
}
